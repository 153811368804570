import classNames from 'classnames'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { Control, useForm } from 'react-hook-form'

import styles from './gift.module.scss'
import { SelectionHandle } from './types'
import { useUpdateCart } from './useUpdateCart'
import { CartItemData } from '../cart/CartItem'
import {
    CartItemConfigurableConfiguration,
    CartItemConfigurableFormValue,
} from '../cart/CartItemConfiguration/CartItemConfigurableConfiguration'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import defaultAttributes from '../catalog/ProductPage/StickyCta/defaultAttributes'
import flattenCombinedAttributes, {
    decodeAttributeValueObject,
    encodeAttributeValueObject,
} from '../catalog/ProductPage/StickyCta/flattenCombinedAttributes'
import Loader from '../presentation/Loader'
import { GiftRule } from '../reducers'

type Props = {
    rule: GiftRule
    cartItem?: CartItemData
    product: ConfigurableProduct
    onChange?: (option: ConfigurableAttributesFieldValue) => void
    editMode?: boolean
}

// eslint-disable-next-line react/display-name
export const FreeGiftNoSelect = forwardRef<SelectionHandle, Props>(
    ({ cartItem, product, onChange, editMode }: Props, ref) => {
        const flattenedOptions = flattenCombinedAttributes(product)
        const defaultOptions = defaultAttributes(product)
        const options =
            (flattenedOptions ?? defaultOptions)?.map((o) => ({
                value: o.value,
                label: o.label,
                isDisabled: o.disabled,
            })) ?? []

        const optionsMap = new Map()
        const selectedAttributeObject: Record<string, number> = {}
        if ('variants' in product) {
            product.configurableOptions.forEach((item) =>
                optionsMap.set(item.attributeCode, item.attributeId),
            )
            const variant = product.variants.find(
                (item) => item.product?.sku === cartItem?.sku,
            )

            for (const attribute of variant?.attributes ?? []) {
                selectedAttributeObject[
                    String(optionsMap.get(attribute.code))
                ] = attribute.valueIndex
            }
        }
        const defaultSelectedOption = options?.[0]?.value ?? ''

        const attributeValue = encodeAttributeValueObject(
            selectedAttributeObject,
        )

        const { control, setValue, watch } = useForm<
            CartItemConfigurableFormValue & { enableUpdate: boolean }
        >({
            defaultValues: {
                value: attributeValue,
                enableUpdate: false,
            },
        })

        const { loading, updateCart } = useUpdateCart()

        const optionValue = watch('value')

        const onChangeRef = useRef(onChange)

        useEffect(() => {
            if (onChangeRef.current) {
                onChangeRef.current(decodeAttributeValueObject(optionValue))
            }
        }, [optionValue])

        useEffect(() => {
            setValue('value', attributeValue)
        }, [attributeValue, setValue])

        useImperativeHandle(ref, () => ({
            deselect() {
                setValue('value', '')
            },
            select(updateCartItem?: boolean) {
                if (!updateCartItem) {
                    setValue('value', defaultSelectedOption)
                } else {
                    // Update cart with gift item:
                    const updateCartWithGiftItem = async () => {
                        await updateCart([
                            {
                                product,
                                options: decodeAttributeValueObject(
                                    defaultSelectedOption,
                                ),
                            },
                        ])
                    }
                    updateCartWithGiftItem()
                }
            },
        }))

        return (
            <form className={styles.sizeSelector}>
                {loading && <Loader className={styles.loader} />}

                <CartItemConfigurableConfiguration
                    horizontalLayout={editMode}
                    product={product as Product}
                    control={
                        control as unknown as Control<
                            CartItemConfigurableFormValue,
                            'value'
                        >
                    }
                    onChange={() => {
                        onChangeRef.current?.(
                            decodeAttributeValueObject(defaultSelectedOption),
                        )
                    }}
                    isDisabled
                    selectAnOptionLabel="PCS"
                    className={!editMode && !cartItem ? styles.hide : ''}
                />
            </form>
        )
    },
)
