import { HTMLAttributes } from 'react'

import styles from './MiniCartItem.module.scss'
import MiniCartItemContainer from './MiniCartItemContainer'
import MiniCartItemDetails from './MiniCartItemDetails'
import MiniCartItemImage from './MiniCartItemImage'
import MiniCartItemRowTotal from './MiniCartItemRowTotal'
import MiniCartItemSelectedOptions from './MiniCartItemSelectedOptions'
import { getGiftRuleLabel } from './NextGiftNotification'
import { useGetBasicProduct } from '../catalog/ProductPage/GetBasicProduct.query'
import { CurrencyEnum } from '../graphql/schema.generated'
import PageLoader from '../presentation/PageLoader'
import {
    QuoteDataCartItemInterface,
    QuoteDataTotalsItemInterface,
} from '../types'
import Text from '../typography/Text'
import useFreeGiftRules from '../useFreeGiftRules'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    cartItem: Pick<QuoteDataCartItemInterface, 'item_id' | 'product_id' | 'qty'>
    totals: QuoteDataTotalsItemInterface
    currency: CurrencyEnum
    className?: string
    variant?: 'gift' | 'item'
}

const MiniCartItem = ({
    cartItem,
    totals,
    currency,
    className,
    variant = 'item',
    ...others
}: Props) => {
    const { rules } = useFreeGiftRules()
    const { data: product } = useGetBasicProduct(cartItem.product_id)
    const isGift = variant === 'gift'

    if (!product) {
        return <PageLoader className={styles.loader} />
    }

    const description = isGift ? (
        <Text variant="secondary">
            {
                getGiftRuleLabel(
                    rules.find((r) =>
                        r.products.find((p) => p.id === product.id),
                    )?.label,
                )[0]
            }
        </Text>
    ) : (
        product.sku
    )

    return (
        <MiniCartItemContainer className={className} {...others}>
            <MiniCartItemImage product={product} />

            <MiniCartItemDetails
                product={product}
                description={description}
                details={
                    <MiniCartItemSelectedOptions
                        cartItem={cartItem}
                        product={product}
                        totals={totals}
                    />
                }
                footer={
                    !isGift && (
                        <MiniCartItemRowTotal
                            product={product}
                            totals={totals}
                            currency={currency}
                        />
                    )
                }
                {...others}
            />
        </MiniCartItemContainer>
    )
}

export default MiniCartItem
