export const JUST_REWARDS_ENABLED =
    process.env.REACT_APP_FEATURE_JUSTREWARDS === 'true'

export const JUST_REWARDS_POINTS_ENABLED =
    process.env.REACT_APP_FEATURE_JUSTREWARDS_POINTS === 'true'

export const JUST_REWARDS_STORE_BLACKLIST =
    process.env.REACT_APP_FEATURE_JUSTREWARDS_STORE_BLACKLIST

export const COLORSWATCH_PDP_ENABLED =
    process.env.REACT_APP_FEATURE_COLORSWATCH_PDP === 'true'

export const WISHLIST_DISABLED =
    process.env.REACT_APP_WISHLIST_DISABLED === 'true'

export const STORELOCATOR_DISABLED =
    process.env.REACT_APP_STORELOCATOR_DISABLED === 'true'

export const STORES_DISABLED = process.env.REACT_APP_STORES_DISABLED === 'true'

export const PERSONAL_SHOPPER_ENABLED =
    process.env.REACT_APP_FEATURE_PERSONAL_SHOPPER === 'true'

export const BLUECONIC_EVENTS_ENABLED =
    process.env.REACT_APP_FEATURE_BLUECONIC_EVENTS === 'true'

export const PRIVILEGES_ENABLED =
    process.env.REACT_APP_PRIVILEGES_ENABLED === 'true'

export const PRIVILIGES_DASHBOARD_WHITELIST =
    process.env.REACT_APP_FEATURE_PRIVILIGES_DASHBOARD_WHITELIST

export const GIFTCARD_BALANCE_CHECK_URL =
    process.env.REACT_APP_GIFTCARD_BALANCE_CHECK_URL

export const OMIT_AUTH_ROUTES_IN_PERCOLATE =
    process.env.REACT_APP_OMIT_AUTH_ROUTES_IN_PERCOLATE === 'true'

export const DEBUG_PREFETCH_URL = process.env.REACT_APP_DEBUG_PREFETCH_URL

export const REQUIRE_LOGIN_FOR_PRELOAD =
    process.env.REACT_APP_REQUIRE_LOGIN_FOR_PRELOAD
