import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { addItemToCart } from '../actions'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import {
    isGroupedProduct,
    Product,
} from '../catalog/ProductPage/GetProduct.query'
import { giftModalEnabledVar } from '../FreeGiftAvailableModal'
import { useAddToCartEvent } from '../utils/ga4/useAddToCartEvent'
import { M2ApiResponseError } from '../utils/RestApi'

async function add(
    pushAddToCart: (itemId: string) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    product: Product | ConfigurableProduct,
    quantity: number,
    options: ConfigurableAttributesFieldValue | undefined,
    openMiniCart: boolean = true,
) {
    try {
        return await dispatch(
            addItemToCart(
                pushAddToCart,
                product,
                quantity,
                options,
                openMiniCart,
            ),
        )
    } catch (e) {
        if (!(e instanceof Error)) {
            return
        }
        let message = e.message

        if (e instanceof M2ApiResponseError) {
            message = (e as M2ApiResponseError).userMessage
        }

        throw new Error(message)
    }
}

export default function useAddToCartFunction() {
    const dispatch = useDispatch()

    const push = useAddToCartEvent()

    function addToCart(
        product: Product | ConfigurableProduct | null | undefined,
        quantity: number,
        options: ConfigurableAttributesFieldValue | undefined,
        openMiniCart?: boolean,
    ): Promise<void>

    function addToCart(
        product: Product | ConfigurableProduct | null | undefined,
    ): (
        quantity: number,
        options: ConfigurableAttributesFieldValue | undefined,
        openMiniCart?: boolean,
    ) => Promise<void>

    function addToCart(
        product: Product | ConfigurableProduct | null | undefined,
        quantity?: number,
        options?: ConfigurableAttributesFieldValue | undefined,
        openMiniCart: boolean = true,
    ) {
        if (quantity == null || options == null) {
            return (
                quantity: number,
                options: ConfigurableAttributesFieldValue | undefined,
            ) => addToCart(product, quantity, options)
        }

        if (!product || isGroupedProduct(product)) {
            return Promise.resolve()
        }
        if (openMiniCart) {
            giftModalEnabledVar(true)
        }
        return add(
            (itemId) => {
                push(itemId, product, quantity, options)
            },
            dispatch,
            product,
            quantity,
            options,
            openMiniCart,
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(addToCart, [dispatch, push])
}
