import parseItemVariant from './parseItemVariant'
import toGA4Item from './toGAItem'
import { usePushEcommerceEvent } from './usePushEvent'
import { convertToTagManagerVariants } from './utils'
import { ConfigurableAttributesFieldValue } from '../../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { BasicConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { BasicProduct } from '../../catalog/ProductPage/GetBasicProduct.query'
import { useRootCategoryId } from '../../catalog/useRootCategory'

const useAddToWishlist = () => {
    const rootCategoryId = useRootCategoryId()

    const push = usePushEcommerceEvent([], false)

    return (
        product: BasicProduct | BasicConfigurableProduct,
        selectedOptions?: ConfigurableAttributesFieldValue,
    ) => {
        if (!rootCategoryId) {
            return
        }

        const variant: string[] = selectedOptions
            ? convertToTagManagerVariants(
                  selectedOptions,
                  product as BasicConfigurableProduct,
              )
            : []

        const item = toGA4Item(product, rootCategoryId, {
            quantity: 1,
            item_variant: parseItemVariant(variant.join(',')),
        })

        if (!item.price || !item.currency) {
            return
        }

        push?.([
            { ecommerce: null },
            {
                event: 'add_to_wishlist',
                ecommerce: {
                    value: item.price,
                    currency:
                        typeof item.currency === 'string'
                            ? item.currency
                            : 'EUR',
                    items: [item],
                },
            },
        ])
    }
}

export default useAddToWishlist
