import { gql } from '@apollo/client'

import { Maybe } from '../../graphql/schema.generated'
import { ProductCardFragment } from '../../ProductCardFragment'

const fragment = gql`
    fragment ConfigurableProductInfo on ConfigurableProduct {
        configurableOptions {
            id
            attributeId
            attributeCode
            label
            position
            values {
                valueIndex
                label
                storeLabel
            }
        }
        variants {
            attributes {
                valueIndex
                code
                label
                uid
            }
            product {
                id
                name
                sku
                stockStatus
                onlyXLeftInStock
                priceRange {
                    maximumPrice {
                        discount {
                            amountOff
                            percentOff
                        }
                        finalPrice {
                            currency
                            value
                        }
                        regularPrice {
                            currency
                            value
                        }
                    }
                }
                outletAdviesprijs
            }
        }
    }
`

export default fragment

export interface ConfigurableAttributeValue {
    /** The id to be send to the server to communicate this value was selected. */
    valueIndex: number
    label: string
    storeLabel: string
    __typename?: 'ConfigurableProductOptionsValues'
}
export interface ConfigurableAttribute {
    /** Unused afaik */
    id: number
    /** This is used to let the server know the attribute a value is for. */
    attributeId: string
    /** A string representation of the id, not sent to the server. */
    attributeCode: string
    label: string
    position: number
    /** All possible values for the option. */
    values: ConfigurableAttributeValue[]
    __typename?: 'ConfigurableProductOptions'
}

export type ConfigurableProductVariantProduct = Pick<
    ProductCardFragment,
    'name' | 'stockStatus' | 'priceRange' | 'sku'
> & {
    onlyXLeftInStock: Maybe<number>
    __typename?: 'SimpleProduct'
}
export interface ConfigurableAttributeOption {
    /**
     * This maps to a configurable option type.
     * See also `configurableOptions`
     */
    code: string
    label: string
    uid: string
    /**
     * This maps to a configurable option value.
     * See also `ConfigurableOptionValue['valueIndex']`
     */
    valueIndex: number
    __typename?: 'ConfigurableAttributeOption'
}
export interface ConfigurableVariant {
    attributes: ConfigurableAttributeOption[]
    /**
     * If this is `null` this variant is out of stock.
     */
    product: Maybe<ConfigurableProductVariantProduct>
    __typename?: 'ConfigurableVariant'
}
