import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useStaleDataFallback } from '@emico/apollo'

import { Answer, useTweakwiseFunnel } from './useTweakwiseFunnel'
import { useTweakwiseFunnelProducts } from './useTweakwiseFunnelProducts'

function encodeAnswers(answers: Answer[]) {
    const state = new URLSearchParams()
    state.append(
        'an',
        answers
            .map((answer) =>
                [answer.questionId, ...(answer.answerIds ?? [])].join('|'),
            )
            .join('~'),
    )
    return state.toString()
}

function decodeAnswers(search: string) {
    const params = new URLSearchParams(search)
    if (params.get('an')) {
        return (
            params
                .get('an')
                ?.split('~')
                .map((question) => question.split('|'))
                .map((question) => ({
                    questionId: question[0],
                    skipQuestion: question.length <= 1,
                    answerIds: question.slice(1),
                })) ?? []
        )
    }
    return []
}

export function useTweakwiseFunnelState(code: string) {
    const history = useHistory()
    const { search } = useLocation()
    const [lastAnswers, setLastAnswers] = useState<Answer | null>(null)
    const [previousAnswers, setPreviousAnswers] = useState<Answer | null>(null)

    const answers = useMemo(() => {
        const params = new URLSearchParams(search)

        if (params.get('an')) {
            return (
                params
                    .get('an')
                    ?.split('~')
                    .map((question) => question.split('|'))
                    .map((question) => ({
                        questionId: question[0],
                        skipQuestion: question.length <= 1,
                        answerIds: question.slice(1),
                    })) ?? []
            )
        }
        return []
    }, [search])
    const { data, loading } = useTweakwiseFunnel(code, answers)
    const showResults = data?.questions
        ? !data?.questions?.some((question) => question?.current)
        : false
    const {
        data: products,
        navigationUrl,
        cid,
    } = useTweakwiseFunnelProducts(code, answers, {
        skip: !showResults,
    })

    const nextQuestion = data?.questions?.find((question) => question?.current)

    const addAnswer = (questionId: string, answerIds: string[]) => {
        setLastAnswers(null)
        history.push({
            search: encodeAnswers([
                ...answers,
                {
                    questionId,
                    answerIds,
                },
            ]),
        })
    }

    const back = () => {
        const newAnswers = answers.slice(0, -1)
        setLastAnswers(answers[answers.length - 1] ?? null)
        history.push({
            search: encodeAnswers(newAnswers),
        })
    }

    const isFirstQuestion = data?.questions?.[0]?.current === true

    return {
        loading,
        nextQuestion: useStaleDataFallback(nextQuestion),
        lastAnswers,
        addAnswer,
        back,
        items: data?.items,
        products: products,
        count: data?.count ?? 0,
        isFirstQuestion,
        navigationUrl,
        cid,
        showResults,
    }
}
