import defaultsToVars, { defaultsToCss, getVarKeys } from './cssVarsUtils'

const colorPrefix = 'color'

const paletteDefaults = {
    white: '#ffffff',
    whiteSmoke: '#f2f2f2',
    black: '#000000',
    grayDark: '#333',
    grayMiddle: '#999',
    grayLight: '#d0d0d0',
    grayLighter: '#efefef',
    graylightest: '#ececec',
    muted: '#9d9d9d',
    red: '#c0392b',
    blue: '#01558b',
    pink: '#ff1053',
    orange: '#FF784A',
    yellow: '#ffcd00',
    green: '#06AE7D',
    highlight: '#c66f07',
}

export const palette = defaultsToVars(paletteDefaults, colorPrefix)

const defaults = {
    primary: '#ff1053',
    primaryExtra: '#b50c3b',
    secondary: '#01558b',
    secondaryExtra: '#003b69',

    onPrimary: palette.white,
    onSecondary: palette.white,

    // Semantics
    success: palette.green,
    info: palette.blue,
    warning: palette.orange,
    error: palette.pink,

    // Feedback messages
    messageColorSuccess: '#1e8549',
    messageBackgroundSuccess: '#dcf9e8',
    messageIconBackgroundSuccess: '#1e8549',
    messageIconColorSuccess: 'white',

    messageColorWarning: '#935015',
    messageBackgroundWarning: '#fcecde',
    messageIconBackgroundWarning: '#eb9447',
    messageIconColorWarning: 'white',

    headings: palette.grayDark,
    text: palette.grayDark,
    textWhite: palette.white,
    textGrey: palette.muted,
    border: palette.grayMiddle,
    borderLight: palette.graylightest,
    background: palette.white,
    onBackground: '#1d1d1d',

    shadow: 'rgba(63, 63, 63, 0.14)',

    sale: palette.red,
    badgeBg: '#ff1053',
    badgeText: '#fff',
    badgeFill: '#fff',

    // Dark theme
    darkText: palette.white,
    darkHeadings: palette.white,
    darkBackground: '#1d1d1d',

    // Light theme
    lightText: palette.grayDark,
    lightHeadings: palette.grayDark,
    lightBackground: palette.white,

    // Promo banner background
    promoBannerBackground: '#eda600',

    // Footer
    footerTopBackground: '#000',
    footerMainBackground: '#000',
    footerTopBottomBorder1: '2px solid #ffffff50',
    footerTopBottomBorder2: '2px solid #ffffff50',
}

export const varKeys = getVarKeys(
    {
        ...paletteDefaults,
        ...defaults,
    },
    colorPrefix,
)

export const colorsCss = [
    ...defaultsToCss(paletteDefaults, colorPrefix),
    ...defaultsToCss(defaults, colorPrefix),
]

export const semantics = defaultsToVars(defaults, colorPrefix)

const colors = {
    ...palette,
    ...semantics,
}

export default colors
