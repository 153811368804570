import { DetailedHTMLProps, useCallback, useMemo, useState } from 'react'
import {
    Control,
    FormState,
    useForm,
    UseFormRegister,
    UseFormSetValue,
} from 'react-hook-form'
import Cookies from 'universal-cookie'

import { useActiveStoreView } from '@emico/storeviews'

import useIsProductInCart from './useProductIsInCart'
import { validateForm } from './validateForm'
import parseError, {
    getErrorUserMessage,
    reportUnkownError,
} from '../../checkout/parseError'
import useAddToCartFunction from '../../checkout/useAddToCartFunction'
import { ConfigurableProduct } from '../ProductPage/ConfigurableProduct'
import { Product } from '../ProductPage/GetProduct.query'
import { decodeAttributeValueObject } from '../ProductPage/StickyCta/flattenCombinedAttributes'

export interface ProductFormValues {
    quantity?: number
    configurableAttributes?: string
}

export interface UseProductFormReturn {
    /**
     * Props that should be applied to the wrapping form
     */
    formProps: DetailedHTMLProps<
        React.FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
    >
    configurableSelectProps: {
        // eslint-disable-next-line @typescript-eslint/ban-types
        control: Control<ProductFormValues, object>
        name: 'configurableAttributes'
    }
    // eslint-disable-next-line @typescript-eslint/ban-types
    control: Control<ProductFormValues, object>
    register: UseFormRegister<ProductFormValues>
    formState: FormState<ProductFormValues>
    /**
     * Wether or not the form is submitting
     */
    isSubmitting: boolean
    isInCart: boolean
    /**
     * API while submitting, often an error received from backend
     */
    submitError: string | undefined | null
    /**
     * Whether the submit button should be shown. e.g. not when a product cannot be purchased in a
     * country
     */
    showSubmit: boolean
    /**
     * Selected attributes. `false` when not a configurable product
     */
    selectedConfigurableAttributes: Record<number, number> | false
    setValue: UseFormSetValue<ProductFormValues>
}

const useProductFormSubmitIsAllowed = () => {
    const cookies = new Cookies()
    const visitorCountry = cookies.get('geo_country') as string | undefined
    const activeStoreView = useActiveStoreView()

    return !(
        visitorCountry &&
        visitorCountry === process.env.REACT_APP_HIDE_PRICE_FOR &&
        activeStoreView.code !== process.env.REACT_APP_FORCE_SHOW_PRICE_FOR
    )
}

interface ProductFormOptions {
    openMiniCartAfterAdd?: boolean
    defaultValues?: Partial<ProductFormValues>
}

const useProductForm = (
    product: Product | ConfigurableProduct,
    { openMiniCartAfterAdd = true, defaultValues }: ProductFormOptions = {},
): UseProductFormReturn => {
    const configurableProduct =
        'configurableOptions' in product ? product : undefined
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState<string | undefined | null>(
        null,
    )

    const { handleSubmit, register, control, watch, formState, setValue } =
        useForm<ProductFormValues>({
            defaultValues: {
                quantity: 1,
                ...defaultValues,
            },
        })

    const addToCart = useAddToCartFunction()
    const addProductToCart = useMemo(
        () => addToCart(product),
        [addToCart, product],
    )
    const showSubmit = useProductFormSubmitIsAllowed()

    const selectedConfigurableAttributesString = watch('configurableAttributes')

    const selectedConfigurableAttributes = selectedConfigurableAttributesString
        ? decodeAttributeValueObject<Record<number, number>>(
              selectedConfigurableAttributesString ?? '',
          )
        : configurableProduct
          ? /**/ {}
          : /**/ false

    const isInCart = useIsProductInCart(product, selectedConfigurableAttributes)

    const validate = useMemo(() => validateForm(product), [product])

    const handleAddToCart = useCallback(
        async (values: ProductFormValues) => {
            setIsSubmitting(true)
            setSubmitError(null)

            try {
                await addProductToCart(
                    values.quantity ?? 1,
                    values.configurableAttributes
                        ? decodeAttributeValueObject(
                              values.configurableAttributes,
                          )
                        : undefined,
                    openMiniCartAfterAdd,
                )
            } catch (err) {
                if (!(err instanceof Error)) {
                    return
                }
                // Handle server-side validation
                console.error('Add to cart error:', err)

                const errorMessage = getErrorUserMessage(err)

                reportUnkownError(errorMessage)
                setSubmitError(
                    parseError(
                        errorMessage,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        'params' in err ? (err as any).params : undefined,
                    ),
                )
            } finally {
                setIsSubmitting(false)
            }
        },
        [addProductToCart, openMiniCartAfterAdd],
    )

    return {
        formProps: useMemo(
            () => ({
                onSubmit: handleSubmit(handleAddToCart /* handleErrors */),
            }),
            [handleSubmit, handleAddToCart],
        ),
        configurableSelectProps: useMemo(
            () => ({
                name: 'configurableAttributes',
                control,
                rules: {
                    validate: (value: string) => validate(value ?? ''),
                },
            }),
            [control, validate],
        ),
        selectedConfigurableAttributes,
        control,
        register,
        formState,
        isSubmitting,
        isInCart,
        submitError,
        showSubmit,
        setValue,
    }
}

export default useProductForm
