import { useState } from 'react'

import EditGiftsModal from './EditGiftsModal'
import styles from './FreeGiftProductSelection.module.scss'
import { FreeGiftRule } from './FreeGifts'
import Button from '../input/Button'
import { GiftRule } from '../reducers'

export const FreeGiftProductSelection = ({
    rule,
    ruleCompleted,
}: {
    rule: FreeGiftRule | GiftRule
    ruleCompleted: boolean
}) => {
    const hasProductSelection = rule?.products.length > 1

    const [isOpen, setOpen] = useState<boolean>()

    return (
        <>
            {hasProductSelection && ruleCompleted ? (
                <Button
                    variant="linkInverted"
                    className={styles.changeGift}
                    name=""
                    category=""
                    onClick={() => setOpen(true)}
                >
                    Change gift
                </Button>
            ) : null}

            {isOpen && (
                <EditGiftsModal rules={[rule]} onBack={() => setOpen(false)} />
            )}
        </>
    )
}
