import { Trans } from '@lingui/macro'

import { resolveConfigurableOptions } from './CartItem'
import { parseOptionLabels } from './CartItem/parseOptionLabels'
import styles from './MiniCartItemSelectedOptions.module.scss'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { ProductCardFragment } from '../ProductCardFragment'
import {
    QuoteDataCartItemInterface,
    QuoteDataTotalsItemInterface,
} from '../types'

interface Props {
    cartItem: Pick<QuoteDataCartItemInterface, 'item_id' | 'product_id' | 'qty'>
    totals: QuoteDataTotalsItemInterface
    product: ProductCardFragment
}
const MiniCartItemSelectedOptions = ({ cartItem, totals, product }: Props) => {
    const selectedOptions =
        totals &&
        (product as ConfigurableProduct).configurableOptions &&
        resolveConfigurableOptions(
            parseOptionLabels(totals.options),
            product as ConfigurableProduct,
        )

    return (
        <>
            {selectedOptions &&
                selectedOptions.map(([attribute, value]) => (
                    <span
                        key={attribute.attributeId}
                        className={styles.configurableOption}
                    >
                        <strong>{attribute.label}:</strong> {value.storeLabel}
                    </span>
                ))}
            <span className={styles.amount}>
                <Trans id="cart.miniCart.amount">
                    <strong>Amount:</strong> {cartItem.qty}
                </Trans>
            </span>
        </>
    )
}

export default MiniCartItemSelectedOptions
