import { TweakwiseFunnelItem } from '@emico-hooks/graphql-schema-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'

import styles from './GuidedSellingResults.module.scss'
import { CategoryGridHeader } from '../catalog/CategoryGridHeader'
import CategoryFilter from '../catalog/CategoryPage/CategoryFilter'
import ProductFilterPage from '../catalog/common/ProductFilterPage'
import LoadMore from '../catalog/LoadMore'
import { ProductGrid } from '../catalog/ProductGrid'
import Button from '../input/Button'
import Col from '../layout/Col'
import Container from '../layout/Container'
import Row from '../layout/Row'
import Heading from '../typography/Heading'

const CategoryHeading = styled(Heading)`
    margin: 25px 0 20px;
    text-align: center;
`

export const GuidedSellingResults = ({
    items,
    link,
    cid,
}: {
    items: TweakwiseFunnelItem[]
    link: string
    cid: string
}) => {
    const params = new URLSearchParams(link)
    const filters = Object.fromEntries(
        [...params.entries()].map(([key, value]) => [key, [value]]),
    )
    return (
        <div className={styles.wrapper}>
            <ProductFilterPage
                categoryId={Number(cid)}
                pageName={t({
                    message: 'Guided selling results',
                    id: 'guidedSelling.results',
                })}
                filters={filters}
            >
                {({
                    mobileFilters,
                    desktopFilters,
                    items,
                    numResults: numTotalResults,
                    loadNextPage,
                    loadingNextPage,
                    loading,
                }) => (
                    <>
                        {mobileFilters}

                        {desktopFilters}

                        <Container>
                            <Row
                                css={css`
                                    opacity: ${loading ? 0.5 : 1};
                                `}
                            >
                                <Col>
                                    <ProductGrid listName="guided-selling">
                                        {items}
                                    </ProductGrid>

                                    {loadNextPage && (
                                        <LoadMore
                                            numItemsSeen={items.length}
                                            numTotalResults={numTotalResults}
                                            onClick={loadNextPage}
                                            loading={loadingNextPage}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </>
                )}
            </ProductFilterPage>
        </div>
    )
}
