import { TypePolicies } from '@apollo/client'
import { TweakwiseNavigationParams } from '@emico-hooks/graphql-schema-types'

import {
    QueryTweakwiseNavigationArgs,
    TweakwiseNavigationResult,
} from '@emico/graphql-schema-types'

export const typePolicies: TypePolicies = {
    Query: {
        fields: {
            tweakwiseNavigation: {
                keyArgs: [
                    'params',
                    [
                        'categoryId',
                        'facets',
                        'sortKey',
                        'sortDirection',
                        'filterTemplateId',
                        'sortTemplateId',
                        'parameters',
                        'personalParameters',
                        'keyboard',
                        'visitorKey',
                        'query',
                    ],
                ],
                merge(
                    existing: TweakwiseNavigationResult | null,
                    incomming: TweakwiseNavigationResult,
                    { args }: { args: QueryTweakwiseNavigationArgs | null },
                ): TweakwiseNavigationResult | null {
                    const page = args?.params?.page ?? 1
                    const pageSize = args?.params?.pageSize ?? 48
                    // Tweakwise is 1-indexed, so reduce by one.
                    const offset = (page - 1) * pageSize
                    const incommingItems = incomming.items ?? []
                    const merged = existing?.items
                        ? existing.items.slice(0)
                        : []

                    for (let i = 0; i < incommingItems.length; i += 1) {
                        merged[offset + i] = incommingItems[i]
                    }

                    return {
                        facets: incomming.facets,
                        items: merged,
                        properties: {
                            ...existing?.properties,
                            ...incomming.properties,
                        },
                        redirects: incomming.redirects,
                        __typename: 'TweakwiseNavigationResult',
                    }
                },
            },
        },
    },
}
