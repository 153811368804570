import { handleActions, ReducerMap } from 'redux-actions'

import actions from '../actions/cart'
import checkoutActions from '../actions/checkout'
import { CartItemData } from '../cart/CartItem'
import {
    QuoteDataCartInterface,
    QuoteDataPaymentMethodInterface,
    QuoteDataTotalsInterface,
} from '../types/MagentoRestApi'

export interface UpdateItemError {
    cartItemId: number
    quantity: number
    salableQuantity: number | undefined
    error: boolean
}

export interface GiftRuleProduct {
    id: number
    type: string
    item?: CartItemData
}

export interface GiftRule {
    is_available: boolean
    is_valid: boolean
    products: GiftRuleProduct[]
    rule_id: number
    label?: string
    item_ids?: number[]
    image?: string
    insufficient_amount?: number
    max_items?: number
    tooltip?: string
    is_valid_conditions?: boolean
    is_valid_customer_group?: boolean
    claimed_label?: string
    unclaimed_label?: string
}

export interface CartState {
    details?: QuoteDataCartInterface
    gifts?: GiftRule[]
    newGiftsAvailable?: GiftRule[]
    isUpdating: boolean
    paymentMethods?: QuoteDataPaymentMethodInterface[]
    totals?: QuoteDataTotalsInterface
    // undefined is here for backwards compatibility with existing carts that miss this value
    updateItemErrors: UpdateItemError[] | undefined
    isMiniCartOpen: boolean
    miniCartFreeGiftRuleIds?: number[]
    giftsHaveChanged: boolean
}

export const name = 'cart'

export const initialState: CartState = {
    details: undefined,
    gifts: undefined,
    isUpdating: false,
    paymentMethods: undefined,
    totals: undefined,
    updateItemErrors: [],
    isMiniCartOpen: false,
    miniCartFreeGiftRuleIds: undefined,
    newGiftsAvailable: undefined,
    giftsHaveChanged: false,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducerMap: ReducerMap<CartState, any> = {
    [actions.getDetails.receive.toString()]: (
        state,
        {
            payload,
            error,
        }: {
            payload: Partial<CartState>
            error?: boolean
        },
    ): CartState => {
        if (error) {
            return {
                ...state,
                isUpdating: false,
            }
        }

        const getItemWithSelectedOptions = (
            id: number,
            productId: number,
        ): CartItemData | undefined => {
            const item = payload.details?.items?.find(
                (i) => i.item_id === id && i.product_id === productId,
            )

            if (!item) {
                return
            }

            const totals = payload?.totals?.items?.find(
                (totalItem) => totalItem.item_id === item.item_id,
            )

            if (!totals) {
                return
            }

            return { ...item, totals }
        }

        // Build cart gift state
        const gifts = payload.details?.extension_attributes?.free_gifts?.map(
            (item) => ({
                ...item,
                products: [...item.products].map((product) => ({
                    ...product,
                    item: (item.item_ids || []).reduce<
                        CartItemData | undefined
                    >((acc, itemId) => {
                        // For each selected product, get the cart data from state.
                        if (!itemId) {
                            return acc
                        }

                        const item = getItemWithSelectedOptions(
                            itemId,
                            product.id,
                        )

                        if (item) {
                            acc = item
                        }

                        return acc
                    }, undefined),
                })),
            }),
        )

        // Detect if new gift rules are available.
        const newGiftsAvailable = gifts?.filter(({ is_valid }) => is_valid)

        return {
            ...state,
            ...payload,
            gifts,
            isUpdating: false,
            giftsHaveChanged:
                state.newGiftsAvailable?.length !== newGiftsAvailable?.length,
            newGiftsAvailable,
        }
    },
    [actions.updateItem.request.toString()]: (state): CartState => ({
        ...state,
        isUpdating: true,
    }),
    [actions.updateItem.receive.toString()]: (
        state,
        {
            payload,
        }: {
            payload: UpdateItemError
        },
    ): CartState => {
        // remove old item because error might be fixed or qty might be updated
        const newItems = [...(state.updateItemErrors || [])].filter(
            (item) => item.cartItemId !== payload.cartItemId,
        )

        if (payload.error) {
            newItems.push(payload)
        }
        return {
            ...state,
            updateItemErrors: newItems,
            isUpdating: false,
        }
    },

    [actions.removeItem.request.toString()]: (state): CartState => ({
        ...state,
        isUpdating: true,
    }),
    [actions.removeItem.receive.toString()]: (
        state,
        {
            payload,
            error,
        }: {
            payload: {
                cartItemId: number
            }
            error?: boolean
        },
    ): CartState => {
        if (error) {
            return {
                ...initialState,
            }
        }
        // remove old item because error might be fixed or qty might be updated
        const newItems = [...(state.updateItemErrors || [])].filter(
            (item) => item.cartItemId !== payload.cartItemId,
        )

        return { ...state, updateItemErrors: newItems }
    },
    [actions.miniCart.open.toString()]: (
        state,
        { payload }: { payload?: { freeGiftRuleIds: number[] } },
    ): CartState => {
        const { freeGiftRuleIds } = payload || {}

        return {
            ...state,
            miniCartFreeGiftRuleIds: freeGiftRuleIds,
            isMiniCartOpen: true,
        }
    },
    [actions.miniCart.close.toString()]: (state): CartState => ({
        ...state,
        miniCartFreeGiftRuleIds: undefined,
        isMiniCartOpen: false,
    }),
    [checkoutActions.order.accept.toString()]: (): CartState => initialState,
    [actions.reset.toString()]: () => initialState,
}

export default handleActions(reducerMap, initialState)
