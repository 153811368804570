import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { stripMaybes } from '@emico/utils'

import { SrsStore, srsStoreFragment } from './useSrsStore'
import {
    Maybe,
    SrsStoreFilterInput,
    SrsStores,
} from '../graphql/schema.generated'

export interface LocationProps {
    lat: number
    lng: number
}

const query = gql`
    query SrsStores(
        $coordinates: CoordinatesInput
        $filters: SrsStoreFilterInput
        $pageSize: Int
        $currentPage: Int
        $skipStock: Boolean!
    ) {
        srsStores(
            coordinates: $coordinates
            currentPage: $currentPage
            pageSize: $pageSize
            filters: $filters
        ) {
            totalCount
            pageInfo {
                pageSize
                currentPage
                totalPages
            }
            items {
                skuStock @skip(if: $skipStock)
                ...SrsStoreFragment
            }
        }
    }
    ${srsStoreFragment}
`

export interface SrsStoreProps {
    coordinates?: LocationProps
    filters?: SrsStoreFilterInput
    pageSize?: number
    currentPage?: number
    options?: Pick<QueryHookOptions, 'skip'>
    skipStock?: boolean
    minimalData?: boolean
}

export type StoreFilter = 'justbrands' | 'vanguard' | 'pme' | 'castiron' | ''

export const useSrsStores = ({
    coordinates,
    filters,
    pageSize,
    currentPage,
    options,
    skipStock = false,
}: SrsStoreProps) => {
    const { data, error, loading, ...rest } = useQuery<{
        srsStores: Pick<SrsStores, 'pageInfo' | 'totalCount'> & {
            items: Maybe<Array<Maybe<SrsStore>>>
        }
    }>(query, {
        ...options,
        variables: {
            coordinates: coordinates
                ? { latitude: coordinates?.lat, longitude: coordinates?.lng }
                : undefined,
            filters,
            pageSize,
            currentPage,
            skipStock,
        },
        errorPolicy: 'all',
    })

    const items = data?.srsStores.items
    const stores = useMemo(() => items?.filter(stripMaybes) ?? [], [items])

    const filterStores = useMemo(
        () =>
            coordinates?.lat &&
            coordinates.lng &&
            stores?.filter((store) => store.distance && store.distance <= 30),
        [stores, coordinates?.lat, coordinates?.lng],
    )

    return {
        data: stores,
        filteredStores: filterStores || undefined,
        loading,
        totalCount: data?.srsStores?.totalCount,
        ...rest,
    }
}
