import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './Loader.module.scss'
import Button from '../../input/Button'
import Loader from '../Loader'

export interface Props {
    className?: string
    reason?: string
    fullScreen?: boolean
}

const PageLoader = ({ className, fullScreen, reason }: Props) => {
    const [message, setMessage] = React.useState<string | undefined>(
        process.env.REACT_APP_LOADER_REASONS === 'true' ? reason : undefined,
    )
    const [allowTryAgain, setAllowTryAgain] = React.useState<boolean>(false)

    React.useEffect(() => {
        let timer: ReturnType<typeof setTimeout>

        if (window.percolate) {
            window.percolate.isReady = false
        }

        timer = setTimeout(() => {
            setMessage(
                t({
                    id: 'core.loader.takingLongerThanUsual',
                    message: `It's taking longer than usual...`,
                }),
            )
            timer = setTimeout(() => setAllowTryAgain(true), 10000)
        }, 5000)
        return () => {
            if (window.percolate) {
                window.percolate.isReady = true
            }
            clearTimeout(timer)
        }
    }, [])

    return (
        <div
            className={cx(
                fullScreen ? styles.fullScreen : undefined,
                className,
            )}
        >
            <Loader />

            {message && (
                <div className={styles.reason}>
                    <div>{message}</div>

                    {allowTryAgain && (
                        <Button
                            minWidth
                            variant="primary"
                            name="Try again"
                            category="core.loader.tryAgain"
                            className={styles.tryAgain}
                            to={window.location.href}
                        >
                            <Trans id="core.loader.tryAgain">Try again?</Trans>
                        </Button>
                    )}
                </div>
            )}
        </div>
    )
}

export default PageLoader
