import { useCallback, useState } from 'react'

import { removeItemFromCart, updateItemInCart } from '../actions'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import useAddToCartFunction from '../checkout/useAddToCartFunction'
import { useDispatch, useSelector } from '../types'
import { useAddToCartEvent } from '../utils/ga4/useAddToCartEvent'

type UpdateCartArgs = {
    product: Product | ConfigurableProduct
    options: ConfigurableAttributesFieldValue | undefined
}

type UpdateCart = (value: UpdateCartArgs[]) => Promise<void>

type CartAction = () => Promise<void>

export const useUpdateCart = (): {
    loading: boolean
    updateCart: UpdateCart
} => {
    const dispatch = useDispatch()
    const addToCart = useAddToCartFunction()
    const pushAddToCart = useAddToCartEvent()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const cartItems = useSelector((state) => state.cart?.details?.items)

    const updateCart = useCallback(
        async (value: UpdateCartArgs[]) => {
            let cartActions: Array<{
                isDelete: boolean
                action: CartAction
            }> = []

            for (const { product, options } of value) {
                const isSingleSizeProduct =
                    product?.__typename === 'SimpleProduct' ||
                    (product as ConfigurableProduct).variants?.length === 1

                const prodId = product.id
                const hasOptions = !options
                    ? false
                    : Object.keys(options).length > 0
                const isCartItem = cartItems?.find(
                    (item) => item.product_id === prodId,
                )

                if (isCartItem && !hasOptions) {
                    // delete existing item
                    cartActions = [
                        ...cartActions,
                        {
                            action: async () =>
                                await dispatch(
                                    removeItemFromCart(isCartItem, product),
                                ),
                            isDelete: true,
                        },
                    ]
                } else if (isCartItem && hasOptions && !isSingleSizeProduct) {
                    // update item
                    cartActions = [
                        ...cartActions,
                        {
                            isDelete: false,
                            action: async () =>
                                await dispatch(
                                    updateItemInCart(
                                        () => {
                                            pushAddToCart(
                                                isCartItem.sku,
                                                product,
                                                1,
                                                options,
                                            )
                                        },
                                        isCartItem.item_id ?? 0,
                                        product,
                                        1,
                                        options,
                                    ),
                                ),
                        },
                    ]
                } else if (!isCartItem && hasOptions) {
                    // add item:
                    cartActions = [
                        ...cartActions,
                        {
                            isDelete: false,
                            action: async () =>
                                await addToCart(product, 1, options, false),
                        },
                    ]
                }
            }
            cartActions = cartActions.sort((a, _) => (a.isDelete ? -1 : 0))

            const runActions = async (actions: CartAction[]) => {
                setIsLoading(true)
                for (const action of actions) {
                    await action()
                }
                setIsLoading(false)
            }
            if (cartActions.length) {
                return await runActions(
                    cartActions.map((action) => action.action),
                )
            }
            return Promise.resolve()
        },
        [addToCart, cartItems, dispatch, pushAddToCart],
    )

    return {
        updateCart,
        loading: isLoading,
    }
}
