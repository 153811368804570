import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'

import { useCollapse } from './behaviour/Collapse'
import DropdownIcon from './core/DropdownIcon'
import Icon from './media/Icon'
import theme from './theme'
import HeadingElement from './types/HeadingElement'
import HeadingVariant from './types/HeadingVariant'
import Heading from './typography/Heading'

const ToggleIcon = styled(Icon)`
    width: 15px;
    height: 15px;
`

const toggleIconWrapper = css`
    transform: rotate(0deg);
    transition: transform 0.2s ease;
`

const ToggleButton = styled.button`
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    padding: 1em 0;

    &,
    &:focus {
        outline: none;
    }

    &:focus-visible {
        text-decoration: underline;
    }
`

const Header = styled(Heading)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
`

const Wrapper = styled.div`
    border-top: 1px solid ${theme.colors.borderLight};

    &:last-of-type {
        border-bottom: 1px solid ${theme.colors.borderLight};
    }
`

const toggled = css`
    transform: rotate(180deg);
`

const Content = styled.div`
    padding-bottom: 20px;
`

interface AccordionItemProps {
    heading: React.ReactNode
    content: React.ReactNode
    onClick(): void
    open: boolean
    element?: HeadingElement
    variant?: HeadingVariant
}

const AccordionItem = ({
    heading,
    content,
    open,
    onClick,
    element = HeadingElement.h2,
    variant = HeadingVariant.h3,
}: AccordionItemProps) => {
    const [getCollapseProps, getToggleProps] = useCollapse({ isOpen: open })
    const props = getCollapseProps()

    return (
        <ClassNames>
            {({ css }) => (
                <Wrapper>
                    <ToggleButton
                        {...getToggleProps({
                            onClick: () => {
                                onClick()
                                if (!open) {
                                    setTimeout(() => {
                                        // eslint-disable-next-line react/prop-types
                                        props.ref?.current?.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'nearest',
                                            inline: 'nearest',
                                        })
                                    }, 155)
                                }
                            },
                        })}
                    >
                        <Header variant={variant} element={element}>
                            {heading}

                            <ToggleIcon
                                component={DropdownIcon}
                                title="Arrow"
                                css={css(`fill: currentColor;`)}
                                wrapperClassName={css(
                                    toggleIconWrapper,
                                    open ? toggled : undefined,
                                )}
                            />
                        </Header>
                    </ToggleButton>
                    <div {...props}>
                        <Content>{content}</Content>
                    </div>
                </Wrapper>
            )}
        </ClassNames>
    )
}

export default AccordionItem
