import { ConfigurableAttributeOption } from '@emico-hooks/graphql-schema-types'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { Trans } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'

import styles from './ConfigurableAttributeCombinedWidthAndLength.module.scss'
import RadioButton from '../../../input/RadioButton'
import Heading from '../../../typography/Heading'
import { ConfigurableVariant } from '../../common/MinimalConfigurableProductInfo.fragment'
import { useSelectedConfigurableValue } from '../ConfigurableAttributesField/ConfigurableAttributesField'
import {
    CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
    CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
    isOutOfStock,
} from '../ConfigurableAttributesField/helpers'
import {
    ConfigurableOptions,
    ConfigurableProduct,
} from '../ConfigurableProduct'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
    product: ConfigurableProduct
    onChange(widthValueId?: number, lengthValueId?: number): void
    value: {
        widthValueId: number | undefined
        lengthValueId: number | undefined
    }
}

type ConfigurableAttributeOptionWithStock =
    | (ConfigurableAttributeOption & {
          checkCombinedStockStatus: (
              otherOptionCode: string,
              otherOption?: ConfigurableAttributeOption,
          ) => boolean
          isOutOfStock: boolean
      })
    | undefined

const getAttributeOptionByValue = (
    valueId: number,
    attributeOptions: ConfigurableAttributeOptionWithStock[],
) => attributeOptions.find((opt) => opt?.valueIndex === valueId)

const parseAttributes =
    (variants: ConfigurableVariant[]) => (attrCode: string) => {
        const variantAttributes: ConfigurableAttributeOption[] = []

        for (const variant of variants) {
            const { attributes } = variant
            const attr = attributes
                .filter((attr) => attr.code === attrCode)
                .at(0)

            // width & length attributes should be unique values:
            if (
                attr &&
                !variantAttributes.find(
                    (attrOption) => attrOption.valueIndex === attr.valueIndex,
                )
            ) {
                variantAttributes.push(attr)
            }
        }
        return variantAttributes
    }

const parseVariants = (product: ConfigurableProduct) => {
    const parseVariantAttributes = parseAttributes(product.variants)

    const optionIsOutOfStock = (
        variants: ConfigurableVariant[],
        code: string,
        attributeOption: ConfigurableAttributeOption,
    ) =>
        variants
            .filter((variant) =>
                variant.attributes.find(
                    (attr) =>
                        attr.code === code &&
                        attr.valueIndex === attributeOption.valueIndex,
                ),
            )
            .every(isOutOfStock)

    const optionCombinedIsOutOfStock =
        (
            option: ConfigurableAttributeOption,
            currentOptionCode: string,
            variants: ConfigurableVariant[],
        ) =>
        (
            otherOptionCode: string,
            otherOption?: ConfigurableAttributeOption,
        ) => {
            if (!otherOption || !otherOption.valueIndex) {
                return false
            }
            const currentOptionValue = option.valueIndex
            const otherOptionValue = otherOption.valueIndex

            const variantFound = variants.find((variant) => {
                const { attributes } = variant

                const currentAttr = attributes.find(
                    (at) => at.code === currentOptionCode,
                )

                const otherAttr = attributes.find(
                    (at) => at.code === otherOptionCode,
                )

                if (
                    currentAttr?.valueIndex === currentOptionValue &&
                    otherAttr?.valueIndex === otherOptionValue
                ) {
                    return variant
                }
                return null
            })

            return isOutOfStock(variantFound)
        }

    const widthOptions = parseVariantAttributes(
        CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
    ).map((opt) => ({
        ...opt,
        isOutOfStock: optionIsOutOfStock(
            product.variants,
            CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
            opt,
        ),
        checkCombinedStockStatus: optionCombinedIsOutOfStock(
            opt,
            CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
            product.variants,
        ),
    }))

    const lengthOptions = parseVariantAttributes(
        CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
    ).map((opt) => ({
        ...opt,
        isOutOfStock: optionIsOutOfStock(
            product.variants,
            CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
            opt,
        ),
        checkCombinedStockStatus: optionCombinedIsOutOfStock(
            opt,
            CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
            product.variants,
        ),
    }))

    return { lengthOptions, widthOptions }
}

function sortOptions<T extends { valueIndex?: number | null }>(
    configurableOptions: ConfigurableOptions[],
    code: string,
    items: T[],
) {
    return configurableOptions
        .find((item) => item.attributeCode === code)
        ?.values.map((item) => item.valueIndex)
        .map((index) => items.find((item) => item.valueIndex === index))
        .filter(stripMaybes)
}

const ConfigurableAttributeCombinedWidthAndLength = ({
    product,
    onChange,
    className,
    value,
    ...others
}: Props) => {
    const selectedValueVar = useSelectedConfigurableValue(product)

    const { lengthOptions, widthOptions } = React.useMemo(
        () => parseVariants(product),
        [product],
    )

    const [selectedWidth, setSelectedWidth] =
        React.useState<ConfigurableAttributeOptionWithStock>()

    const [selectedLength, setSelectedLength] =
        React.useState<ConfigurableAttributeOptionWithStock>()

    React.useEffect(() => {
        const { widthValueId, lengthValueId } = value

        if (widthValueId) {
            setSelectedWidth(
                getAttributeOptionByValue(widthValueId, widthOptions),
            )
        }
        if (lengthValueId) {
            setSelectedLength(
                getAttributeOptionByValue(lengthValueId, lengthOptions),
            )
        }
        if (!selectedValueVar) {
            onChange(widthValueId, lengthValueId)
        }
    }, [value, widthOptions, lengthOptions, onChange, selectedValueVar])

    const { lengthComponents, widthComponents } = React.useMemo(() => {
        const sortedWidthOptions = sortOptions(
            product.configurableOptions,
            CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
            widthOptions,
        )

        const widthComponents = sortedWidthOptions?.map((widthOption) => {
            const optionIsOutOfStock = widthOption.isOutOfStock

            const isNoCombination = widthOption.checkCombinedStockStatus(
                CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
                selectedLength,
            )

            return (
                <RadioButton
                    type="checkbox"
                    className={cx(styles.radioButton, {
                        [styles.notAvailable]:
                            isNoCombination && !optionIsOutOfStock,
                    })}
                    value={`${widthOption?.valueIndex}`}
                    key={widthOption.valueIndex}
                    checked={
                        !isNoCombination &&
                        selectedWidth?.valueIndex === widthOption.valueIndex
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                            setSelectedWidth(widthOption)
                            onChange(
                                Number(widthOption.valueIndex),
                                selectedLength?.valueIndex ?? undefined,
                            )
                        } else {
                            setSelectedWidth(undefined)
                            onChange(
                                undefined,
                                selectedLength?.valueIndex ?? undefined,
                            )
                        }
                    }}
                    disabled={optionIsOutOfStock}
                >
                    {widthOption.label}
                </RadioButton>
            )
        })

        const sortedLengthOptions = sortOptions(
            product.configurableOptions,
            CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
            lengthOptions,
        )

        const lengthComponents = sortedLengthOptions?.map((lengthOption) => {
            const optionIsOutOfStock = lengthOption.isOutOfStock
            const isNoCombination = lengthOption.checkCombinedStockStatus(
                CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
                selectedWidth,
            )

            return (
                <RadioButton
                    type="checkbox"
                    className={cx(styles.radioButton, {
                        [styles.notAvailable]:
                            isNoCombination && !optionIsOutOfStock,
                    })}
                    key={lengthOption.valueIndex}
                    value={`${lengthOption?.valueIndex}`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                            setSelectedLength(lengthOption)
                            onChange(
                                selectedWidth?.valueIndex ?? undefined,
                                Number(lengthOption?.valueIndex),
                            )
                        } else {
                            setSelectedLength(undefined)
                            onChange(
                                selectedWidth?.valueIndex ?? undefined,
                                undefined,
                            )
                        }
                    }}
                    checked={
                        !isNoCombination &&
                        selectedLength?.valueIndex === lengthOption.valueIndex
                    }
                    disabled={optionIsOutOfStock}
                >
                    {lengthOption.label}
                </RadioButton>
            )
        })

        return {
            widthComponents,
            lengthComponents,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lengthOptions, widthOptions, selectedLength, selectedWidth, onChange])

    return (
        <div
            className={styles.configurableAttributes}
            data-bc-position="pdp-productinfo-sizes"
        >
            <Heading variant="h4" element="h4" className={styles.optionHeading}>
                <Trans id="catalog.productPage.options.waist">Waist</Trans>
            </Heading>
            <div
                className={cx(styles.configurableOptionSize, className)}
                {...others}
            >
                {widthComponents}
            </div>

            <Heading variant="h4" element="h4" className={styles.optionHeading}>
                <Trans id="catalog.productPage.options.length">Length</Trans>
            </Heading>
            <div
                className={cx(styles.configurableOptionSize, className)}
                {...others}
            >
                {lengthComponents}
            </div>
        </div>
    )
}

export default ConfigurableAttributeCombinedWidthAndLength
