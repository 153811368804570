import { makeVar } from '@apollo/client'
import styled from '@emotion/styled'
import cx from 'classnames'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import { Icon, useBreakpoints } from '@emico/ui'

import styles from './TopBarBanner.module.scss'
import CloseIcon from '../../../core/CloseIcon'
import { useCoreConfigValue } from '../../../coreConfig.query'
import Button from '../../../input/Button'
import push from '../../../utils/googleTagManager/push'
import Container from '../../Container'

const HIDE_BANNER_KEY = 'hide-app-banner'
const BANNER_MOBILE_KEY = 'app-banner-mobile'
const BANNER_DESKTOP_KEY = 'app-banner-desktop'

interface TopBannersPromoI {
    value?: number | undefined
}
export const topBannersPromo = makeVar<TopBannersPromoI | undefined>(undefined)

const StyledIcon = styled(Icon)`
    width: 15px;
    height: 15px;
`

const TopBarBanner = () => {
    // TODO: Is it necessary to get the cookies multiple times?
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cookies = new Cookies()
    const bannerRef = React.useRef<HTMLDivElement>(null)
    const { push: historyPush } = useHistory()
    const { isMobile } = useBreakpoints()
    const [isHidden, setHidden] = React.useState<boolean>(false)
    const { value: bannerEnabled, loading: loadingBannerEnabled } =
        useCoreConfigValue('justbrands_banner/banner/enabled')

    const { value: bannerContentMobile } = useCoreConfigValue(
        'justbrands_banner/banner/content_mobile',
    )

    const { value: bannerContentDesktop } = useCoreConfigValue(
        'justbrands_banner/banner/content_desktop',
    )

    const { value: bannerTextColor } = useCoreConfigValue(
        'justbrands_banner/banner/textcolor',
    )

    const { value: bannerBgColor } = useCoreConfigValue(
        'justbrands_banner/banner/bgcolor',
    )

    const { value: bannerLink } = useCoreConfigValue(
        'justbrands_banner/banner/link',
    )

    const isBannerEnabled = bannerEnabled === '1'
    const bannerContent = isMobile ? bannerContentMobile : bannerContentDesktop
    const isHiddenByUser = cookies.get(HIDE_BANNER_KEY) === '1'

    React.useEffect(() => {
        topBannersPromo({
            value: isHiddenByUser ? 0 : 1,
        })
    }, [isHiddenByUser])

    // Determine if app banner should be shown
    React.useEffect(() => {
        const isHiddenByUser = cookies.get(HIDE_BANNER_KEY) === '1'
        const mobileHash = cookies.get(BANNER_MOBILE_KEY)
        const desktopHash = cookies.get(BANNER_DESKTOP_KEY)
        const bannerHash = bannerContent && btoa(encodeURI(bannerContent))

        // If user has explicitly hidden the banner, dont show it
        // Also, always show banner when its contents are different than
        // when the user closed the banner (indicates an update to the banner contents)
        if (
            !isHiddenByUser ||
            (isMobile && bannerHash && mobileHash !== bannerHash) ||
            (!isMobile && bannerHash && desktopHash !== bannerHash)
        ) {
            setHidden(false)
        }
    }, [bannerContent, cookies, isMobile])

    if (
        loadingBannerEnabled ||
        !isBannerEnabled ||
        !bannerContent ||
        isHidden
    ) {
        return null
    }

    const handleClick = bannerLink
        ? () => {
              historyPush(bannerLink)
          }
        : undefined

    const handleCloseClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.stopPropagation()

        push({ event: 'closePromobar' })

        // Set a cookie that expires 30 days from now
        const date = new Date()

        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000)
        cookies.set(HIDE_BANNER_KEY, '1', {
            path: '/',
            expires: date,
        })

        // Store hash value of contents
        // This way we can check if banner content has changed
        // and show the banner when it has changed
        if (bannerContentMobile) {
            cookies.set(BANNER_MOBILE_KEY, btoa(encodeURI(bannerContentMobile)))
        }
        if (bannerContentDesktop) {
            cookies.set(
                BANNER_DESKTOP_KEY,
                btoa(encodeURI(bannerContentDesktop)),
            )
        }
        setHidden(true)
    }

    return (
        <div
            className={cx(styles.base, {
                [styles.link]: bannerLink,
            })}
            onClick={handleClick}
            ref={bannerRef}
            style={{
                backgroundColor: bannerBgColor,
            }}
            data-id="topBarBanner"
        >
            <Container>
                <div className={styles.banner}>
                    <div
                        style={{
                            color: bannerTextColor,
                        }}
                        className={styles.label}
                        dangerouslySetInnerHTML={{
                            __html: bannerContent,
                        }}
                    />
                    <Button
                        style={{
                            color: bannerTextColor,
                        }}
                        className={styles.close}
                        variant="default"
                        onClick={handleCloseClick}
                        name="Close"
                        category="core.topbarbanner.close"
                    >
                        <StyledIcon component={CloseIcon} />
                    </Button>
                </div>
            </Container>
        </div>
    )
}

export default TopBarBanner
