import { ClassNames, css } from '@emotion/react'

import usePrismicFooter from './prismicFooterData'
import { PageBodyCategoryList } from '../../graphql/schema.generated'
import Container from '../../layout/Container'
import LinkList from '../../LinkList'
import mapPrismicSliceCategoryList from '../../mapPrismicSliceCategoryList'

const grid = css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
`

const gridItem = css`
    padding: 0;
    max-width: none;
`

const MenuBlock = () => {
    let linkListData: PageBodyCategoryList | undefined

    const LinkListDataArray = [
        'FooterPmeBodyCategoryList',
        'FooterJustbrandsBodyCategoryList',
        'FooterCastironBodyCategoryList',
        'FooterVanguardBodyCategoryList',
        'FooterJbfoBodyCategoryList',
        'FooterPmeoutletBodyCategoryList',
        'FooterMonstersaleBodyCategoryList',
    ]

    const { data } = usePrismicFooter()
    const body = data?.body as PageBodyCategoryList[]

    if (data && body) {
        linkListData = body.find((item) => {
            if (item.__typename) {
                return LinkListDataArray.includes(item.__typename)
            }
            return null
        }) as PageBodyCategoryList
    }

    if (!linkListData) {
        return null
    }

    const { items } = mapPrismicSliceCategoryList(linkListData)

    if (!items || items.length === 0) {
        return null
    }

    return (
        <ClassNames>
            {({ css }) => (
                <Container>
                    <LinkList
                        // This is not the React style prop...
                        // eslint-disable-next-line react/style-prop-object
                        style="dark"
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        items={items}
                        gridClassName={css(grid)}
                        gridItemClassName={css(gridItem)}
                    />
                </Container>
            )}
        </ClassNames>
    )
}

export default MenuBlock
