import classNames from 'classnames'
import { ReactNode, useEffect, useRef, useState } from 'react'

import styles from './progressbar-loyalty.module.css'
import { usePrevious } from './usePrevious.hook'
import ProgressIndicator from '../core/ProgressIndicator/PlaneIcon'
import { useSelector } from '../types'
import Text from '../typography/Text'

export const ProgressBarLoyalty = ({
    insufficientAmount,
    showProgress,
    isCompleted = false,
    reverse = false,

    children,
}: {
    insufficientAmount?: number
    showProgress: boolean
    isCompleted?: boolean
    reverse?: boolean
    children?: ReactNode
}) => {
    const total = useSelector((state) => state.cart?.totals?.subtotal_incl_tax)
    const progressBarRef = useRef<HTMLDivElement>(null)
    const [isReversed, setReversed] = useState<boolean>()
    const [percentage, setPercentage] = useState<number>()

    const previousAmount = usePrevious(insufficientAmount)
    const mountedRef = useRef<boolean | undefined>()
    const iconRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (isCompleted) {
            setPercentage(100)
        } else {
            if (insufficientAmount && total) {
                setPercentage((100 * total) / (total + insufficientAmount))
            }
        }
    }, [insufficientAmount, total, isCompleted])

    useEffect(() => {
        if (!progressBarRef.current || !iconRef.current) {
            return
        }
        progressBarRef.current.style.setProperty(
            '--icon-width',
            `${iconRef.current.offsetWidth}px`,
        )
        progressBarRef.current.style.setProperty(
            '--progress-width',
            `${percentage}%`,
        )
    }, [percentage])

    useEffect(() => {
        if (!mountedRef.current) {
            mountedRef.current = true
            return
        }
        const prev = previousAmount ?? 0
        const current = insufficientAmount ?? 0
        if (prev < current) {
            setReversed(true)
            setTimeout(() => {
                setReversed(false)
            }, 800)
        } else if (prev > current) {
            setReversed(false)
        }
    }, [insufficientAmount, previousAmount])

    return (
        <div className={styles.row}>
            <div
                className={classNames(styles.progressBarWrapper, {
                    [styles.show]: showProgress,
                })}
            >
                <div
                    className={classNames(styles.progressBar, {
                        [styles.completed]: isCompleted,
                    })}
                />
                <div
                    className={classNames(styles.progressBarFill, {
                        [styles.fillProgress]: showProgress,
                        [styles.reversed]: isReversed,
                        [styles.completed]: isCompleted,
                    })}
                    ref={progressBarRef}
                >
                    <div ref={iconRef} className={styles.icon}>
                        <ProgressIndicator />
                    </div>
                </div>
            </div>
            {children && (
                <div className={classNames(styles.progressInfo)}>
                    <Text size="small">{children}</Text>
                </div>
            )}
        </div>
    )
}
