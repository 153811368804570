import cx from 'classnames'
import * as React from 'react'

import styles from './Banner.module.scss'
import ImageLazy from '../../media/ImageLazy'
import Link from '../Link'

export interface Props
    extends React.HTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
    imageUrl: string
    to?: string
    className?: string
    children?: React.ReactNode
}

const Banner = ({
    imageUrl,
    to,
    className,
    children,
    ...otherProps
}: Props) => {
    const Component = to ? Link : 'div'

    return (
        <Component
            {...otherProps}
            name="Banner"
            category="navigation.subNav"
            to={to ?? ''}
            resolver
            className={cx(styles.base, className)}
        >
            <ImageLazy
                alt=""
                images={{
                    image: imageUrl,
                    regularWidth: 250,
                    mediumWidth: 250,
                    smallWidth: 250,
                }}
                className={styles.image}
            />
            {children}
        </Component>
    )
}

export default Banner
