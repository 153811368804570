import * as React from 'react'

import AccordionItemComp from './AccordionItem'
import HeadingElement from './types/HeadingElement'
import HeadingVariant from './types/HeadingVariant'

export interface AccordionItem {
    heading: React.ReactNode
    content: React.ReactNode
}

interface Props {
    items: AccordionItem[]
    initial?: number
    element?: HeadingElement
    variant?: HeadingVariant
    className?: string
}

const Accordion = ({ items, initial, element, variant, className }: Props) => {
    const initialItem =
        initial && initial > items.length ? items.length : initial
    const [open, setOpen] = React.useState<number | undefined>(initialItem)

    React.useEffect(() => {
        setOpen(initialItem)
    }, [initialItem, items])

    return (
        <div className={className}>
            {items.map(({ heading, content }, index) => (
                <AccordionItemComp
                    heading={heading}
                    key={`accordion-item-${index}`}
                    content={content}
                    element={element}
                    variant={variant}
                    open={open === index}
                    onClick={() => setOpen(open === index ? undefined : index)}
                />
            ))}
        </div>
    )
}

export default Accordion
