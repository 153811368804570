import { ComponentProps } from 'react'

import { isSignedIn } from './actions'
import {
    PageBodyMemberUspS,
    PageBodyMemberUspSFields,
} from './graphql/schema.generated'
import Col from './layout/Col'
import Container from './layout/Container'
import Row from './layout/Row'
import MemberUsps from './MemberUsps'
import ButtonVariant from './types/ButtonVariant'

type PrismicSliceMemberUsps = PageBodyMemberUspS

interface Props {
    slice: PrismicSliceMemberUsps
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const PrismicSliceMemberUsps = ({ slice: { primary, fields } }: Props) => {
    // When there is no relevant data, return null
    // Also, when the user is signed in, don't show this slice
    if (!primary || !fields || fields.length === 0 || isSignedIn()) {
        return null
    }

    const {
        heading,
        content,
        buttonUrl,
        buttonText,
        buttonStyle,
        button2Url,
        button2Text,
        button2Style,
    } = primary

    const iconFields = (fields as PageBodyMemberUspSFields[]) ?? []
    const icons = iconFields.reduce<ComponentProps<typeof MemberUsps>['usps']>(
        (acc, field) => {
            const icon = field?.icon
            const label = field?.label

            if (icon && label) {
                acc.push({
                    icon,
                    label,
                })
            }
            return acc
        },
        [],
    )

    // Icons and header are required
    if (icons.length === 0 || !heading) {
        return null
    }

    const componentProps: ComponentProps<typeof MemberUsps> = {
        heading,
        content: content ?? undefined,
        usps: icons,
        buttonUrl: buttonUrl ?? undefined,
        buttonText: buttonText ?? undefined,
        buttonStyle: (buttonStyle as ButtonVariant) ?? undefined,
        button2Url: button2Url ?? undefined,
        button2Text: button2Text ?? undefined,
        button2Style: (button2Style as ButtonVariant) ?? undefined,
    }

    return (
        <Container verticalPadding>
            <Row>
                <Col lg={{ order: 0, span: 10, offset: 1 }}>
                    <MemberUsps {...componentProps} />
                </Col>
            </Row>
        </Container>
    )
}

export default PrismicSliceMemberUsps
