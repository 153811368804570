import * as React from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { useStaleDataFallback } from '@emico/apollo'
import { useBreakpoints } from '@emico/ui'

import FilterBarDesktop from './FilterBar/FilterBarDesktop'
import FilterBarMobile from './FilterBar/FilterBarMobile'
import PageLoader from '../../../presentation/PageLoader'
import useTweakwiseNavigation, {
    CustomTweakwiseNavigationItem,
} from '../../../useTweakwiseNavigation'
import { useFilterAppliedEvent } from '../../../utils/ga4/useFilterAppliedEvent'
import { useFilterRemovedEvent } from '../../../utils/ga4/useFilterRemovedEvent'
import { useSortByEvent } from '../../../utils/ga4/useSortByEvent'
import filterInteraction from '../../../utils/googleTagManager/filterInteraction'
import push from '../../../utils/googleTagManager/push'

export type FilterValue = string[]

export const PAGE_SIZE = 48
const SORT_KEY = 'sort'

export interface FilterValues {
    [key: string]: FilterValue
}

interface Props {
    search?: string
    categoryId?: number
    pageName: string
    filters?: FilterValues

    children({
        items,
        numResults,
    }: {
        mobileFilters?: React.ReactNode
        desktopFilters?: React.ReactNode
        items: CustomTweakwiseNavigationItem[]
        numResults: number
        searchQuery: string
        loadNextPage?(): void
        loadingNextPage: boolean
        loading: boolean
    }): React.ReactNode
}

const getFilterUrl = (
    filterValues: FilterValues,
    sort?: string,
): string | undefined => {
    const params = new URLSearchParams()

    Object.entries(filterValues).forEach(([key, value]) => {
        if (value.length > 0) {
            params.append(key, value.join(','))
            return
        }
    })
    if (sort) {
        params.set(SORT_KEY, sort)
    } else {
        params.delete(SORT_KEY)
    }
    return `?${params.toString()}`
}

const useUrlFilterValues = (): FilterValues | undefined => {
    const [filterValues, setFilterValues] = React.useState<
        FilterValues | undefined
    >()
    const { search } = useLocation()

    React.useEffect(() => {
        const newValues: FilterValues = {}

        if (search) {
            const searchParams = new URLSearchParams(search)

            searchParams.forEach((value, key) => {
                if (value.includes(',')) {
                    newValues[key] = value.split(',')
                } else if (value !== '') {
                    newValues[key] = [value]
                }
            })
        }
        setFilterValues(newValues)
    }, [search])

    return filterValues
}

const ProductFilterPage = ({
    search = '',
    categoryId,
    pageName,
    filters,
    children,
}: Props) => {
    const urlFilterValues = useUrlFilterValues()
    const { isMobile } = useBreakpoints()
    const history = useHistory()
    const { search: searchParams } = useLocation()

    const [filterValues, setFilterValues] = React.useState<
        FilterValues | undefined
    >(urlFilterValues)

    const [sortKey, setSortKey] = React.useState<string | undefined>(
        urlFilterValues?.[SORT_KEY]?.[0],
    )
    const initialRender = React.useRef(true)
    const filterBarRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        setFilterValues(urlFilterValues)
        setSortKey(urlFilterValues?.[SORT_KEY]?.[0])
    }, [urlFilterValues])

    // Prevent filters from staying active when switching to another category
    // Don't do this on first render otherwise default filters get reset.
    // We don't want the defaults to be set again when entering a new category,
    // because they might not belong to that category.
    React.useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
        } else {
            setFilterValues({})
            setSortKey(undefined)
        }
    }, [categoryId])

    const activeFilters = Object.entries(
        filters && filterValues
            ? { ...filters, ...filterValues }
            : filterValues ?? {},
    ).map(([key, value]) => ({
        key,
        values: value,
    }))

    const params = new URLSearchParams(searchParams)
    const extraFilterTemplate = params.has('tn_ft')
        ? Number(params.get('tn_ft'))
        : undefined

    const extraSortingTemplate = params.has('tn_st')
        ? Number(params.get('tn_st'))
        : undefined

    const { data, loading, fetchMore } = useTweakwiseNavigation({
        page: 1,
        query: search,
        categoryId: categoryId,
        facets: activeFilters,
        sortKey: sortKey ?? 'desc',
        // Set initial page size to 48 so the grid gets filled out nicely on all viewports
        // The value has to be dividable by 2,3 and 4 in order for the grid
        // to fill out nicely
        pageSize: PAGE_SIZE,

        filterTemplateId: extraFilterTemplate,
        sortTemplateId: extraSortingTemplate,
    })

    const navigationResult = useStaleDataFallback(data)

    // It must store the last search query so that users of this component know
    // the search query that goes along with the data. This can be useful
    // because `data` will contain stale data while waiting for a new request.
    const [lastSearchQuery, setLastSearchQuery] = React.useState<string>(search)
    const [loadingNextPage, setLoadingNextPage] = React.useState<boolean>(false)

    const pushFilterApplied = useFilterAppliedEvent()
    const pushFilterRemoved = useFilterRemovedEvent()
    const pushSortBy = useSortByEvent()

    React.useEffect(() => {
        setLastSearchQuery(search)
    }, [search])

    React.useEffect(() => {
        if (sortKey) {
            pushSortBy(sortKey)
        }
    }, [pushSortBy, sortKey])

    if (!navigationResult) {
        return <PageLoader fullScreen reason="Loading product list..." />
    }

    const {
        facets: availableFilters,
        items,
        properties: {
            nrOfItems,
            currentPage: current,
            nrOfPages: total,
            sortFields,
        },
        redirects,
    } = navigationResult

    const currentPage = current ?? 0
    const totalPages = total ?? 0
    const hasMorePages = currentPage < totalPages
    const { origin } = window.location

    // Handle redirects
    if (redirects?.[0]?.url) {
        if (redirects[0].url.indexOf(origin) === 0) {
            // Current domain, use React Router
            return <Redirect to={redirects[0].url.substr(origin.length)} />
        }

        // External domain
        window.location.href = redirects[0].url
        return <PageLoader fullScreen reason="Redirecting..." />
    }

    const scrollToTop = () => {
        if (isMobile) {
            window.scrollTo(0, 0)
        } else if (filterBarRef.current) {
            filterBarRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }

    const filterBarProps = {
        ref: filterBarRef,
        sortFields,
        options: availableFilters,
        values: filterValues ?? {},
        setValue: (key: string, value: FilterValue) => {
            const newFilterValues = {
                ...filterValues,
            }

            if (!value || value.length === 0) {
                // Deleted values must be removed from the
                // state so only active filters are set.
                delete newFilterValues[key]
                pushFilterRemoved(key, String(newFilterValues[key] ?? ''))
            } else {
                newFilterValues[key] = value
                pushFilterApplied(key, String(value ?? ''))
            }

            history.push({
                search: getFilterUrl(newFilterValues, sortKey),
                state: { scrollRestoration: false },
            })

            scrollToTop()

            push(
                filterInteraction({
                    name: key,
                    category: pageName,
                    value: String(value || ''),
                }),
            )
        },
        sortKey,
        setSortKey: (value: string | undefined) => {
            history.push({
                search: getFilterUrl(filterValues || {}, value),
                state: { scrollRestoration: false },
            })

            scrollToTop()
        },
        clear: () => {
            setFilterValues({})
            setSortKey(undefined)
            // Clear filters in url
            history.push({
                search: undefined,
                state: { scrollRestoration: false },
            })
            scrollToTop()
        },
        numResults: nrOfItems,
        loading,
    }

    return (
        <>
            {children({
                mobileFilters: isMobile && (
                    <FilterBarMobile {...filterBarProps} />
                ),
                desktopFilters: !isMobile && (
                    <FilterBarDesktop {...filterBarProps} />
                ),
                items,
                numResults: nrOfItems,
                searchQuery: lastSearchQuery,
                loadNextPage: hasMorePages
                    ? async () => {
                          setLoadingNextPage(true)
                          try {
                              await fetchMore({
                                  variables: {
                                      page: currentPage + 1,
                                  },
                              })
                          } finally {
                              setLoadingNextPage(false)
                          }
                      }
                    : undefined,
                loadingNextPage,
                loading,
            })}
        </>
    )
}

export default ProductFilterPage
