import { Trans } from '@lingui/macro'
import * as React from 'react'
import { Control, useForm } from 'react-hook-form'

import {
    CartItemConfigurableConfiguration,
    CartItemConfigurableFormValue,
} from './CartItemConfigurableConfiguration'
import { updateItemInCart } from '../../actions/cart'
import { ConfigurableAttributesFieldValue } from '../../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../../catalog/ProductPage/GetProduct.query'
import { encodeAttributeValueObject } from '../../catalog/ProductPage/StickyCta/flattenCombinedAttributes'
import { giftModalEnabledVar } from '../../FreeGiftAvailableModal'
import { useDispatch, useSelector } from '../../types'
import { useAddToCartEvent } from '../../utils/ga4/useAddToCartEvent'
import {
    CartItemData,
    resolveConfigurableOptions,
    serializeConfigurableOptions,
} from '../CartItem/CartItem'
import styles from '../CartItem/CartItem.module.scss'
import { parseOptionLabels } from '../CartItem/parseOptionLabels'
import CartItemQuantityForm from '../CartItemQuantityForm'

interface Props {
    cartItem: CartItemData
    product: Product | ConfigurableProduct
    disabled?: boolean
}

const CartItemConfiguration = ({ cartItem, product, disabled }: Props) => {
    const pushAddToCart = useAddToCartEvent()
    const dispatch = useDispatch()
    const [updatingCart, setUpdatingCart] = React.useState<boolean>(false)
    const [rerenderKey, setRerenderKey] = React.useState(0)
    const error = useSelector((state) =>
        state.cart.details?.items?.find((i) => i.item_id === cartItem.item_id),
    )

    const optionsMap = new Map()
    const selectedAttributeObject: Record<string, number> = {}

    if ('variants' in product) {
        product.configurableOptions.forEach((item) =>
            optionsMap.set(item.attributeCode, item.attributeId),
        )
        const variant = product.variants.find(
            (item) => item.product?.sku === cartItem.sku,
        )

        for (const attribute of variant?.attributes ?? []) {
            selectedAttributeObject[String(optionsMap.get(attribute.code))] =
                attribute.valueIndex
        }
    }

    const attributeValue = encodeAttributeValueObject(selectedAttributeObject)

    const { handleSubmit, control } = useForm<CartItemConfigurableFormValue>({
        defaultValues: {
            value: attributeValue,
        },
    })

    React.useEffect(() => {
        // TODO: Might no longer be needed, we switched to react-hook-form
        // Rerender on error otherwise select value appears different but
        // it actually has not changed.
        setRerenderKey((key) => key + 1)
    }, [error])

    if (!cartItem.totals.options) {
        return null
    }

    const updateCartItem = async (
        quantity: number,
        options?: ConfigurableAttributesFieldValue,
    ) => {
        if (quantity < 1) {
            return
        }

        await dispatch(
            updateItemInCart(
                () => {
                    pushAddToCart(cartItem.sku, product, quantity, options)
                },
                cartItem.item_id ?? 0,
                product,
                quantity,
                options,
            ),
        )
        giftModalEnabledVar(true)
    }

    return (
        <React.Fragment key={rerenderKey}>
            <form
                onSubmit={handleSubmit(() => {
                    // no-op, updates are handled on-change
                })}
            >
                <CartItemConfigurableConfiguration
                    product={product}
                    control={
                        control as Control<
                            CartItemConfigurableFormValue,
                            'value'
                        >
                    }
                    onChange={async (value) => {
                        setUpdatingCart(true)
                        await updateCartItem(cartItem.qty, value)
                        setUpdatingCart(false)
                    }}
                />
            </form>

            <span className={styles.amount}>
                <label>
                    <Trans id="cart.cartItem.amountLabel">Amount</Trans>
                </label>
                <CartItemQuantityForm
                    quantity={cartItem.qty}
                    max={
                        cartItem.qty > cartItem.salable_qty
                            ? cartItem.qty
                            : cartItem.salable_qty
                    }
                    onSubmit={async (values) => {
                        if (
                            values.quantity === cartItem.qty ||
                            values.quantity < 1
                        ) {
                            return
                        }
                        setUpdatingCart(true)

                        const selectedOptions =
                            (product as ConfigurableProduct)
                                .configurableOptions &&
                            resolveConfigurableOptions(
                                parseOptionLabels(cartItem.totals.options),
                                product as ConfigurableProduct,
                            )

                        const options = selectedOptions
                            ? serializeConfigurableOptions(selectedOptions)
                            : undefined

                        await updateCartItem(values.quantity, options)
                        setUpdatingCart(false)
                    }}
                    disabled={updatingCart || disabled}
                />
            </span>
        </React.Fragment>
    )
}

export default CartItemConfiguration
