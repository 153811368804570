import { useSelector } from './types'

const useIsCheckoutAccessible = () =>
    useSelector((state) => {
        // Cart items might not be loaded into state yet
        if (!state.cart?.details?.items) {
            return true
        }

        return !(
            state.cart?.details?.items?.find(
                (item) => !item.is_salable || item.qty > item.salable_qty,
            ) ||
            (state.cart.updateItemErrors &&
                state.cart.updateItemErrors.length > 0) ||
            !state.cart.totals?.items_qty
        )
    })

export default useIsCheckoutAccessible
