import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import * as React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import styles from './CartItem.module.scss'
import {
    ConfigurableAttribute,
    ConfigurableAttributeValue,
} from '../../catalog/common/MinimalConfigurableProductInfo.fragment'
import { ConfigurableAttributesFieldValue } from '../../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { BasicConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import {
    BasicProduct,
    useGetBasicProduct,
} from '../../catalog/ProductPage/GetBasicProduct.query'
import { WISHLIST_DISABLED } from '../../constants'
import Responsive from '../../core/Responsive/Responsive'
import { CurrencyEnum } from '../../graphql/schema.generated'
import Button from '../../input/Button'
import FormError from '../../input/FormError'
import Icon from '../../media/Icon'
import Loader from '../../presentation/Loader'
import PriceBox from '../../presentation/PriceBox'
import { useSelector } from '../../types'
import {
    QuoteDataCartItemInterface,
    QuoteDataTotalsItemInterface,
} from '../../types/MagentoRestApi'
import WishlistIconButton from '../../wishlist/WishlistIconButton/WishlistIconButton'
import CartItemContainer from '../CartItemContainer'
import CartItemImage from '../CartItemImage'
import CartItemInfoContainer from '../CartItemInfoContainer'
import UndoIcon from '../UndoIcon'

export type CartItemData = QuoteDataCartItemInterface & {
    totals: QuoteDataTotalsItemInterface
}

interface Props {
    cartItem: CartItemData
    currency: CurrencyEnum
    remove(item: CartItemData, product: BasicProduct): Promise<void>
    restore(item: CartItemData, product: BasicProduct): Promise<void>
    onClose?(item: CartItemData): void
    disabled?: boolean
}

export interface CartItemOptions {
    value: string
    label: string
}

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    position: relative;

    @media screen and (min-width: 768px) {
        flex-flow: row nowrap;
    }
`

// The cart options are formatted as { [optionLabel]: valueLabel }. This
// function resolves those labels to ids so we get { [optionId]: valueId }.
export const resolveConfigurableOptions = (
    options: CartItemOptions[],
    product: BasicConfigurableProduct,
) =>
    options.map<[ConfigurableAttribute, ConfigurableAttributeValue]>(
        ({ label: optionLabel, value: valueLabel }) => {
            // Find the configurable option with the provided label
            const configurableOption = product.configurableOptions.find(
                (item) => item.label === optionLabel,
            )

            if (!configurableOption) {
                // The only instances where I can imagine this occurs is when the
                // user has an old, removed item in their cart. This seems very
                // unlikely to happen but it would mean we can't restore this item
                // properly.
                throw new Error(
                    `Unable to find configurable option with label "${optionLabel}" for product "${product.sku}"`,
                )
            }
            const configurableOptionValue = configurableOption.values.find(
                (item) => item.label === valueLabel,
            )

            if (!configurableOptionValue) {
                // The only instances where I can imagine this occurs is when the
                // user has an old, removed item in their cart. This seems very
                // unlikely to happen but it would mean we can't restore this item
                // properly.
                throw new Error(
                    `Unable to find configurable option value with value label "${valueLabel}" for configurable option "${optionLabel}" for product "${product.sku}"`,
                )
            }

            return [configurableOption, configurableOptionValue]
        },
    )
export const serializeConfigurableOptions = (
    configurableOptions: Array<
        [ConfigurableAttribute, ConfigurableAttributeValue]
    >,
): ConfigurableAttributesFieldValue =>
    configurableOptions.reduce<ConfigurableAttributesFieldValue>(
        (obj, [option, value]) => {
            obj[Number(option.attributeId)] = value.valueIndex
            return obj
        },
        {},
    )

const renderTotals = (
    cartItem: CartItemData,
    product: BasicProduct,
    currency: CurrencyEnum,
) => {
    const { regularPrice, discount } = product.priceRange.maximumPrice
    const { totals } = cartItem
    const actualPrice = totals.row_total_incl_tax || 0
    const originalPrice = regularPrice.value * totals.qty
    const hasDiscount = discount?.percentOff ? discount.percentOff > 0 : false
    const discountPercent = Math.round(discount?.percentOff || 0)

    return (
        <>
            <PriceBox
                oldPrice={
                    hasDiscount
                        ? {
                              value: originalPrice,
                              currency,
                          }
                        : undefined
                }
                regularPrice={
                    !hasDiscount
                        ? {
                              value: actualPrice,
                              currency,
                          }
                        : undefined
                }
                specialPrice={
                    hasDiscount
                        ? {
                              value: actualPrice,
                              currency,
                          }
                        : undefined
                }
                priceClassName={styles.price}
                outletAdviceprice={product.outletAdviesprijs ?? null}
            />
            {hasDiscount && (
                <div className={styles.discount}>
                    <Trans id="cart.cartItem.discountLabel">
                        You save {discountPercent}%
                    </Trans>
                </div>
            )}
        </>
    )
}

// The time after which item restoration disappears and the item is permanently hidden
const RESTORATION_TIMEOUT = 2500

const CartItem = ({
    cartItem,
    currency,
    remove,
    restore,
    onClose,
    disabled,
}: Props) => {
    const { data: product } = useGetBasicProduct(cartItem.product_id)
    const [isDeleting, setDeleting] = React.useState<boolean>(false)
    const [isMoving, setMoving] = React.useState<boolean>(false)
    const [isWorking, setWorking] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<React.ReactNode>()
    const error = useSelector(
        (state) =>
            state.cart.updateItemErrors &&
            state.cart.updateItemErrors.find(
                (item) => item.cartItemId === cartItem.item_id,
            ),
    )

    let timer: ReturnType<typeof setTimeout> | undefined
    const cancelTimer = () => {
        if (timer) {
            clearTimeout(timer)
            timer = undefined
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => cancelTimer, [])

    React.useEffect(() => {
        if (error || (cartItem && cartItem.salable_qty < cartItem.qty)) {
            const salableQty = error?.salableQuantity || cartItem.salable_qty
            const selectedQuantity = error?.quantity || cartItem.qty

            setErrorMessage(
                <Trans id="cart.cartItem.error">
                    Requested quantity of {selectedQuantity} not available. Only{' '}
                    {salableQty} left.
                </Trans>,
            )
        } else {
            setErrorMessage(undefined)
        }
    }, [cartItem, error])

    if (!product) {
        return <Loader className={styles.loader} />
    }

    const handleRemoveItem = async () => {
        setDeleting(true)
        setWorking(true)
        const workingStart = Number(new Date())

        await remove(cartItem, product)
        const workingDuration = Number(new Date()) - workingStart

        setWorking(false)
        if (onClose) {
            // Adjust the waiting time for the time we've been waiting on the API
            // calls. This way if deleting takes long, the restore button stays
            // visible until it finishes.
            const waitTime = Math.max(0, RESTORATION_TIMEOUT - workingDuration)

            timer = setTimeout(() => {
                onClose(cartItem)
            }, waitTime)
        }
    }

    const handleMoveToWishlist = async () => {
        setDeleting(true)
        setWorking(true)
        setMoving(true)
        const workingStart = Number(new Date())

        await remove(cartItem, product)
        const workingDuration = Number(new Date()) - workingStart

        setWorking(false)
        if (onClose) {
            // Adjust the waiting time for the time we've been waiting on the API
            // calls. This way if deleting takes long, the restore button stays
            // visible until it finishes.
            const waitTime = Math.max(0, RESTORATION_TIMEOUT - workingDuration)

            timer = setTimeout(() => {
                onClose(cartItem)
            }, waitTime)
        }
    }

    const handleRestoreItem = async () => {
        cancelTimer()
        // TODO: Enable the below when we can manage to keep items in the
        //  same spot after restoration
        // setDeleting(false)
        setWorking(true)
        await restore(cartItem, product)
        setWorking(false)
    }

    return (
        <CartItemContainer>
            {!disabled && errorMessage && (
                <FormError autoHide={false} variant="noBackground">
                    {errorMessage}
                </FormError>
            )}
            {disabled && (
                <FormError autoHide={false} variant="noBackground">
                    <Trans id="cart.cartItemListing.notAvailableItemError">
                        Item unavailable. Remove the item from your cart.
                    </Trans>
                </FormError>
            )}
            <Wrapper>
                <TransitionGroup>
                    {isDeleting && (
                        <CSSTransition
                            timeout={1100}
                            classNames={{
                                enter: styles.enter,
                                enterActive: styles.enterActive,
                                exit: styles.exit,
                                exitActive: styles.exitActive,
                            }}
                        >
                            <div className={styles.deleteOverlay}>
                                <div className={styles.container}>
                                    <div className={styles.shade}>
                                        {isWorking && <Loader />}
                                    </div>

                                    {isMoving ? (
                                        <Trans id="cart.cartItem.moved">
                                            The item has been moved to your
                                            wishlist
                                        </Trans>
                                    ) : (
                                        <Trans id="cart.cartItem.removed">
                                            The item has been removed from your
                                            cart
                                        </Trans>
                                    )}
                                    <br />
                                    {!isMoving && (
                                        <Button
                                            variant="linkInverted"
                                            name="Put back in cart"
                                            category="cart.cartItem.removed.putBackInCart"
                                            onClick={handleRestoreItem}
                                            loading={isWorking}
                                            disabled={isWorking}
                                        >
                                            <Trans id="cart.cartItem.removed.putBackInCart">
                                                <Icon
                                                    component={UndoIcon}
                                                    title=""
                                                    className={
                                                        styles.restoreIcon
                                                    }
                                                />
                                                put back in cart
                                            </Trans>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>

                <Responsive sm down>
                    <div className={styles.imageWrapper}>
                        {!disabled && !WISHLIST_DISABLED && (
                            <WishlistIconButton
                                className={styles.wishlistIconButton}
                                product={product}
                                onAddToWishlist={handleMoveToWishlist}
                                optimistic={false}
                            />
                        )}
                        <CartItemImage product={product} disabled={disabled} />
                    </div>
                </Responsive>

                <Responsive md up>
                    <CartItemImage product={product} disabled={disabled} />
                </Responsive>

                <CartItemInfoContainer
                    disabled={disabled}
                    cartItem={cartItem}
                    product={product}
                    totals={renderTotals(cartItem, product, currency)}
                    handleRemoveItem={handleRemoveItem}
                    handleMoveToWishlist={handleMoveToWishlist}
                />
            </Wrapper>
        </CartItemContainer>
    )
}

export default CartItem
