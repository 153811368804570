import {
    CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
    CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
} from '../../catalog/ProductPage/ConfigurableAttributesField/helpers'

/**
 * Formats variant value so it's more consistent.
 * Format should be CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE:<value>,CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE:<value>
 * with values being 0 if not present.
 */
export default (value: string = '') => {
    const variants = value.split(',')

    return [
        CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
        CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
    ]
        .map((attr) => {
            const variant = variants.find((variant) =>
                variant.toLowerCase().startsWith(`${attr}:`),
            )
            return variant?.toLowerCase() || `${attr}:0`
        })
        .join(',')
}
