import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import cx from 'classnames'
import * as React from 'react'
import Cookies from 'universal-cookie'

import shopIdStyle from './ShopIdTopBarBanner.module.scss'
import styles from './TopBarBanner.module.scss'
import { useCoreConfigValue } from '../../../coreConfig.query'
import Col from '../../Col'
import Container from '../../Container'
import Row from '../../Row'

const SHOP_ID = 'shop_id'
const CUSTOMER_SHOP_ID = 'customer_shop_id'

const isShopIdCookiePresent = (cookieName: string) =>
    document.cookie.match(RegExp(`(?:^|;\\s*)${cookieName}=([^;]*)`))

const getIdFromCookie = (cookieName: string): number | undefined => {
    if (!isShopIdCookiePresent(cookieName)) {
        return undefined
    }
    try {
        const cookies = new Cookies()
        const shopId = parseInt(cookies.get(cookieName), 10)

        if (isNaN(shopId)) {
            return undefined
        }
        return shopId
    } catch (e) {
        return undefined
    }
}

export const getShopIdFromCookie = (): number | undefined =>
    getIdFromCookie(SHOP_ID)

export const getShopIdCookies = () => ({
    shopId: getIdFromCookie(SHOP_ID),
    customerShopId: getIdFromCookie(CUSTOMER_SHOP_ID),
})

const ShopIdTopBarBanner = () => {
    const { value: bannerTextColor } = useCoreConfigValue(
        'justbrands_banner/banner/textcolor',
    )
    const shopId = getShopIdFromCookie()

    if (!shopId) {
        return null
    }

    return (
        <div
            className={cx(styles.base, shopIdStyle.base)}
            data-id="shopIdTopBarBanner"
        >
            <Container>
                <Row>
                    <Col>
                        <div className={styles.banner}>
                            <div
                                style={{ color: bannerTextColor }}
                                dangerouslySetInnerHTML={{
                                    __html: t({
                                        id: 'core.topBar.shopIdMessage',
                                        message: `Note: This iPad is connected with Shop Id ${shopId}`,
                                    }),
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ShopIdTopBarBanner
