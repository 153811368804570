import { useApolloClient, gql, TypedDocumentNode } from '@apollo/client'
import { customerFragment } from '@emico-hooks/customer-fragment'
import { useAuthorizationContext } from '@emico-hooks/login-token'
import { useCallback } from 'react'

import {
    CreateCustomerMutation,
    CreateCustomerMutationVariables,
} from './useCreateCustomer.generated'
import { CustomerCreateInput } from '../../components/src/graphql/schema.generated'

const createCustomerMutation = gql`
    mutation CreateCustomer($input: CustomerInput!) {
        createCustomer(input: $input) {
            customer {
                ...CustomerFragment
            }
        }
    }

    ${customerFragment}
` as TypedDocumentNode<CreateCustomerMutation, CreateCustomerMutationVariables>

/**
 * Mutation to create a customer
 *
 * Usage example:
 *
 * ```ts
 * export function exampleFunction() {
 *  const createCustomer = useCreateCustomer()
 *
 *  return useCallback(
 *    async () => {
 *      try {
 *        await createCustomer(customer)
 *      } catch(error) {
 *        // error
 *      }
 *    },
 *    [createCustomer],
 *  )
 * }
 * ```
 */

export function useCreateCustomer() {
    const client = useApolloClient()
    const authorizationContext = useAuthorizationContext()

    return useCallback(
        /**
         *
         * @param customer Customer data object
         */
        (customer: CustomerCreateInput) =>
            client.mutate({
                mutation: createCustomerMutation,
                variables: {
                    input: customer,
                },
                context: authorizationContext,
            }),
        [authorizationContext, client],
    )
}
