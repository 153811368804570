import { useIsLoggedIn } from '@emico-hooks/login-token'

import { CartItemData } from './CartItem'
import { GiftRule } from '../reducers/cart'
import useFreeGiftRules from '../useFreeGiftRules'

const noop = () => true

export const useCartGifts = (
    filter: (rule: GiftRule) => boolean = noop,
): Array<
    GiftRule & {
        items: Array<{
            productId: number
            item: CartItemData | undefined
        }>
    }
> => {
    const { rules } = useFreeGiftRules()
    const isLoggedIn = useIsLoggedIn()

    if (rules.length === 0) {
        return []
    }

    return (
        isLoggedIn
            ? rules.filter((rule) => rule.is_valid_customer_group)
            : rules
    )
        .map((rule) => {
            // The is_available flag is true when the Free Gift has not been applied yet.
            // The is_valid flag is true when the conditions of the Rule are met.
            const { products, item_ids } = rule

            // For each rule, show the products that are selected
            // If no products are selected, show the first product
            let items: Array<{
                productId: number
                item: CartItemData | undefined
            }> = [
                {
                    productId: products[0].id,
                    item: undefined,
                },
            ]

            if (item_ids) {
                items = item_ids.reduce<typeof items>((acc, itemId) => {
                    const product = products.find(
                        (p) => p.item?.item_id === itemId,
                    )
                    if (!product?.item) {
                        return acc
                    }

                    acc.push({
                        productId: product.id,
                        item: product.item,
                    })
                    return acc
                }, [])
            }
            return { ...rule, items }
        })
        .sort((a, b) => {
            if (a.items?.[0].item && !b.items?.[0].item) {
                return -1
            } else if (!a.items?.[0].item && b.items?.[0].item) {
                return 1
            } else if (a.items?.[0].item && b.items?.[0].item) {
                return a.rule_id - b.rule_id
            } else {
                return (
                    (a.insufficient_amount || 0) - (b.insufficient_amount || 0)
                )
            }
        })
        .filter(filter)
}
