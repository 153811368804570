import { FieldProps, useField } from 'informed'
import * as React from 'react'

export default function useInformedInput<
    FormValue = unknown,
    FormValues = unknown,
>(props: FieldProps<FormValue, FormValues>) {
    const {
        fieldState: { value: informedValue, error },
        fieldApi,
        render,
        ref,
        userProps: {
            onChange,
            onBlur,
            onFocus,
            onInitialize,
            serializeValue = (value: FormValue): unknown => value,
            deserializeValue = (
                value: FormValue | undefined,
            ): FormValue | undefined => value,
            // TODO: Fix issue
            // When autoFocus is set, it triggers an error within the onInformedFocus handler
            // So we disable it. Use a ref wich triggers the focus event.
            autoFocus,
            ...inputProps
        },
    } = useField<FormValue, FormValues>(props)

    const { setValue, setTouched, getTouched, validate } = fieldApi
    const useChecked = Boolean(
        inputProps.type && ['checkbox', 'radio'].includes(inputProps.type),
    )

    /**
     * We use the setTouched and getTouched to force rerender of the input on focus and blur
     * The hasFieldBeenTouched monitors if the field has been "touched", either programmatically or by hand.
     */
    const isTouched = getTouched()
    const [hasFieldBeenTouched, setFieldTouched] = React.useState(false)

    const hasError = Boolean(error)
    const isValid = !hasError && hasFieldBeenTouched
    const errorMessage = hasError && error

    const onInformedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldTouched(true)
        setValue(
            useChecked ? e.target.checked : deserializeValue(e.target.value),
        )

        if (onChange) {
            onChange(e)
        }
    }

    const onInformedBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setTouched(false)
        setFieldTouched(true)

        if (onBlur) {
            onBlur(e)
        }
    }

    const onInformedFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setTouched(true)
        validate()

        if (onFocus) {
            onFocus(e)
        }
    }

    const value =
        informedValue === undefined || informedValue === null
            ? useChecked
                ? false
                : ''
            : useChecked
              ? informedValue
              : serializeValue(informedValue)

    if (onInitialize) {
        onInitialize(value)
    }

    return {
        render,
        hasError,
        isValid,
        inputProps: {
            ...inputProps,
            ref,
            // Set name to trigger browser's autocomplete
            name: props.field,
            // Set id to allow for non-encapsulating labels
            id: props.field,
            type: props.type, // these don't get passed into inputProps so we do it manually
            value,
            onBlur: onInformedBlur,
            onFocus: onInformedFocus,
            onChange: onInformedChange,
            checked: useChecked && Boolean(value),
        },
        isTouched,
        errorMessage,
        useChecked,
    }
}
