import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import * as React from 'react'

import ci_icon from './markerIcons/ci_icon.png'
import jb_icon from './markerIcons/jb_icon.png'
import pme_icon from './markerIcons/pme_icon.png'
import OpenOrClosed from './OpenOrClosed'
import { SrsStore } from './useSrsStore'
import PageLoader from '../presentation/PageLoader'
import theme from '../theme'
import Heading from '../typography/Heading'
import Text from '../typography/Text'

type StockType = 'in-stock' | 'out-of-stock' | 'limited'

interface Location {
    lat: number
    lng: number
}
interface Props {
    searchResults: SrsStore[] | undefined
    searchResultsLoading: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelectStoreDetail(value: SrsStore): any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelectStoreLocation(value: Location): any
    storeLocator: boolean
}

const ItemHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
`

const ScrollContainer = styled.div`
    background-color: #fff;
    margin: 0;
`

const Results = styled.ul<{ addScroll: boolean }>`
    ${({ addScroll }) =>
        addScroll
            ? css`
                  max-height: 500px;
                  overflow-y: auto;
              `
            : ''}
    padding: 0;
    margin: 0;
    border-top: 1px solid #f2f2f2;
    @media (max-width: 767px) {
        max-height: unset;
        overflow-y: unset;
    }
`

const ResultItem = styled.li`
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;

    &:hover {
        background-color: #f2f2f2;
        svg {
            fill: ${process.env.REACT_APP_PRIMARY_COLOR};
        }
    }
`

const StyledOpenClose = styled(OpenOrClosed)`
    margin: 10px 0;
`

const ItemInfo = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: row;
`

const Marker = styled.img`
    position: relative;
    top: 0;
    left: 0;
`

const ContentWrapper = styled.div`
    width: 90%;
    align-items: center;
`

const UnderlineText = styled(Text)`
    text-decoration: underline;
`

const StockWrapper = styled.div<{ stockType: string }>`
    margin-top: 10px;
    text-align: right;
    font-weight: 700;
    color: ${({ stockType }) =>
        stockType === 'in-stock'
            ? theme.colors.green
            : stockType === 'limited'
              ? theme.colors.orange
              : theme.colors.red};
`

const Block = styled.div<{ stockType: StockType }>`
    width: 10px;
    height: 10px;
    background-color: ${({ stockType }) =>
        stockType === 'in-stock'
            ? theme.colors.green
            : stockType === 'limited'
              ? theme.colors.orange
              : theme.colors.red};
`

const BlockWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 5px;
    margin-bottom: 5px;
`

const generateMarker = ({ pmeStore, ciStore, jbStore, name }: SrsStore) => {
    let icon: string | undefined

    if (pmeStore) {
        icon = pme_icon
    } else if (ciStore) {
        icon = ci_icon
    } else if (jbStore) {
        icon = jb_icon
    }
    return icon ? (
        <Marker src={icon} width={30} height={44} alt={name} />
    ) : undefined
}

export const SupplyStatus = ({ stock }: { stock: number }) => {
    let stockType: StockType = 'in-stock'
    let stockLabel = t({
        id: 'storelocator.searchResults.stockType.inStock',
        message: `In stock`,
    })

    if (stock <= 2) {
        stockType = 'limited'
        stockLabel = t({
            id: 'storelocator.searchResults.stockType.limitedStock',
            message: `Limited stock`,
        })
    }
    if (stock <= 0) {
        stockType = 'out-of-stock'
        stockLabel = t({
            id: 'storelocator.searchResults.stockType.outOfStock',
            message: `Out of stock`,
        })
    }

    return (
        <StockWrapper stockType={stockType}>
            <BlockWrapper>
                <Block stockType={stockType} />
                {stockType !== 'out-of-stock' && (
                    <Block stockType={stockType} />
                )}
                {stockType === 'in-stock' && <Block stockType={stockType} />}
            </BlockWrapper>
            {stockLabel}
        </StockWrapper>
    )
}

const SearchResults = ({
    searchResults,
    onSelectStoreDetail,
    storeLocator,
    searchResultsLoading,
}: Props) => (
    <ScrollContainer>
        {searchResultsLoading ? (
            <PageLoader
                reason={t({
                    id: 'storelocator.searchResults.loading',
                    message: `Loading stock status`,
                })}
                css={css`
                    padding-bottom: 20px;
                    padding-top: 20px;
                `}
            />
        ) : (
            <Results addScroll={storeLocator}>
                {searchResults?.map((result, i) => (
                    <ResultItem
                        key={i}
                        onClick={() =>
                            result.gLat &&
                            result.gLong &&
                            onSelectStoreDetail(result)
                        }
                    >
                        {generateMarker(result)}
                        <ContentWrapper>
                            <ItemHeading>
                                <Heading variant="h4" element="h2">
                                    {result.name}
                                </Heading>
                                {result?.distance && (
                                    <Text as="span">
                                        {result?.distance.toFixed(1)} km
                                    </Text>
                                )}
                            </ItemHeading>
                            <ItemInfo>
                                <div>
                                    <Text as="p">
                                        {result.street} {result.number}
                                    </Text>
                                    <Text as="p">
                                        {result.postal}, {result.city}
                                    </Text>
                                    {result &&
                                        result.gTimes &&
                                        (result.jbStore ||
                                            result.pmeStore ||
                                            result.ciStore) && (
                                            <StyledOpenClose
                                                times={result.gTimes}
                                            />
                                        )}
                                    <UnderlineText>
                                        <Trans id="storelocator.searchResults.storeInfoLabel">
                                            Store information
                                        </Trans>
                                    </UnderlineText>
                                </div>
                                {result.skuStock !== undefined &&
                                    !searchResultsLoading && (
                                        <SupplyStatus
                                            stock={result.skuStock || 0}
                                        />
                                    )}
                            </ItemInfo>
                        </ContentWrapper>
                    </ResultItem>
                ))}
            </Results>
        )}
    </ScrollContainer>
)

export default SearchResults
