import { QueryHookOptions, gql, useQuery } from '@apollo/client'
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { TweakwiseFunnel } from '@emico-hooks/graphql-schema-types'

import { Answer, TweakwiseFunnelItemFragment } from './useTweakwiseFunnel'
import productCardFragment from '../ProductCardFragment'

const query = gql`
    query TweakwiseFunnel(
        $code: String!
        $answers: [TweakwiseFunnelAnswerInput]
        $skippedQuestions: [Int]
    ) {
        tweakwiseFunnel(
            params: {
                code: $code
                answers: $answers
                skippedQuestions: $skippedQuestions
            }
        ) {
            code
            navigationUrl
            items {
                ...TweakwiseFunnelItemFragment
                product {
                    ...ProductCardFragmentJB
                }
            }
        }
    }

    ${TweakwiseFunnelItemFragment}
    ${productCardFragment}
`

function normalizeNavigationUrl(url: string) {
    if (!url) {
        return ''
    }

    const params = new URLSearchParams(url)
    const cid = params.get('tn_cid')
    if (cid) {
        params.delete('tn_cid')
        params.set('cid', cid.slice(5))
    }

    params.forEach((value, key) => {
        params.delete(key)
        params.set(key.replace('tn_', '').replace('fk_', ''), value)
    })

    return [cid, params.toString()]
}

export function useTweakwiseFunnelProducts(
    code: string,
    answers: Answer[],
    options?: QueryHookOptions,
) {
    const { data, networkStatus } = useQuery<{
        tweakwiseFunnel: TweakwiseFunnel
    }>(query, {
        fetchPolicy: 'network-only',
        variables: {
            code,
            answers: answers.map((a) => ({
                questionId: a.questionId,
                answerIds: a.answerIds,
            })),
            skippedQuestions: answers
                .filter((a) => a.skipQuestion)
                .map((a) => parseInt(a?.questionId ?? ''))
                .pop(),
        },
        ...options,
    })
    const [cid, navigationUrl] = normalizeNavigationUrl(
        data?.tweakwiseFunnel?.navigationUrl ?? '',
    )

    return {
        data: data?.tweakwiseFunnel?.items,
        cid: cid?.slice(5),
        navigationUrl,
        loading: isNetworkRequestInFlight(networkStatus),
    }
}
