import { Children, isValidElement, Fragment, cloneElement } from 'react'

import BlueConicComponent, {
    Props as BlueConicComponentProps,
} from './BlueConicComponent'
import ErrorBoundary from './ErrorBoundary'

type Props = {
    children?: React.ReactElement
} & Partial<Pick<BlueConicComponentProps, 'blueConicPosition'>>

const BlueConicContent = ({ blueConicPosition, children }: Props) => {
    const childrenWithProps = Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a
        // typescript error too.
        // Check for a blueconic position. If it is set enable blueconic takeover.
        if (isValidElement(child) && blueConicPosition) {
            const blueConicDataAttribute = {
                'data-bc-position': blueConicPosition,
            }

            return (
                <ErrorBoundary fallback={<Fragment />}>
                    <BlueConicComponent
                        blueConicPosition={blueConicPosition}
                        fallBack={cloneElement(child, blueConicDataAttribute)}
                        // Pass down all props of the child to the blueconic component
                        {...(child.props ?? {})}
                        // including data attribute
                        {...blueConicDataAttribute}
                    />
                </ErrorBoundary>
            )
        }
        return child
    })

    return childrenWithProps ? <>{childrenWithProps}</> : null
}

export default BlueConicContent
