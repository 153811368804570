import { makeVar, useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import actions from './actions/cart'
import EditGiftsModal from './gifts/EditGiftsModal'
import paths from './paths'
import { useDispatch, useSelector } from './types/Redux'

export const giftModalEnabledVar = makeVar<boolean>(false)

const FreeGiftAvailableModal = () => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const [hasChanged, setChanged] = useState<boolean>(false)
    const rules = useSelector((state) => state.cart.newGiftsAvailable)
    const isModalEnabled = useReactiveVar(giftModalEnabledVar)

    // Do not show minicart on cart page
    const disableMiniCart = pathname === paths.cart

    // Detect if there is a change in available rules.
    useEffect(() => {
        // If the only rules shown are rules that are selected already, dont show the modal.
        if (rules?.find((r) => r.is_available)) {
            setChanged(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rules?.map((r) => r.rule_id).join()])

    if (!rules?.find((r) => r.is_available)) {
        return null
    }

    if (!isModalEnabled || !hasChanged || !rules) {
        return null
    }

    const freeGiftRuleIds = rules.map((r) => r.rule_id)

    return (
        <EditGiftsModal
            rules={rules}
            onBack={() => {
                setChanged(false)
                giftModalEnabledVar(false)

                if (!disableMiniCart) {
                    dispatch(
                        actions.miniCart.open({
                            freeGiftRuleIds,
                        }),
                    )
                }
                dispatch(actions.updateGift.receive())
            }}
        />
    )
}

export default FreeGiftAvailableModal
