import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useActiveStoreView } from '@emico/storeviews'

import { removeCart, signOut } from '../actions'
import Button from '../input/Button'
import { getShopIdFromCookie } from '../layout/Header/TopBar/ShopIdTopBarBanner'
import theme from '../theme'
import Heading from '../typography/Heading'
import { wishlistIdVar } from '../wishlist/useWishlistId'

export const Warning = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: ${theme.colors.grayDark};
    color: ${theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
`

export const Spacer = styled.div`
    display: inline-block;
    width: 20px;
    height: 20px;
`

const SRSCartCleanup = () => {
    const shopId = getShopIdFromCookie()
    const history = useHistory()
    const { makeUrl } = useActiveStoreView()
    const [showWarning, setShowWarning] = useState<boolean>(false)
    const [navigateTo, setNavigateTo] = useState<string>('/')
    const dispatch = useDispatch()

    useEffect(() => {
        if (shopId === undefined) {
            return
        }

        const unblock = history.block((location, action) => {
            if (location.pathname.startsWith('/checkout') || showWarning) {
                return
            }
            setNavigateTo(location.pathname)
            setShowWarning(true)
            return false
        })

        return () => unblock()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLeave = useCallback(async () => {
        dispatch(removeCart())
        wishlistIdVar(null)
        await dispatch(signOut())

        // Use window.location.href instead of history.push to limit the chance of cached data being present
        window.location.href = makeUrl(navigateTo)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateTo])

    const handleCancel = useCallback(() => {
        setShowWarning(false)
        setNavigateTo('/')
    }, [])

    if (shopId === undefined || !showWarning) {
        return null
    }

    return (
        <Warning>
            <Heading variant="h1" element="h2" color="light">
                <Trans id="srs.cartCleanup.title">Warning</Trans>
            </Heading>
            <p>
                <Trans id="srs.cartCleanup.explanation">
                    Are you sure you want to exit the checkout? Your shopping
                    cart will be emptied to protect your privacy.
                </Trans>
            </p>
            <div>
                <Button
                    minWidth
                    category="srs.cart-cleanup"
                    name="leave"
                    size="large"
                    onClick={handleLeave}
                >
                    <Trans id="srs.cartCleanup.leave">Yes, leave</Trans>
                </Button>
                <Spacer />
                <Button
                    minWidth
                    category="srs.cart-cleanup"
                    name="cancel"
                    variant="white"
                    size="large"
                    onClick={handleCancel}
                >
                    <Trans id="srs.cartCleanup.cancel">Cancel</Trans>
                </Button>
            </div>
        </Warning>
    )
}

export default SRSCartCleanup
