import { useMemo } from 'react'

import { parseOptionLabels } from '../../cart/CartItem/parseOptionLabels'
import {
    QuoteDataCartItemInterface,
    QuoteDataTotalsItemInterface,
    useSelector,
} from '../../types'
import { ConfigurableProduct } from '../ProductPage/ConfigurableProduct'
import { Product } from '../ProductPage/GetProduct.query'

export function isProductInCart(
    cartItems: QuoteDataCartItemInterface[] | undefined,
    cartTotalItems: QuoteDataTotalsItemInterface[] | undefined,
    product: Product | ConfigurableProduct,
    selectedOptions: Record<number, number> | false,
) {
    if (!cartItems || !cartTotalItems) {
        return false
    }

    const productInCart = cartItems
        .filter((item) => item.product_id === product.id)
        .map((item) => item.item_id)

    if (!productInCart.length) {
        return false
    }

    // We are done if product options should not be checked
    if (selectedOptions === false || !('configurableOptions' in product)) {
        return true
    }

    // For each item in the cart totals
    for (const totalsProduct of cartTotalItems) {
        // Check if the totals item is for this product. A totals entry does not have a product id.
        if (!productInCart.includes(totalsProduct.item_id)) {
            continue
        }

        const options = parseOptionLabels(totalsProduct?.options ?? '[]')

        const flatSelectedOptions: Record<string, string> = {}

        for (const key of Object.keys(selectedOptions)) {
            const labelOption = product.configurableOptions.find(
                (item) => item.attributeId === key,
            )

            const value = labelOption?.values.find(
                (item) =>
                    item.valueIndex === Number(selectedOptions[Number(key)]),
            )?.storeLabel

            flatSelectedOptions[labelOption?.attributeCode ?? key] =
                value ?? '__N/A__'
        }

        // Check if all (configurable) options match.
        let allOptionsMatch = true

        for (const option of options) {
            if (String(flatSelectedOptions[option.label]) !== option.value) {
                allOptionsMatch = false
            }
        }

        // If they all match, we have a matching product. If not, there might be another entry of
        // the same product that does have a match.
        if (allOptionsMatch) {
            return true
        }
    }

    // When this line is reached, we found no match
    return false
}

/**
 * Check if a product is in cart
 * @param product A Product (configurable or not)
 * @param selectedOptions Selected product options, set to `false` if configurable product
 * options should not be checked, or product is not a configurable
 */
export default function useIsProductInCart(
    product: Product | ConfigurableProduct,
    selectedOptions: Record<number, number> | false,
) {
    const cartItems = useSelector((state) => state.cart.details?.items)
    const cartTotalItems = useSelector((state) => state.cart.totals?.items)

    return useMemo(
        () =>
            isProductInCart(
                cartItems,
                cartTotalItems,
                product,
                selectedOptions,
            ),
        [product, selectedOptions, cartItems, cartTotalItems],
    )
}
