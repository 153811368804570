import cx from 'classnames'
import * as React from 'react'

import styles from './PriceBox.module.scss'
import { ConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { Money } from '../../graphql/schema.generated'
import { ProductCardFragment } from '../../ProductCardFragment'
import overridable from '../../utils/overridable'
import Price from '../Price'

export interface Props {
    className?: string
    priceClassName?: string
    variantBig?: boolean
    oldPrice?: Money
    specialPrice?: Money
    regularPrice?: Money
    outletAdviceprice?: number | null
}

export class Prices {
    oldPrice?: Money
    specialPrice?: Money
    regularPrice?: Money

    get actualPrice(): Money | undefined {
        if (this.specialPrice !== undefined) {
            return this.specialPrice
        }
        return this.regularPrice
    }
}

export const getConfigurableProductPrices = (
    product: ConfigurableProduct,
): Prices | undefined => {
    let price = product.priceRange.maximumPrice

    // Fixes https://support.emico.nl/issues/124503
    // Sometimes it occurs that a product has a price of 0 but is in stock.
    // In this case, we check the variations for the lowest possible price.
    if (price?.regularPrice?.value === 0 && product.variants.length > 0) {
        price = product.variants.reduce((prev, curr) => {
            if (curr.product?.priceRange.maximumPrice) {
                const currPrice =
                    curr.product.priceRange.maximumPrice?.regularPrice?.value
                const prevPrice = prev?.regularPrice?.value

                if (
                    typeof currPrice === 'number' &&
                    typeof prevPrice === 'number' &&
                    (prevPrice === 0 || currPrice < prevPrice)
                ) {
                    prev = curr.product.priceRange.maximumPrice
                }
            }

            return prev
        }, product.priceRange.maximumPrice)
    }

    if (price?.regularPrice?.value === 0) {
        price = {
            ...product.priceRange.maximumPrice,
            regularPrice: product.priceRange.maximumPrice.finalPrice,
        }
    }
    return getPrices({ priceRange: { maximumPrice: price } })
}

export const getPrices = ({
    priceRange,
}: Pick<ProductCardFragment, 'priceRange'>): Prices | undefined => {
    if (!priceRange) {
        return undefined
    }
    const price = priceRange.maximumPrice

    if (price && price.finalPrice && price.finalPrice.value) {
        // NOTE: 0 is a valid price but is falsey in JS, so properly check for
        // `null` rather than if `value` is truthy

        const mappedPrices: Prices = new Prices()
        const regularPrice = price.regularPrice

        if (price.discount?.amountOff && price.discount?.amountOff > 0) {
            mappedPrices.oldPrice = regularPrice
            mappedPrices.specialPrice = price.finalPrice
        } else {
            mappedPrices.regularPrice = price.finalPrice
        }
        return mappedPrices
    }

    return undefined
}

export const renderPrice = (
    price: Money | undefined,
    type: string,
    variantBig?: boolean,
    priceClassName?: string,
) => {
    if (price?.value == null || price?.currency == null) {
        return null
    }

    return (
        <Price
            price={price.value}
            currency={price.currency}
            old={type === 'old'}
            special={type === 'special'}
            variantBig={variantBig}
            className={priceClassName}
        />
    )
}

const PriceBox = ({
    regularPrice,
    oldPrice,
    specialPrice,
    className,
    priceClassName,
    variantBig,
}: Props) => (
    <div className={cx(className, styles.default)}>
        {renderPrice(regularPrice, 'regular', variantBig, priceClassName)}
        {renderPrice(oldPrice, 'old', variantBig, priceClassName)}
        {renderPrice(specialPrice, 'special', variantBig, priceClassName)}
    </div>
)

export default overridable('PriceBox')(PriceBox)
