import { useActiveStoreView } from '@emico/storeviews'

import useNavigationItems, {
    CustomNavigationItem,
} from '../../catalog/Navigation.query'
import { SubNavMenuItem } from '../../navigation/SubNav'
import makeMediaUrl from '../../utils/makeMediaUrl'

const useMenuItems = () => {
    const activeStoreView = useActiveStoreView()
    const menuItems = useNavigationItems(activeStoreView.code)

    return !menuItems ? undefined : mapItems(menuItems)
}

const getUrlPath = (item: CustomNavigationItem): string | undefined => {
    const url =
        item.category?.urlPath ||
        item.product?.urlKey ||
        item.cmsPage?.urlKey ||
        item.href ||
        undefined

    // When a menu item is left blank in the backend,
    // the HREF will have a value of #
    // Menu items need to have absolute links in order to work properly
    // So we make them absolute here by removing the / at the start of an url
    // if there is any, and add it manually
    return url ? `/${url.replace(/^\//, '')}` : undefined
}

const mapItems = (
    items: CustomNavigationItem[],
    level: number = 0,
): SubNavMenuItem[] => {
    level = level + 1

    const sortedItems = [...items].sort((a, b) => {
        if (!a || !b || !a.sortOrder || !b.sortOrder) {
            return 0
        }

        return a.sortOrder - b.sortOrder
    })

    return sortedItems
        .filter((item) => getUrlPath(item))
        .map<SubNavMenuItem>((item, index) => {
            const {
                promotionBackgroundImage,
                promotionButtonLink,
                promotionButtonText,
                promotionLink,
                promotionText,
                promotionBackgroundImage2,
                promotionButtonLink2,
                promotionButtonText2,
                promotionLink2,
                promotionText2,
            } = item || {}

            const banner: SubNavMenuItem['banner'] =
                promotionBackgroundImage &&
                (promotionLink || promotionButtonLink)
                    ? {
                          imageUrl: makeMediaUrl(
                              promotionBackgroundImage || '',
                          ),
                          to: promotionLink || promotionButtonLink || '',
                          text: promotionText || undefined,
                          btnLabel: promotionButtonText || undefined,
                      }
                    : undefined

            const banner2: SubNavMenuItem['banner'] =
                promotionBackgroundImage2 &&
                (promotionLink2 || promotionButtonLink2)
                    ? {
                          imageUrl: makeMediaUrl(
                              promotionBackgroundImage2 || '',
                          ),
                          to: promotionLink2 || promotionButtonLink2 || '',
                          text: promotionText2 || undefined,
                          btnLabel: promotionButtonText2 || undefined,
                      }
                    : undefined

            return {
                id: index,
                label: item.label,
                to: String(getUrlPath(item)),
                banner,
                banner2,
                children:
                    level < 3 ? mapItems(item.children, level) : undefined,
            }
        })
}

export default useMenuItems
