import { Component, ReactNode } from 'react'
import { connect } from 'react-redux'

import { fetchCoreConfig, getCartDetails } from './actions'
import { State } from './reducers'

interface DispatchProps {
    fetchCoreConfig(): void
    getCartDetails(): void
}
type Props = DispatchProps & {
    children: ReactNode
}

class AutoLoader extends Component<Props> {
    componentDidMount(): void {
        this.load()
    }

    load() {
        const { fetchCoreConfig, getCartDetails } = this.props

        Promise.resolve(fetchCoreConfig()).then(() => getCartDetails())
    }

    render() {
        return this.props.children
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, DispatchProps, {}, State>(null, {
    fetchCoreConfig,
    getCartDetails,
})(AutoLoader)
