import { t } from '@lingui/macro'

import { Maybe } from '@emico/graphql-schema-types'

import { Breadcrumbs } from './Breadcrumbs'
import {
    GroupedProduct,
    Product,
} from '../catalog/ProductPage/GetProduct.query'
import { useRootCategoryId } from '../catalog/useRootCategory'
import { ProductCardFragment, ProductCategory } from '../ProductCardFragment'
import { filterNullValues } from '../utils/filterNullValues'

interface Props {
    product: Product | GroupedProduct
}

type Categories = ProductCategory[]

export const filterCategories = (
    categories: Categories,
    rootCategoryId: number,
) =>
    categories.filter((category) =>
        category.path.split('/').includes(String(rootCategoryId)),
    )
const getDeepestCategoryLevel = (categories: Categories) =>
    categories.reduce<number>(
        (highest, category) => Math.max(highest, category.level),
        -Infinity,
    )

export const getFirstCategory = (categories: Categories) =>
    [...categories].sort((a, b) => a.position - b.position)[0]

export const getDeepestCategories = (categories: Categories) => {
    const deepestLevel = getDeepestCategoryLevel(categories)

    return categories.filter((cat) => cat.level === deepestLevel)
}

export const getBreadcrumbs = (
    productCategories: Product['categories'],
    rootCategoryId: number,
    productName?: string,
    productUrl?: string,
) => {
    const breadcrumbs = []

    // product.categories include categories from all websites in Magento,
    // filter away those not available on this website.
    const categories = filterCategories(productCategories, rootCategoryId)

    // Show default category if product has one.
    // Default category is the deepest nested category (highest level value)
    // that has the lowest position value
    if (categories.length > 0) {
        const fullCategoryUrl: string[] = []
        const deepestCategory = getFirstCategory(
            getDeepestCategories(categories),
        )

        const deepestCategoryBreadcrumbs = filterNullValues(
            deepestCategory.breadcrumbs,
        )

        if (deepestCategoryBreadcrumbs) {
            const clone = [...deepestCategoryBreadcrumbs]

            clone
                .sort((a, b) => a.categoryLevel - b.categoryLevel)
                .forEach((cat) => {
                    fullCategoryUrl.push('/', cat.categoryUrlKey)
                    breadcrumbs.push({
                        to: fullCategoryUrl.join(''),
                        label: cat.categoryName,
                    })
                })
        }

        fullCategoryUrl.push('/', deepestCategory.urlKey)
        breadcrumbs.push({
            to: fullCategoryUrl.join(''),
            label: deepestCategory.name,
        })
    } else {
        breadcrumbs.push({
            to: '/shop-all',
            label: t({
                id: 'core.mainNavigation.shopLabel',
                message: `Shop`,
            }),
        })
    }

    if (productUrl && productName) {
        breadcrumbs.push({
            to: `/${productUrl}`,
            label: productName,
        })
    }

    return breadcrumbs
}

interface BreadcrumbsType {
    label: Maybe<string | undefined>
    to: Maybe<string | undefined>
}

const getProductBreadcrumbs = (
    product: ProductCardFragment,
): BreadcrumbsType[] => {
    if (product.mainCategory && !product.mainCategory.breadcrumbs) {
        return [
            {
                label: product.mainCategory.name,
                to: product.mainCategory.urlPath,
            },
        ]
    }
    if (!product.mainCategory || !product.mainCategory.breadcrumbs) {
        return []
    }
    const breadcrumbs = product.mainCategory.breadcrumbs.map((breadcrumb) => ({
        label: breadcrumb?.categoryName,
        to: breadcrumb?.categoryUrlKey,
    }))

    const breadcrumbsPaths: BreadcrumbsType[] = [
        ...breadcrumbs,
        {
            label: product.mainCategory.name,
            to: product.mainCategory.urlPath,
        },
    ]

    return breadcrumbsPaths
}

const ProductBreadcrumbs = ({ product }: Props) => {
    const rootCategoryId = useRootCategoryId()

    if (!rootCategoryId) {
        return null
    }

    const breadcrumbs = getProductBreadcrumbs(product)?.concat({
        label: product.name,
        to: product.urlKey,
    })

    if (!breadcrumbs.length) {
        return null
    }

    return (
        <Breadcrumbs>
            {breadcrumbs.map(({ to, label }, index) => (
                <Breadcrumbs.Link key={to} to={to ?? ''} position={index + 1}>
                    {label}
                </Breadcrumbs.Link>
            ))}
        </Breadcrumbs>
    )
}

export default ProductBreadcrumbs
