import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { Item } from '@react-stately/collections'
import { Key, ReactNode } from 'react'
import { Control } from 'react-hook-form'

import { minWidth } from '@emico/styles'
import { useBreakpoints } from '@emico/ui'

import { Product } from '../../catalog/ProductPage/GetProduct.query'
import defaultAttributes from '../../catalog/ProductPage/StickyCta/defaultAttributes'
import flattenCombinedAttributes, {
    decodeAttributeValueObject,
} from '../../catalog/ProductPage/StickyCta/flattenCombinedAttributes'
import PopperSelectControl from '../../input/PopperSelectControl'

export interface CartItemConfigurableFormValue {
    value: string
    label: string
    isDisabled: boolean | undefined
}
interface CartItemConfigurableConfigurationProps {
    product: Product
    control: Control<CartItemConfigurableFormValue, 'value'>
    defaultSelectedKey?: Key | undefined
    onChange(value: Record<number, number>): void
    isDisabled?: boolean
    isRequired?: boolean
    errorMessage?: ReactNode
    horizontalLayout?: boolean
    className?: string
    selectAnOptionLabel?: string
}

export const CartItemConfigurableConfiguration = ({
    product,
    control,
    onChange,
    defaultSelectedKey,
    isDisabled,
    isRequired,
    errorMessage,
    horizontalLayout,
    selectAnOptionLabel,
    className,
}: CartItemConfigurableConfigurationProps) => {
    const { isMobile } = useBreakpoints()
    const flattenedOptions = flattenCombinedAttributes(product)
    const defaultOptions = defaultAttributes(product)
    const options =
        (flattenedOptions ?? defaultOptions)?.map((o) => ({
            value: o.value,
            label: o.label,
            isDisabled: o.disabled,
        })) ?? []

    return (
        <PopperSelectControl<CartItemConfigurableFormValue, 'value'>
            css={css`
                button {
                    width: var(--cart-item-popper-width, 130px);
                    margin-bottom: 0.8rem;
                }
                align-items: baseline;
                [disabled] {
                    border: 1px solid #dddddd;
                    background-color: #f3f3f3;
                    color: rgba(0, 0, 0, 0.5);
                    cursor: not-allowed;
                }
                @media ${minWidth('sm')} {
                    width: auto;
                }
            `}
            control={control}
            name="value"
            label={t({
                id: 'catalog.cart.configurableOption.size',
                message: `Size`,
            })}
            horizontalLayout={!isMobile || horizontalLayout}
            items={options}
            disabledKeys={options
                ?.filter((o) => o.isDisabled)
                .map((o) => o.value)}
            defaultSelectedKey={defaultSelectedKey}
            onSelectionChange={(key) => {
                const value = decodeAttributeValueObject<
                    Record<number, number>
                >(String(key))

                onChange(value)
            }}
            defaultPopperPosition="bottom-start"
            isRequired={isRequired}
            isDisabled={isDisabled}
            errorMessage={errorMessage}
            className={className}
            selectAnOptionLabel={selectAnOptionLabel}
        >
            {(item) => <Item key={item.value}>{item.label}</Item>}
        </PopperSelectControl>
    )
}
