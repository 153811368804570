import {
    gql,
    MutationResult,
    useApolloClient,
    useMutation,
} from '@apollo/client'
import {
    getCustomerTokenHeaderObject,
    useLoginTokenContext,
} from '@emico-hooks/login-token'

import { createGuestWishlist } from './createWishlist.mutation'
import { wishlistFragment } from './GetWishlist.query'
import { wishlistIdVar } from './useWishlistId'
import {
    Mutation,
    MutationAddProductsToWishlistArgs,
} from '../graphql/schema.generated'

const mutation = gql`
    mutation addProductsToWishlist(
        $wishlistId: ID!
        $wishlistItems: [WishlistItemInput!]!
    ) {
        addProductsToWishlist(
            wishlistId: $wishlistId
            wishlistItems: $wishlistItems
        ) {
            wishlist {
                ...WishlistFields
            }
        }
    }
    ${wishlistFragment}
`

type Result = Pick<Mutation, 'addProductsToWishlist'>

export const useAddProductsToWishlist = (): [
    (variables: MutationAddProductsToWishlistArgs['wishlistItems']) => void,
    MutationResult<Result>,
] => {
    const client = useApolloClient()
    const token = useLoginTokenContext()()

    // eslint-disable-next-line no-restricted-syntax
    const [addProductsToWishlist, props] = useMutation<
        Result,
        MutationAddProductsToWishlistArgs
    >(mutation, {
        context: getCustomerTokenHeaderObject(token),
    })

    return [
        async (wishlistItems) => {
            if (!wishlistIdVar()) {
                const result = await createGuestWishlist(client)

                wishlistIdVar(result.data?.createGuestWishlist?.wishlist.id)
            }
            const wishlistId = wishlistIdVar()

            if (!wishlistId) {
                return
            }
            addProductsToWishlist({
                variables: {
                    wishlistId,
                    wishlistItems,
                },
            })
        },
        props,
    ]
}
