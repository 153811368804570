import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
    ComponentSlider,
    NextArrow,
    PrevArrow,
    Slides,
    Track,
} from '@emico/component-slider'
import { useBreakpoints } from '@emico/ui'

import styles from './VisualFilter.module.css'
import { VisualFilterCards } from '../../graphql/schema.generated'
import ImageLazy from '../../media/ImageLazy'
import Link from '../../navigation/Link'
import theme from '../../theme'
import Heading from '../../typography/Heading'
import useSize from '../../useSize'

const GAP = 20

const ComponentSliderWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDesktop',
})<{
    isDesktop: boolean
}>`
    width: 100%;
    margin: 0 0 16px;
    ${(props) => (props.isDesktop ? `padding: 0 90px;` : '')}
`

const StyledComponentSlider = styled(ComponentSlider)`
    ${Slides} {
        justify-content: center;
    }
`

const CardLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
    isSelected?: boolean
}>`
    display: block;
    border-style: solid;
    border-width: 2px;
    text-decoration: none !important;
    border-color: ${(props) =>
        props.isSelected ? theme.colors.primary : 'transparent'};

    @media screen and (min-width: 768px) {
        border-width: 4px;
    }
`

const Card = styled.article`
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    background: #f8f8f8;
    cursor: pointer;
    user-select: none;
    width: min(35vw, 230px);
`

const Wrapper = styled.div`
    padding: 10px;
    overflow: hidden;
    order: 2;
`

const Label = styled(Heading)`
    position: absolute;
    top: 35%;
    left: 10px;
    padding: 0.5em 1em 0.2em;
    background: ${theme.colors.primary};
    color: ${theme.colors.onPrimary};
    text-transform: uppercase;
    text-align: center;
    /* This rule was broken, should this be enabled? */
    /* word-break; break-all; */
    font-size: clamp(10px, 2vw, 14px);
    z-index: 1;
`

const ImageWrapper = styled.div`
    flex: 1;
    order: 1;
    position: relative;
    width: 100%;

    overflow: hidden;
    text-align: center;
`

const StyledImageLazy = styled(ImageLazy)`
    width: 100%;
    height: auto;
`

const CardHeading = styled(Heading)`
    position: relative;
    padding-top: 0.5em;
`

const StyledNextArrow = styled(NextArrow)`
    right: -90px;
`

const StyledPrevArrow = styled(PrevArrow)`
    left: -90px;
`

const VisualFilter = ({ items }: { items: VisualFilterCards[] }) => {
    const { pathname, search } = useLocation()
    const currentUrl = `${pathname}${search}`
    const wrapperRef = useRef<HTMLDivElement>(null)
    const size = useSize(wrapperRef)
    const { isDesktop } = useBreakpoints()
    const [arrowsEnabled, setArrowsEnabled] = useState<boolean>(isDesktop)
    const initialSlide = items.findIndex(
        (item) => item.relativeUrl && currentUrl.startsWith(item.relativeUrl),
    )

    useEffect(() => {
        if (!isDesktop) {
            setArrowsEnabled(false)
        } else if (wrapperRef.current && size?.width) {
            const track = wrapperRef.current.querySelector(Track.toString())

            setArrowsEnabled(
                Boolean(track && track.scrollWidth > track.clientWidth),
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperRef.current, size?.width, isDesktop])

    if (!items || items.length === 0) {
        return null
    }

    const cards = items?.map(({ image, relativeUrl, title, label }) => {
        if (!image || !image.url || !relativeUrl || !title) {
            return null
        }

        const isSelected = currentUrl.startsWith(relativeUrl)

        return (
            <CardLink
                isSelected={isSelected}
                to={relativeUrl}
                name={title}
                category="visualFilter"
                key={image.url}
                preload
            >
                <Card data-active={isSelected ? true : false}>
                    <Wrapper>
                        <CardHeading variant="h4" element="h1" fuss={['pme']}>
                            {title}
                        </CardHeading>
                    </Wrapper>
                    <ImageWrapper>
                        <StyledImageLazy
                            url={image.url}
                            width={230}
                            lazy={false}
                            height={276}
                            alt={title}
                            images={{ image: '' }}
                            params={{
                                resizingType: 'fill',
                            }}
                        />
                    </ImageWrapper>
                    <Label variant="h4" element="div">
                        {label}
                    </Label>
                </Card>
            </CardLink>
        )
    })

    return (
        <ComponentSliderWrapper isDesktop={isDesktop} ref={wrapperRef}>
            <StyledComponentSlider
                className={styles.noScrollbar}
                arrows={arrowsEnabled}
                slideGap={GAP}
                currentSlide={initialSlide > 0 ? initialSlide : 0}
                padding={isDesktop ? 90 : 0}
                renderPrevArrow={({ handleClick, disabled }) => (
                    <StyledPrevArrow
                        disabled={disabled}
                        onClick={handleClick}
                        analyticsContext=""
                        analyticsName=""
                        // eslint-disable-next-line react/no-children-prop
                        children={null}
                    />
                )}
                renderNextArrow={({ handleClick, disabled }) => (
                    <StyledNextArrow
                        disabled={disabled}
                        onClick={handleClick}
                        analyticsContext=""
                        analyticsName=""
                        // eslint-disable-next-line react/no-children-prop
                        children={null}
                    />
                )}
            >
                {cards}
            </StyledComponentSlider>
        </ComponentSliderWrapper>
    )
}

export default VisualFilter
