import { useReactiveVar } from '@apollo/client'
import { useIsLoggedIn } from '@emico-hooks/login-token'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Plural, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'

import { ButtonUnstyled } from '@emico/ui'

import OutOfStockInfo from './OutOfStockInfo'
import styles from './ProductInfo.module.scss'
import ProductInfoHeader from './ProductInfoHeader'
import CartGiftItem from '../../../cart/CartGiftItem'
import { useCartGifts } from '../../../cart/useCartGifts'
import { LazySizeGuideModal } from '../../../chunks'
import { PRIVILEGES_ENABLED, WISHLIST_DISABLED } from '../../../constants'
import UspIcon from '../../../core/UspCheckIcon'
import { useCoreConfigObject } from '../../../coreConfig.query'
import ErrorBoundary from '../../../ErrorBoundary'
import FatalErrorPage from '../../../FatalErrorPage'
import Button from '../../../input/Button'
import FormError from '../../../input/FormError'
import { headerSizeVar } from '../../../layout/Header/HeaderContainer'
import List from '../../../List'
import Icon from '../../../media/Icon'
import Link from '../../../navigation/Link'
import PriceBox, { Prices } from '../../../presentation/PriceBox'
import { useSizeGuide } from '../../../presentation/SizeGuideModal/getSizeGuide.query'
import ProductColor from '../../../ProductColor'
import ProductInfoAccordion from '../../../ProductInfoAccordion'
import theme from '../../../theme'
import Heading from '../../../typography/Heading'
import Text from '../../../typography/Text'
import useUsps, { usePromotionBlock } from '../../../useUsps'
import { filterNullValues } from '../../../utils/filterNullValues'
import getProductStockInfo from '../../../utils/getProductStockInfo'
import overridable from '../../../utils/overridable'
import WishlistIconButton from '../../../wishlist/WishlistIconButton'
import ProductFormButton from '../../ProductForm/ProductFormButton'
import {
    ProductFormValues,
    UseProductFormReturn,
} from '../../ProductForm/useProductForm'
import ConfigurableAttributesField2, {
    ConfigurableAttributesFieldValue,
    setSelectedConfigurableValue,
    useSelectedConfigurableValue,
} from '../ConfigurableAttributesField/ConfigurableAttributesField'
import { hasLimitedStock } from '../ConfigurableAttributesField/helpers'
import { ConfigurableProduct } from '../ConfigurableProduct'
import { Product, ProductGroup } from '../GetProduct.query'
import PromotionBlock from '../PromotionBlock'
import ShopSupplyModal from '../ShopSupplyModal/ShopSupplyModal'
import { encodeAttributeValueObject } from '../StickyCta/flattenCombinedAttributes'

const StyledButton = styled(ButtonUnstyled)`
    margin-top: 15px;
    color: ${theme.colors.text};
    border-bottom: 1px solid ${theme.colors.text};
`

const TopPromoBlock = styled(PromotionBlock)`
    margin-bottom: 25px;
`

const BottomPromoBlock = styled(PromotionBlock)`
    margin-top: 25px;
`

export const generateLinkFromTag = (
    replaceComponent: (url: string, linkText: string) => ReactNode,
    text: string,
): ReactNode => {
    const regex = new RegExp('\\[url=(.+?)\\](.+?)\\[/url\\]', 'igm')
    const links: ReactNode[] = []
    const replacedText = text
        .replace(regex, (match, p1, p2) => {
            if (p1 && p2) {
                links.push(replaceComponent(p1, p2))
            }
            return '[link]'
        })
        .split('[link]')

    return (
        <>
            {replacedText.map((line, index) => (
                <Fragment key={index}>
                    {line}
                    {links[index]}
                </Fragment>
            ))}
        </>
    )
}

export const UniqueSellingPoints = () => {
    const { data: usps } = useUsps('pdp')

    if (!usps || usps.length === 0) {
        return null
    }

    const items = usps
        .map(({ label }) => ({
            icon: label && (
                <Icon
                    name={label}
                    component={UspIcon}
                    title={label}
                    className={styles.uspIcon}
                    wrapperClassName={styles.uspIconWrapper}
                />
            ),
            label: label && (
                <Text as="span" color="dark">
                    {generateLinkFromTag(
                        (url, linkText) => (
                            <Link
                                name={`${linkText}`}
                                category="catalog.productInfo.linkInUsp"
                                to={url}
                                title={linkText}
                            >
                                {linkText}
                            </Link>
                        ),
                        label,
                    )}
                </Text>
            ),
        }))
        .filter((items) => items.label)

    return (
        <div className={styles.usps}>
            <List items={items} size="small" />
        </div>
    )
}

export const SizeGuideLink = ({
    productGroup,
}: {
    productGroup: ProductGroup
}) => {
    const [isOpen, setOpen] = useState<boolean>(false)
    const { data } = useSizeGuide(productGroup)

    const rows = filterNullValues(data?.sizeTable)

    if (!rows || !data) {
        return null
    }

    const handleToggleSizeGuide = () => {
        setOpen(!isOpen)
    }

    return (
        <>
            <div className={styles.sizeGuideButtonContainer}>
                <Button
                    className={styles.sizeGuideButton}
                    variant="link"
                    name="whats my size"
                    category="sizeguide"
                    onClick={handleToggleSizeGuide}
                    onMouseOver={() => {
                        LazySizeGuideModal.preload()
                    }}
                >
                    <Trans id="catalog.productInfo.sizeguide.buttonText">
                        What's my size?
                    </Trans>
                </Button>
            </div>

            {isOpen && (
                <LazySizeGuideModal
                    close={handleToggleSizeGuide}
                    productGroup={productGroup}
                />
            )}
        </>
    )
}

const SelectionFeedback = ({
    configurableOptionIds = [],
    product,
    selectedValue,
    validationError,
}: {
    product?: ConfigurableProduct
    configurableOptionIds: number[]
    selectedValue?: ConfigurableAttributesFieldValue
    validationError?: string
}) => {
    const [quantity, setQuantity] = useState<number | undefined>()

    useEffect(() => {
        const variantFound = product?.variants?.find((variant) => {
            const { attributes } = variant

            const attrs = Object.keys(selectedValue || {})
                .map((optionKey) =>
                    attributes.find(
                        (at) =>
                            at.valueIndex ===
                            selectedValue?.[Number(optionKey)],
                    ),
                )
                .filter(Boolean)

            if (attrs && attrs.length === configurableOptionIds.length) {
                return variant
            }
            return null
        })

        if (variantFound && hasLimitedStock(variantFound)) {
            setQuantity(Number(variantFound?.product?.onlyXLeftInStock))
        } else {
            setQuantity(undefined)
        }
    }, [product, selectedValue, configurableOptionIds])

    return (
        <div
            className={classNames(styles.selectionFeedback, {
                [styles.show]: quantity || Boolean(validationError),
            })}
        >
            {quantity && (
                <Plural
                    id="core.catalog.product.limitedStock"
                    value={quantity}
                    one="There is only 1 left in this size."
                    other="There are only {quantity} left in this size."
                />
            )}

            {validationError && validationError}
        </div>
    )
}

const FreeGift = () => {
    const validRules = useCartGifts((rule) => !rule.is_valid_conditions)
    const isLoggedIn = useIsLoggedIn()
    const rule = validRules?.[0]
    const [item] = rule?.items || []

    return item ? (
        <div className={styles.pdpFreeGift}>
            {!isLoggedIn && (
                <Heading variant="h3" element="h3" color="dark">
                    <Trans id="catalog.productDetailView.freegift.memberOnlyTitle">
                        For members only
                    </Trans>
                </Heading>
            )}
            <CartGiftItem
                className={styles.cartGiftItem}
                productId={item.productId}
                cartItem={item.item}
                rule={rule}
                withSku={false}
            />
        </div>
    ) : null
}

export interface Props {
    product: Product | ConfigurableProduct
    isConfigurable: boolean
    prices?: Prices
    error?: string
    children?: ReactNode
    usp_icons?: string
    hideStickyCta: (value: boolean) => void

    showSubmit: boolean
    isInCart: boolean
    isSubmitting: boolean
    configurableSelectProps: UseProductFormReturn['configurableSelectProps']

    validationError?: string
}

const ProductInfo = ({
    product,
    isConfigurable,
    prices,
    error,
    children,
    hideStickyCta,
    isInCart,
    showSubmit,
    isSubmitting,
    configurableSelectProps,
    validationError,
}: Props) => {
    const headerSize = useReactiveVar(headerSizeVar)
    const rootMargin = headerSize?.height ?? 0
    const { isOutOfStock } = getProductStockInfo(product)
    const { data: promotionBlock } = usePromotionBlock()
    const selectedValueVar = useSelectedConfigurableValue(product)
    const [openModal, setOpenModal] = useState(false)

    const setSelectedAttributeValue = useCallback(
        (value) => {
            setSelectedConfigurableValue(product, value)
        },
        [product],
    )

    const { data: coreConfig } = useCoreConfigObject()

    if (!coreConfig) {
        return null
    }
    const FEATURE_FLAG = coreConfig[`justbrands_srs/stock/enable_frontend`]

    const topPromotionBlock = promotionBlock.find(
        (p) => p.position === 'pdp_top',
    )

    const bottomPromotionBlock = promotionBlock.find(
        (p) => p.position === 'pdp_bottom',
    )

    return (
        <>
            <ProductInfoHeader product={product} />
            {product.sku && <Text className={styles.sku}>{product.sku}</Text>}
            {product.shortDescription && (
                <Text className={styles.description} as="div">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: product.shortDescription?.html ?? '',
                        }}
                    />
                </Text>
            )}
            {isOutOfStock ? (
                <div
                    style={{
                        position: 'sticky',
                        top: (headerSize?.height ?? 0) + 20,
                    }}
                >
                    <InView
                        onChange={(inView) => {
                            hideStickyCta(inView)
                        }}
                    >
                        {/* TODO: When available, pass selected value for the wishlist */}
                        <OutOfStockInfo product={product} value={undefined} />
                    </InView>
                    <ErrorBoundary
                        fallback={<FatalErrorPage type="component" />}
                    >
                        <ProductInfoAccordion product={product} />
                    </ErrorBoundary>
                </div>
            ) : (
                <>
                    {prices && (
                        <PriceBox
                            className={styles.priceBox}
                            priceClassName={styles.prices}
                            {...prices}
                            variantBig
                            outletAdviceprice={
                                product.outletAdviesprijs ?? null
                            }
                        />
                    )}

                    {topPromotionBlock && (
                        <ErrorBoundary fallback={<Fragment />}>
                            <TopPromoBlock
                                title={topPromotionBlock.label ?? ''}
                                content={topPromotionBlock.promotionText ?? ''}
                                image={topPromotionBlock.promotionImage ?? ''}
                                url={topPromotionBlock.url ?? ''}
                            />
                        </ErrorBoundary>
                    )}

                    <ProductColor product={product} />

                    {children}

                    {product.productGroup && (
                        <ErrorBoundary
                            fallback={<FatalErrorPage type="component" />}
                        >
                            <SizeGuideLink
                                productGroup={product.productGroup}
                            />
                        </ErrorBoundary>
                    )}

                    {error && <FormError autoHide={false}>{error}</FormError>}

                    <div
                        style={{
                            position: 'sticky',
                            top: (headerSize?.height ?? 0) + 20,
                        }}
                    >
                        {isConfigurable && (
                            <ConfigurableAttributesField2<
                                ProductFormValues,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                any
                            >
                                {...configurableSelectProps}
                                className={styles.configurableOptions}
                                product={product as ConfigurableProduct}
                                onChange={setSelectedAttributeValue}
                            />
                        )}
                        {isConfigurable && (
                            <SelectionFeedback
                                validationError={validationError}
                                configurableOptionIds={
                                    isConfigurable
                                        ? (
                                              product as ConfigurableProduct
                                          ).configurableOptions.map((confOpt) =>
                                              Number(confOpt.attributeId),
                                          )
                                        : []
                                }
                                product={product as ConfigurableProduct}
                                selectedValue={selectedValueVar}
                            />
                        )}

                        <InView
                            onChange={(inView) => {
                                hideStickyCta(inView)
                            }}
                            rootMargin={`-${rootMargin}px`}
                            className={styles.buttonWrapper}
                        >
                            <ProductFormButton
                                isInCart={isInCart}
                                isSubmitting={isSubmitting}
                                showSubmit={showSubmit}
                            />
                            {!WISHLIST_DISABLED && (
                                <WishlistIconButton
                                    position="pdp-addtowishlist"
                                    product={product}
                                    css={css`
                                        width: 40px;
                                        flex: 0 0 40px;
                                    `}
                                    value={selectedValueVar}
                                    showWishlistModal
                                />
                            )}
                        </InView>
                        {FEATURE_FLAG === '1' && (
                            <ErrorBoundary
                                fallback={<FatalErrorPage type="component" />}
                            >
                                <StyledButton
                                    analyticsName="link"
                                    analyticsContext="catalog.productDetailView.sizeAvailablity"
                                    onClick={() => setOpenModal(true)}
                                    type="button"
                                >
                                    <Trans id="catalog.productDetailView.sizeAvailablity.modalLink">
                                        Size not available? Check availability
                                        in store.
                                    </Trans>
                                </StyledButton>
                            </ErrorBoundary>
                        )}
                        <ErrorBoundary fallback={<Fragment />}>
                            <UniqueSellingPoints />
                        </ErrorBoundary>
                        <ErrorBoundary
                            fallback={<FatalErrorPage type="component" />}
                        >
                            <ProductInfoAccordion product={product} />
                        </ErrorBoundary>

                        {bottomPromotionBlock && (
                            <ErrorBoundary fallback={<Fragment />}>
                                <BottomPromoBlock
                                    title={bottomPromotionBlock.label ?? ''}
                                    content={
                                        bottomPromotionBlock.promotionText ?? ''
                                    }
                                    image={
                                        bottomPromotionBlock.promotionImage ??
                                        ''
                                    }
                                    url={bottomPromotionBlock.url ?? ''}
                                />
                            </ErrorBoundary>
                        )}
                        {PRIVILEGES_ENABLED && <FreeGift />}
                    </div>
                </>
            )}
            {openModal && (
                <ShopSupplyModal
                    onClose={() => setOpenModal(false)}
                    product={product}
                    defaultKey={encodeAttributeValueObject(selectedValueVar)}
                />
            )}
        </>
    )
}

export default overridable('ProductInfo')(ProductInfo)
