import loginTokenVar from '@emico/login-token'

import actions from './actions'
import { ConfigurableAttributesFieldValue } from '../../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../../catalog/ProductPage/GetProduct.query'
import { ProductCardFragment } from '../../ProductCardFragment'
import publishBlueConicEvent from '../../publishBlueConicEvent'
import { CartState } from '../../reducers'
import storage from '../../storage'
import {
    QuoteDataCartInterface,
    QuoteDataCartItemInterface,
    QuoteDataPaymentMethodInterface,
    QuoteDataTotalsInterface,
} from '../../types/MagentoRestApi'
import { Action, ThunkDispatch, ThunkResult } from '../../types/Redux'
import isJson from '../../utils/isJson'
import { M2ApiResponseError } from '../../utils/RestApi'
import { RequestType } from '../../utils/RestApi/request'
import checkoutActions from '../checkout'
import { isSignedIn } from '../user'

export const createCart = (): ThunkResult<Promise<string>> =>
    async function thunk(dispatch, getState, { request }): Promise<string> {
        // reset the checkout workflow
        dispatch(checkoutActions.reset())

        {
            // if a cart exists in storage, act like we just received it
            const cartId = await getCartId()

            if (cartId && !isSignedIn()) {
                return cartId
            }
        }

        const guestCartEndpoint = '/rest/V1/guest-carts'
        const signedInCartEndpoint = '/rest/V1/carts/mine'
        const cartEndpoint = isSignedIn()
            ? signedInCartEndpoint
            : guestCartEndpoint

        const cartId: string = await request(cartEndpoint, {
            method: 'POST',
            authorizationToken: loginTokenVar(),
        })

        // write to storage in the background
        saveCartId(cartId)

        return cartId
    }

class MissingCartIdError extends Error {}
export interface MagentoRestError {
    json: {
        message: string
        parameters: Array<string | number>
    }
}

export const addItemToCart = (
    pushAddToCart: (itemId: string) => void,
    product: Product | ConfigurableProduct,
    quantity: number,
    selectedOptions?: ConfigurableAttributesFieldValue,
    openMiniCartAfterAdd: boolean = true,
): ThunkResult<Promise<void>> =>
    async function thunk(dispatch, getState, services): Promise<void> {
        const { request } = services

        try {
            const { user } = getState()

            let cartId = getCartId()

            if (!cartId) {
                // addItemToCart must automatically create a cart so that users
                // of the action needn't worry about it
                cartId = await dispatch(createCart())
            }

            const cartItem = toRESTCartItem(
                cartId,
                product,
                quantity,
                selectedOptions,
            )

            const guestCartEndpoint = `/rest/V1/guest-carts/${cartId}/items`
            const signedInCartEndpoint = '/rest/V1/carts/mine/items'
            const cartEndpoint = isSignedIn()
                ? signedInCartEndpoint
                : guestCartEndpoint

            const addedItem = await request(cartEndpoint, {
                method: 'POST',
                body: JSON.stringify({ cartItem }),
                authorizationToken: user.token,
            })

            pushAddToCart(addedItem.sku || product.sku)
            // Refresh cart to update totals
            // NOTE: While the mini cart does this call too and it's
            // automatically opened upon adding an item to the cart, this
            // doesn't occur on mobile so we still need to do this call here
            // aswell.
            await dispatch(getCartDetails({ forceRefresh: true }))

            const { cart } = getState()

            // If a gift is available for selecting, the gift modal is shown, so we don't want to also show the mini cart.
            const isGiftAvailable = cart?.newGiftsAvailable?.some(
                (item) => item.is_available,
            )

            const willShowGiftsModal = isGiftAvailable && cart.giftsHaveChanged

            if (!willShowGiftsModal && openMiniCartAfterAdd) {
                dispatch(actions.miniCart.open())
            }

            publishBlueConicEvent(
                'shoppingcart',
                (cart.details?.items || []).map(
                    (item) => item.product_sku || item.sku,
                ),
            )
        } catch (error) {
            // check if the cart has expired
            if (
                error instanceof MissingCartIdError ||
                (String(error).includes('quoteId') &&
                    String(error).includes(
                        '"%fieldName" is required. Enter and try again.',
                    )) ||
                String(error).includes(
                    'Current customer does not have an active cart.',
                ) ||
                String(error).includes(
                    'Cart is locked due to pending Payment',
                ) ||
                String(error).includes(
                    'No such entity with %fieldName = %fieldValue',
                )
            ) {
                // Delete the cached ID from local storage and Redux.
                // In contrast to the save, make sure storage deletion is
                // complete before dispatching the error--you don't want an
                // upstream action to try and reuse the known-bad ID.
                await dispatch(removeCart())
                // then create a new one
                await dispatch(createCart())
                // then retry this operation
                return thunk(dispatch, getState, services)
            } else {
                throw error
            }
        }
    }

export const updateItemInCart = (
    pushAddToCart: (itemId: string) => void,
    targetItemId: number,
    product: Product | ConfigurableProduct,
    quantity: number,
    options?: ConfigurableAttributesFieldValue,
): ThunkResult<Promise<void>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        services,
    ): Promise<void> {
        const { request } = services
        const { user } = getState()

        dispatch(actions.updateItem.request(targetItemId))

        try {
            const cartId = getCartId()

            if (!cartId) {
                // Don't create a cart here since if there isn't one, there also isn't anything to update
                throw new MissingCartIdError(
                    'Missing required information: cartId',
                )
            }

            const cartItem = toRESTCartItem(cartId, product, quantity, options)

            const guestCartEndpoint = `/rest/V1/guest-carts/${cartId}/items/${targetItemId}`
            const signedInCartEndpoint = `/rest/V1/carts/mine/items/${targetItemId}`
            const cartEndpoint = isSignedIn()
                ? signedInCartEndpoint
                : guestCartEndpoint

            await request(cartEndpoint, {
                method: 'PUT',
                body: JSON.stringify({ cartItem }),
                authorizationToken: user.token,
            })

            pushAddToCart(cartItem.sku || product.sku)

            dispatch(
                actions.updateItem.receive({
                    cartItemId: targetItemId,
                    quantity,
                    error: false,
                    salableQuantity: undefined,
                }),
            )
        } catch (err) {
            if (!(err instanceof Error)) {
                return
            }

            // Something went wrong. most likely because the item is not in stock
            // Try and get salable quantity from error message
            // This is available when user selects different size from size select, and that size is out of stock.
            let salableQuantity
            let errorMessage =
                err instanceof M2ApiResponseError
                    ? err.userMessage
                    : err.message

            if (errorMessage.includes('\n')) {
                errorMessage = errorMessage.split('\n')[0]
            }

            if (isJson(errorMessage)) {
                const { params } = JSON.parse(errorMessage)

                if (params.salableQty) {
                    salableQuantity = params.salableQty
                }
            }

            dispatch(
                actions.updateItem.receive({
                    cartItemId: targetItemId,
                    quantity,
                    salableQuantity,
                    error: true,
                }),
            )

            const response =
                err instanceof M2ApiResponseError ? err.response : undefined

            // check if the cart has expired
            if (
                err instanceof MissingCartIdError ||
                (response && [401, 404].includes(response.status))
            ) {
                // Delete the cached ID from local storage and Redux.
                // In contrast to the save, make sure storage deletion is
                // complete before dispatching the error--you don't want an
                // upstream action to try and reuse the known-bad ID.
                await dispatch(removeCart())
                // then create a new one
                await dispatch(createCart())

                if (isSignedIn()) {
                    // The user is signed in and we just received their cart.
                    // Retry this operation.
                    return thunk(dispatch, getState, services)
                } else {
                    // The user is a guest and just received a brand new (empty) cart.
                    // Add the updated item to that cart.
                    await dispatch(
                        addItemToCart(
                            pushAddToCart,
                            product,
                            quantity,
                            options,
                            false,
                        ),
                    )
                }
            }
        }

        // Refresh cart to update totals
        await dispatch(getCartDetails({ forceRefresh: true }))

        // Push updated cart to BlueConic
        const { cart } = getState()

        publishBlueConicEvent(
            'shoppingcart',
            (cart.details?.items || []).map(
                (item) => item.product_sku || item.sku,
            ),
        )
    }

export const removeItemFromCart = (
    cartItem: Pick<QuoteDataCartItemInterface, 'item_id' | 'qty'>,
    product?: ProductCardFragment,
    noRefresh?: boolean,
): ThunkResult<Promise<void>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        services,
    ): Promise<void> {
        const { request } = services

        const { cart } = getState()

        dispatch(actions.removeItem.request())

        try {
            const cartId = getCartId()

            if (!cartId) {
                throw new MissingCartIdError(
                    'Missing required information: cartId',
                )
            }

            const guestCartEndpoint = `/rest/V1/guest-carts/${cartId}/items/${cartItem.item_id}`
            const signedInCartEndpoint = `/rest/V1/carts/mine/items/${cartItem.item_id}`
            const cartEndpoint = isSignedIn()
                ? signedInCartEndpoint
                : guestCartEndpoint

            await request(cartEndpoint, {
                method: 'DELETE',
            })

            // When removing the last item in the cart, perform a reset
            // to prevent a bug where the next item added to the cart has
            // a price of 0
            const cartItemCount = cart.details ? cart.details.items_count : 0

            if (cartItemCount === 1) {
                await dispatch(removeCart())
            }

            dispatch(
                actions.removeItem.receive({
                    cartItemId: cartItem.item_id,
                }),
            )
        } catch (error) {
            const response =
                error instanceof M2ApiResponseError ? error.response : undefined

            dispatch(actions.removeItem.receive(error))

            // check if the cart has expired
            if (
                error instanceof MissingCartIdError ||
                (response && [401, 404].includes(response.status))
            ) {
                // Delete the cached ID from local storage.
                // The reducer handles clearing out the bad ID from Redux.
                // In contrast to the save, make sure storage deletion is
                // complete before dispatching the error--you don't want an
                // upstream action to try and reuse the known-bad ID.
                await dispatch(removeCart())
            }
        }

        // Refresh cart to update totals
        if (!noRefresh) {
            await dispatch(getCartDetails({ forceRefresh: true }))
        }

        // Push updated cart to BlueConic
        const { cart: updatedCart } = getState()

        publishBlueConicEvent(
            'shoppingcart',
            (updatedCart.details?.items || []).map(
                (item) => item.product_sku || item.sku,
            ),
        )
    }

export const getCartDetails = (
    payload: {
        forceRefresh?: boolean
        withPaymentDetails?: boolean
        cartId?: string
        isSignedIn?: boolean
    } = {},
): ThunkResult<Promise<void>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        services,
    ): Promise<void> {
        const { request } = services
        const { forceRefresh, withPaymentDetails } = payload
        const cartId = payload.cartId ?? getCartId()
        const signedIn = payload.isSignedIn ?? isSignedIn()

        // If there isn't a cart and the user isn't signed in, abort.
        // Don't automatically create a cart since this would make carts for
        // every single (bot) visit which would cost too many server-side
        // resources.
        // If the user is signed in we must ask the server if it has a cart for
        // us.
        if (!cartId && !signedIn) {
            return
        }

        try {
            let paymentMethods: QuoteDataPaymentMethodInterface[] | undefined
            const [details, totals] = await fetchCart(
                request,
                cartId,
                signedIn,
                forceRefresh === true,
            )

            if (signedIn) {
                saveCartId(String(details.id))
            }

            if (withPaymentDetails) {
                paymentMethods = await fetchCartPart({
                    request,
                    cartId,
                    forceRefresh,
                    isSignedIn: signedIn,
                    subResource: 'payment-methods',
                })
            }

            const receive: (payload: Partial<CartState>) => Action =
                actions.getDetails.receive

            await dispatch(
                receive({
                    details,
                    totals,
                    ...(withPaymentDetails ? { paymentMethods } : {}),
                }),
            )
        } catch (error) {
            const response =
                error instanceof M2ApiResponseError ? error.response : undefined

            // check if the cart has expired
            if (response && [401, 404].includes(response.status)) {
                // if so, then delete the cached ID from local storage.
                // In contrast to the save, make sure storage deletion is
                // complete before dispatching the error--you don't want an
                // upstream action to try and reuse the known-bad ID.
                await dispatch(removeCart())
            } else {
                throw error
            }
        }
    }

export const removeCart = (): ThunkResult<Promise<void>> =>
    async function thunk(dispatch) {
        // Clear the cartId from local storage.
        clearCartId()

        // Clear the cart info from the redux store.
        dispatch(actions.reset())
    }

export const AddCouponErrors = {
    NO_CART: 'Current customer does not have an active cart.',
    CART_IS_EMPTY: 'The "%1" Cart doesn\'t contain products.',
    INVALID_COUPON_CODE:
        "The coupon code isn't valid. Verify the code and try again.",
}
export const addCoupon = (couponCode: string): ThunkResult<Promise<void>> =>
    async function thunk(dispatch, getState, { request }) {
        const { user } = getState()

        let cartId = getCartId()

        if (!cartId) {
            // addCoupon must automatically create a cart so that users
            // of the action needn't worry about it
            cartId = await dispatch(createCart())
        }
        const guestCartEndpoint = `/rest/V1/guest-carts/${cartId}/coupons/${couponCode}`
        const signedInCartEndpoint = `/rest/V1/carts/mine/coupons/${couponCode}`
        const endpoint = isSignedIn() ? signedInCartEndpoint : guestCartEndpoint

        try {
            await request(endpoint, {
                method: 'PUT',
                authorizationToken: user.token,
            })
        } catch (err) {
            let ignoreError = false

            if (!(err instanceof Error)) {
                return
            }
            const errorMessage =
                err instanceof M2ApiResponseError
                    ? err.userMessage
                    : err.message

            if (errorMessage === AddCouponErrors.CART_IS_EMPTY) {
                // Even though it says cart is empty it still applied the
                // voucher... Weird behavior but hey it's actually quite
                // convenient for our users .
                ignoreError = true
            }
            if (!ignoreError) {
                throw err
            }
        }

        // Refresh cart to update totals
        await dispatch(getCartDetails({ forceRefresh: true }))
    }

/* helpers */

async function fetchCart(
    request: RequestType,
    cartId: string | null,
    isSignedIn: boolean,
    forceRefresh: boolean,
) {
    // Never fetch the parts separately! The codebase relies on all parts being
    // loaded in state.
    // TODO: Convert to GraphQL and load the extra product info we need in one go (e.g. product description & image)
    return Promise.all([
        fetchCartDetails(request, cartId, isSignedIn, forceRefresh),
        fetchCartTotals(request, cartId, isSignedIn, forceRefresh),
    ])
}

async function fetchCartPart(cartPart: {
    request: RequestType
    cartId: string | null
    forceRefresh?: boolean
    isSignedIn: boolean
    subResource?:
        | 'items'
        | 'totals'
        | 'totals-information'
        | 'coupons'
        | 'shipping-methods'
        | 'shipping-information'
        | 'payment-methods'
        | 'selected-payment-method'
        | 'payment-information'
        | 'billing-address'

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> {
    const {
        request,
        cartId,
        forceRefresh,
        isSignedIn,
        subResource = '',
    } = cartPart
    const signedInEndpoint = `/rest/V1/carts/mine/${subResource}`
    const guestEndpoint = `/rest/V1/guest-carts/${cartId}/${subResource}`
    const endpoint = isSignedIn ? signedInEndpoint : guestEndpoint

    const cache = forceRefresh ? 'reload' : 'default'

    return request(endpoint, { cache })
}

// Extra wrappers to get correct return types
async function fetchCartDetails(
    request: RequestType,
    cartId: string | null,
    isSignedIn: boolean,
    forceRefresh?: boolean,
): Promise<QuoteDataCartInterface> {
    return fetchCartPart({
        request,
        cartId,
        forceRefresh,
        isSignedIn,
    })
}

async function fetchCartTotals(
    request: RequestType,
    cartId: string | null,
    isSignedIn: boolean,
    forceRefresh?: boolean,
): Promise<QuoteDataTotalsInterface> {
    return fetchCartPart({
        request,
        cartId,
        forceRefresh,
        isSignedIn,
        subResource: 'totals',
    })
}

export function getCartId(): string | null {
    return storage.getItem('cartId')
}
export function saveCartId(id: string): void {
    return storage.setItem('cartId', id)
}
export function clearCartId(): void {
    return storage.removeItem('cartId')
}

/**
 * Transforms an item payload to a shape that the REST endpoints expect.
 * When GraphQL comes online we can drop this.
 */
function toRESTCartItem(
    cartId: string,
    product: Product | ConfigurableProduct,
    quantity: number,
    options?: ConfigurableAttributesFieldValue,
) {
    const cartItem: Omit<
        QuoteDataCartItemInterface,
        | 'product_id'
        | 'product_type'
        | 'product_name'
        | 'product_sku'
        | 'is_salable'
        | 'is_free_gift'
        | 'salable_qty'
        | 'reservation_qty'
    > = {
        qty: quantity,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sku: product.sku!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        name: product.name!,
        quote_id: cartId,
        price: product.priceRange.maximumPrice.regularPrice.value,
    }

    if ((product as ConfigurableProduct).configurableOptions && options) {
        cartItem.product_option = {
            extension_attributes: {
                configurable_item_options:
                    convertConfigurableOptionsValuesToRestConfigurableItemOptions(
                        options,
                    ),
            },
        }
    }
    return cartItem
}
const convertConfigurableOptionsValuesToRestConfigurableItemOptions = (
    values: ConfigurableAttributesFieldValue,
) =>
    Object.entries(values).map(([optionId, valueId]) => ({
        option_id: optionId,
        option_value: valueId,
    }))

export const restoreCart = (): ThunkResult<Promise<boolean>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        { request },
    ): Promise<boolean> {
        const guestRestoreCartEndpoint = '/rest/V1/guest-carts/restore'
        const authedRestoreCartEndpoint = '/rest/V1/carts/restore'
        const restoreCartEndpoint = isSignedIn()
            ? authedRestoreCartEndpoint
            : guestRestoreCartEndpoint

        const isCartRestored = await request(restoreCartEndpoint, {
            method: 'POST',
        })

        if (!isCartRestored) {
            // Cart could not be restored.
            // Clear the entire cart because it has become invalid
            await dispatch(removeCart())
        }

        return isCartRestored
    }
