import { QuoteDataShippingMethodInterface } from './MagentoRestApi'
import { CountryCodeEnum } from '../graphql/schema.generated'

export interface Address {
    company: string
    firstName: string
    lastName: string
    street: string
    houseNumber: string
    suffix: string
    postalCode: string
    city: string
    country: CountryCodeEnum
    customerAddressId?: number
}

export interface ShippingMethod {
    carrierCode: string
    methodCode: string
}

// NOTE: The ouput must have the same format as Magento's serialization
export const serializeQuoteDataShippingMethodInterface = (
    shippingMethod: QuoteDataShippingMethodInterface,
) => `${shippingMethod.carrier_code}_${shippingMethod.method_code}`
export const serializeShippingMethod = (shippingMethod: ShippingMethod) =>
    `${shippingMethod.carrierCode}_${shippingMethod.methodCode}`
export const deserializeShippingMethod = (
    shippingMethod: string,
): ShippingMethod => {
    const parts = shippingMethod.split('_', 2)

    return {
        carrierCode: parts[0],
        methodCode: parts[1],
    }
}
