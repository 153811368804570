import styled from '@emotion/styled'
import { Plural, Trans } from '@lingui/macro'
import { forwardRef } from 'react'

import { minWidth } from '@emico/styles'

import CartItem from './MiniCartItem'
import NextGiftNotification from './NextGiftNotification'
import cartActions from '../actions/cart'
import CheckIcon from '../core/CheckIcon/CheckIcon'
import { CurrencyEnum } from '../graphql/schema.generated'
import Button from '../input/Button'
import Icon from '../media/Icon'
import MiniMenu, {
    MiniMenuFooter,
    MiniMenuContent,
    MiniMenuHeading,
    MiniMenuActions,
} from '../MiniMenu'
import paths from '../paths'
import theme from '../theme'
import { useDispatch, useSelector } from '../types'
import useFreeGiftRules from '../useFreeGiftRules'
import useIsCheckoutAccessible from '../useIsCheckoutAccessible'

type ContainerProps = React.HTMLAttributes<HTMLElement> &
    React.RefAttributes<HTMLElement>

const StyledMiniMenu = styled(MiniMenu)`
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;

    @media ${minWidth('md')} {
        right: -18px;
        width: 425px;
    }
`

export const MiniCartUspIcon = styled(Icon)`
    width: 12px;
    height: 12px;
    margin-right: 12px;
    fill: ${theme.colors.primary};
`

// eslint-disable-next-line react/display-name
const MiniCart = forwardRef<HTMLElement, ContainerProps>(
    ({ ...others }: ContainerProps, ref) => {
        const { details, totals, miniCartFreeGiftRuleIds } = useSelector(
            (state) => state.cart,
        )
        const dispatch = useDispatch()
        const { rules } = useFreeGiftRules()
        const canCheckout = useIsCheckoutAccessible()

        if (!details || !details.items || !totals || !totals.items) {
            return null
        }

        const totalsItems = totals.items

        const currencyCode = details
            ? (details?.currency?.quote_currency_code as CurrencyEnum)
            : undefined

        const selectedGiftItemIds =
            miniCartFreeGiftRuleIds &&
            rules
                .filter((r) => miniCartFreeGiftRuleIds.includes(r.rule_id))
                .flatMap((i) => i.item_ids || [])

        const cartItem = details.items
            .filter((item) => !item.extension_attributes?.is_free_gift)
            .map((item) => ({
                cartItem: item,
                totals: totalsItems.find(
                    (value) => value.item_id === item.item_id,
                ),
            }))
            .reverse()?.[0]

        const giftItems =
            selectedGiftItemIds &&
            details.items
                .filter(
                    (item) =>
                        Boolean(item.extension_attributes?.is_free_gift) &&
                        item.item_id &&
                        selectedGiftItemIds.includes(item.item_id),
                )
                .map((item) => ({
                    cartItem: item,
                    totals: totalsItems.find(
                        (value) => value.item_id === item.item_id,
                    ),
                }))

        return (
            <StyledMiniMenu
                ref={ref}
                heading={
                    <Trans id="cart.miniCart.headingAddedToCart">
                        Added to cart
                    </Trans>
                }
                {...others}
            >
                <MiniMenuContent>
                    {cartItem?.totals && currencyCode && (
                        <CartItem
                            cartItem={cartItem.cartItem}
                            totals={cartItem.totals}
                            currency={currencyCode}
                        />
                    )}
                    {giftItems && giftItems.length > 0 && (
                        <>
                            <MiniMenuHeading>
                                <Plural
                                    id="cart.miniCart.headingAddedGiftsToCart"
                                    value={giftItems.length}
                                    one="Gift in your cart"
                                    other="Gifts in your cart"
                                />
                            </MiniMenuHeading>
                            {giftItems.map(
                                (item) =>
                                    item.totals &&
                                    currencyCode && (
                                        <CartItem
                                            key={item.cartItem.product_id}
                                            variant="gift"
                                            cartItem={item.cartItem}
                                            totals={item.totals}
                                            currency={currencyCode}
                                        />
                                    ),
                            )}
                        </>
                    )}
                    <NextGiftNotification variant="minicart" />
                </MiniMenuContent>
                <MiniMenuActions>
                    <Button
                        to={paths.cart}
                        onClick={() => {
                            dispatch(cartActions.miniCart.close())
                        }}
                        variant="secondary"
                        name="View cart"
                        category="cart.miniCart.viewCart"
                        data-testid="cart.miniCart.viewCart"
                    >
                        <Trans id="cart.miniCart.cartButtonLabel">
                            View bag
                        </Trans>
                    </Button>

                    {canCheckout && (
                        <Button
                            to={paths.checkout}
                            onClick={() => {
                                dispatch(cartActions.miniCart.close())
                            }}
                            variant="primary"
                            name="Checkout"
                            category="cart.miniCart.checkout"
                            data-testid="cart.mini.placeOrder"
                        >
                            <Trans id="cart.miniCart.checkoutButtonLabel">
                                Checkout
                            </Trans>
                        </Button>
                    )}
                </MiniMenuActions>
                <MiniMenuFooter centerText>
                    <MiniCartUspIcon component={CheckIcon} title="" />
                    <Trans id="cart.miniCart.usp">
                        Free delivery & returns
                    </Trans>
                </MiniMenuFooter>
            </StyledMiniMenu>
        )
    },
)

export default MiniCart
