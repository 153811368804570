import { Trans } from '@lingui/macro'

import styles from './MiniCartItemRowTotal.module.scss'
import { CurrencyEnum } from '../graphql/schema.generated'
import PriceBox from '../presentation/PriceBox'
import { ProductCardFragment } from '../ProductCardFragment'
import { QuoteDataTotalsItemInterface } from '../types'

interface Props {
    totals: Pick<QuoteDataTotalsItemInterface, 'qty' | 'row_total_incl_tax'>
    product: ProductCardFragment
    currency: CurrencyEnum
}

const MiniCartItemRowTotal = ({ totals, product, currency }: Props) => {
    const { regularPrice, discount } = product.priceRange.maximumPrice
    const actualPrice = totals.row_total_incl_tax || 0
    const originalPrice = regularPrice.value * totals.qty
    const hasDiscount = discount?.percentOff ? discount.percentOff > 0 : false
    const discountPercent = Math.round(discount?.percentOff || 0)

    return (
        <div className={styles.totals}>
            <PriceBox
                oldPrice={
                    hasDiscount
                        ? {
                              value: originalPrice,
                              currency,
                          }
                        : undefined
                }
                regularPrice={
                    !hasDiscount
                        ? {
                              value: actualPrice,
                              currency,
                          }
                        : undefined
                }
                specialPrice={
                    hasDiscount
                        ? {
                              value: actualPrice,
                              currency,
                          }
                        : undefined
                }
                className={styles.priceContainer}
                priceClassName={styles.price}
                outletAdviceprice={product.outletAdviesprijs ?? null}
            />
            {hasDiscount && (
                <div className={styles.discount}>
                    <Trans id="cart.miniCart.discount">
                        You save {discountPercent}%
                    </Trans>
                </div>
            )}
        </div>
    )
}

export default MiniCartItemRowTotal
