import { defaultDataIdFromObject, IdGetter, StoreObject } from '@apollo/client'
import {
    TweakwiseFunnel,
    TweakwiseFunnelItem,
} from '@emico-hooks/graphql-schema-types'

import { CustomerCoupon } from '../../account-dashboard-page/AccountDashboardPageLayout/justRewards/Vouchers/customerVouchers.query'
import {
    ConfigurableAttributeOption,
    CoreConfigData,
    EntityUrl,
    SelectedConfigurableOption,
    WishlistItem,
} from '../../graphql/schema.generated'
import { ProductCardFragment } from '../../ProductCardFragment'

export const PRODUCT = 'Product'

const dataIdFromObject: IdGetter = (object) => {
    switch (object.__typename) {
        case 'CoreConfigData': {
            const coreConfigData = object as unknown as CoreConfigData

            return `${object.__typename}:${coreConfigData.code}`
        }
        case 'StoreConfig':
            return `StoreConfig:1`
        case PRODUCT: // There is no GraphQL entity with this typename, this is used inside the app for programmatical resolutions
        case 'BundleProduct':
        case 'ConfigurableProduct':
        case 'SimpleProduct':
        case 'GroupedProduct':
        case 'VirtualProduct': {
            const product = object as unknown as ProductCardFragment

            return `Product:${product.id}`
        }
        case 'EntityUrl': {
            const entityUrl = object as unknown as EntityUrl

            return `${object.__typename}:${entityUrl.type}:${entityUrl.id}:${
                entityUrl.relativeUrl
            }:${entityUrl.prismicType}:${entityUrl.redirectCode}:${
                entityUrl.product?.id ?? 'noproduct'
            }}`
        }
        case 'CustomerCoupon': {
            const customerCoupon = object as unknown as CustomerCoupon

            return `${object.__typename}:${customerCoupon.code}`
        }
        case 'BundleWishlistItem':
        case 'GroupedProductWishlistItem':
        case 'DownloadableWishlistItem':
        case 'VirtualWishlistItem':
        case 'SimpleWishlistItem':
        case 'ConfigurableWishlistItem': {
            const wishlistItem = object as unknown as WishlistItem

            return `WishlistItem:${wishlistItem.product?.id ?? 'N/A'}${
                wishlistItem.id
            }`
        }
        case 'Customer':
            return `${object.__typename}`
        case 'SelectedConfigurableOption': {
            const selectedConfigurableOption =
                object as unknown as SelectedConfigurableOption

            return `${object.__typename}:${selectedConfigurableOption.id}:${selectedConfigurableOption.valueId}`
        }
        case 'ConfigurableAttributeOption': {
            const data = object as unknown as ConfigurableAttributeOption

            return `${object.__typename}:${data.uid}`
        }
        case 'TweakwiseFunnel': {
            const data = object as unknown as TweakwiseFunnel

            return `${object.__typename}:${data.code}`
        }
        case 'TweakwiseFunnelItem': {
            const data = object as unknown as TweakwiseFunnelItem

            return `${object.__typename}:${data.itemNo}`
        }
        default:
            return defaultDataIdFromObject(object as Readonly<StoreObject>)
    }
}

export default dataIdFromObject
