import { makeVar, useApolloClient } from '@apollo/client'
import { useCreateCustomer } from '@emico-hooks/customer'
import { useLogin } from '@emico-hooks/login'
import {
    ExceptionCategories,
    getMagentoErrorCategory,
} from '@emico-utils/magento-graphql-error-categories'
import { t } from '@lingui/macro'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
    clearCartId,
    getCartDetails,
    getCartId,
    saveCartId,
    setShippingInformation,
    setToken,
    ShippingInfo,
} from './actions'
import { mergeCart } from './cart/mergeCart.mutation'
import { getShopIdCookies } from './layout/Header/TopBar/ShopIdTopBarBanner'
import { CustomerDataCustomerInterface, useDispatch } from './types'

export const LoginErrors = {
    invalidCredentials:
        'Your password is incorrect. Please wait and try again later.',
    invalidCredentialsDE:
        'Ihr Passwort is nicht korrekt. Versuchen Sie es nochmal.',
    invalidCredentialsNL: 'Jouw wachtwoord is onjuist. Probeer het nogmaals.',
    unknownAccount:
        'This account is not known to us. Please create a new account.',
    unknownAccountDE:
        'Dieser Account ist uns nicht bekannt. Bitte erstellen Sie ein neues Konto.',
    unknownAccountNL:
        'Dit account is niet bij ons bekend. Maak een nieuw account aan.',
    customerInactive: 'This account is not activated, please check your mail.',
    customerInactiveDE:
        'Dieser Account is noch nicht aktiviert. Bitte überprüfe deine Email.',
    customerInactiveNL:
        'Dit account is nog niet geactiveerd, controleer je e-mail.',
}

export interface LoginFormValues {
    username: string
    password: string
    customer?:
        | Pick<CustomerDataCustomerInterface, 'firstname' | 'lastname' | 'dob'>
        | undefined

    shippingInfo?: ShippingInfo
}

interface LoginFormHookOptions {
    /**
     * @deprecated This data is no longer pushed in new version of the login hook
     */
    pushEventData?: {
        formName: string
        formID: string
        formStep?: string
        formStepName?: string
    }
    /**
     * @deprecated This data is no longer pushed in new version of the login hook
     */
    createAccountPushEventData?: {
        formName: string
        formID: string
        formStep?: string
        formStepName?: string
    }
    redirect?: string
}

export const isLoggingIn = makeVar(false)

export function useLoginFormV2(options?: LoginFormHookOptions) {
    const { redirect } = options ?? {}
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>(undefined)

    const login = useLogin({ useCartHooks: false })
    const createCustomer = useCreateCustomer()

    const history = useHistory()

    const dispatch = useDispatch()
    const client = useApolloClient()

    const handleSubmit = useCallback(
        async ({
            username,
            password,
            customer,
            shippingInfo,
        }: LoginFormValues) => {
            setIsLoading(true)
            isLoggingIn(true)
            try {
                const cartId = getCartId()
                const { shopId, customerShopId } = getShopIdCookies()

                if (customer) {
                    await createCustomer({
                        email: username,
                        password,
                        firstname: customer.firstname,
                        lastname: customer.lastname,
                        dob: customer.dob,
                        srs_shop_id: shopId ?? customerShopId,
                    })
                }
                const { result } = await login(username, password)

                if (!result.generateCustomerToken?.token) {
                    throw new Error(
                        t({
                            id: 'loginForm.loginFailed',
                            message: `Login failed, please try again`,
                        }),
                    )
                }

                const token = result.generateCustomerToken.token

                setToken(token)

                let newCartId: string | null = null

                if (cartId) {
                    const result = await mergeCart(client)(cartId, token)
                        .catch(() => mergeCart(client)(cartId, token))
                        .catch(() => {
                            clearCartId()
                        })

                    if (result?.data?.id) {
                        saveCartId(result.data.id)
                        newCartId = result.data.id
                    }
                }

                await new Promise((resolve) => setTimeout(resolve, 200))
                // If a guest user enters the checkout, enters his data, and wants to log in,
                // we want the entered data to be used in the cart and for the order.
                //
                // Because the customer is logged in, the possibility exists that he has an active cart,
                // or a default address. By logging in, the cart data is replaced with the user data.
                //
                // By providing it here, we can better control the info that is set on the cart
                setToken(token)
                if (shippingInfo) {
                    await dispatch(setShippingInformation(shippingInfo))
                } else {
                    await dispatch(
                        getCartDetails({
                            forceRefresh: true,
                            cartId: newCartId ?? undefined,
                        }),
                    )
                }

                // Clear client cache, so we 'enforce' getting fresh data.
                await client.stop()
                await client.clearStore()

                if (redirect) {
                    history.push(redirect)
                }
                setIsSuccess(true)
            } catch (e) {
                const category = getMagentoErrorCategory(e)

                if (e instanceof Error) {
                    setError(e.message)
                }

                if (category !== ExceptionCategories.AUTHENTICATION) {
                    throw e
                }
            } finally {
                setIsLoading(false)
                isLoggingIn(false)
            }
        },
        [login, client, redirect, createCustomer, dispatch, history],
    )

    return {
        isLoading,
        error,
        isSuccess,
        handleSubmit,
    }
}

export const useLoginForm = useLoginFormV2
