import { makeVar, useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import {
    Control,
    Controller,
    FieldValues,
    Path,
    UseControllerProps,
} from 'react-hook-form'

import {
    CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
    CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE,
    shouldCombineWidthAndLength,
} from './helpers'
import ConfigurableAttributeCombinedWidthAndLength from '../ConfigurableAttributeCombinedWidthAndLength'
import ConfigurableAttributeDropdown from '../ConfigurableAttributeDropdown'
import ConfigurableAttributeTiles from '../ConfigurableAttributeTiles'
import { ConfigurableProduct } from '../ConfigurableProduct'
import { Product } from '../GetProduct.query'
import {
    decodeAttributeValueObject,
    encodeAttributeValueObject,
} from '../StickyCta/flattenCombinedAttributes'
import { getProductInitialValue } from '../StickyCta/ProductInfoFormMinimal'

export interface ConfigurableAttributesFieldValue {
    [optionId: number]: number
}

interface ControlProps {
    product: ConfigurableProduct
    onChange(key: ConfigurableAttributesFieldValue): void
    className?: string
}

export const selectedValue = makeVar<{
    map: WeakMap<
        Product | ConfigurableProduct,
        ConfigurableAttributesFieldValue
    >
}>({ map: new WeakMap() })

export function setSelectedConfigurableValue(
    product: Product | ConfigurableProduct,
    value: ConfigurableAttributesFieldValue,
) {
    const map = selectedValue().map

    map.set(product, value)
    selectedValue({ map })
}

export function useSelectedConfigurableValue(
    product: Product | ConfigurableProduct,
) {
    const value = useReactiveVar(selectedValue)

    return value.map.get(product)
}

interface Props<
    // eslint-disable-next-line @typescript-eslint/ban-types
    T extends object,
    TName extends Path<T>,
> extends ControlProps {
    rules?: UseControllerProps<T, TName>['rules']
    name: TName
    control: Control<T, TName>
}

const ConfigurableAttributesField2 = <
    T extends TFieldValues,
    TName extends Path<T>,
    TFieldValues extends FieldValues = FieldValues,
>({
    control,
    name,
    rules,
    product,
    onChange: onChangeCallback,
    ...props
}: Props<T, TName>) => {
    let singleSizeValue

    const isSingleSizeProduct =
        product.configurableOptions.length === 1 &&
        product.configurableOptions?.[0]?.values.length === 1

    if (isSingleSizeProduct) {
        const attributeId = product.configurableOptions[0].attributeId
        const valueId = product.configurableOptions[0].values[0].valueIndex

        singleSizeValue = encodeAttributeValueObject(
            getProductInitialValue(product, {
                [attributeId]: valueId,
            }),
        )
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            defaultValue={singleSizeValue as any}
            render={({ field: { onChange, onBlur, value } }) => {
                // This is a component, however, this could be optimised
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const selectedValueVar = useSelectedConfigurableValue(product)

                // This is a component

                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                    if (selectedValueVar) {
                        onChange(encodeAttributeValueObject(selectedValueVar))
                    }
                }, [onChange, selectedValueVar])

                const decodedValue =
                    decodeAttributeValueObject<ConfigurableAttributesFieldValue>(
                        value,
                    )

                return (
                    <div {...props}>
                        {product.configurableOptions.map((attribute) => {
                            const { attributeId: id, attributeCode: code } =
                                attribute

                            if (
                                code ===
                                    CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE &&
                                shouldCombineWidthAndLength(product)
                            ) {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                const lengthAttribute =
                                    product.configurableOptions.find(
                                        (attribute) =>
                                            attribute.attributeCode ===
                                            CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE,
                                    )!

                                const widthValueId = decodedValue?.[Number(id)]
                                const lengthValueId =
                                    decodedValue?.[
                                        Number(lengthAttribute.attributeId)
                                    ]

                                return (
                                    <ConfigurableAttributeCombinedWidthAndLength
                                        key={id}
                                        product={product}
                                        onBlur={onBlur}
                                        onChange={(
                                            widthValueId?: number,
                                            lengthValueId?: number,
                                        ) => {
                                            const newValue = {
                                                ...decodedValue,
                                                [id]: widthValueId,
                                                [lengthAttribute.attributeId]:
                                                    lengthValueId,
                                            }

                                            onChange(
                                                encodeAttributeValueObject(
                                                    newValue,
                                                ),
                                            )

                                            if (onChangeCallback) {
                                                onChangeCallback(newValue)
                                            }
                                        }}
                                        value={{
                                            widthValueId,
                                            lengthValueId,
                                        }}
                                    />
                                )
                            } else if (
                                code ===
                                    CONFIGURABLE_OPTION_LENGTH_ATTRIBUTE_CODE &&
                                shouldCombineWidthAndLength(product)
                            ) {
                                // handled by combined attribute
                                return null
                            }

                            // Single attribute onChange handler
                            const handleChange = (valueId?: number) => {
                                const newValue = {
                                    ...decodedValue,
                                    [id]: valueId,
                                }

                                onChange(encodeAttributeValueObject(newValue))

                                if (onChangeCallback) {
                                    onChangeCallback(newValue)
                                }
                            }

                            if (
                                code ===
                                CONFIGURABLE_OPTION_WIDTH_ATTRIBUTE_CODE
                            ) {
                                return (
                                    <ConfigurableAttributeTiles
                                        key={id}
                                        attribute={attribute}
                                        product={product}
                                        onChange={handleChange}
                                        value={decodedValue[Number(id)]}
                                    />
                                )
                            }

                            return (
                                <ConfigurableAttributeDropdown
                                    key={id}
                                    attribute={attribute}
                                    product={product}
                                    onChange={handleChange}
                                    value={decodedValue[Number(id)]}
                                />
                            )
                        })}
                    </div>
                )
            }}
        />
    )
}

export default ConfigurableAttributesField2
