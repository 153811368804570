import { useReactiveVar } from '@apollo/client'
import styled from '@emotion/styled'
import { ReactNode, forwardRef, useEffect } from 'react'

import { CloseIcon } from '@emico/icons'
import { minWidth } from '@emico/styles'

import cartActions from './actions/cart'
import Button from './input/Button'
import { headerSizeVar } from './layout/Header/HeaderContainer'
import Separator from './layout/Separator'
import theme from './theme'
import { useDispatch } from './types'
import Heading from './typography/Heading'

type ContainerProps = React.HTMLAttributes<HTMLElement> &
    React.RefAttributes<HTMLElement> & {
        className?: string
        heading: React.ReactNode
        children: React.ReactNode
        onClose?(): void
    }

// We don't want the modal to fill the whole screen.
// We want to leave some space for PDP CTA and space to click so
// modal disapears automatically
const bottomOffset = 80

const Aside = styled.aside<{ headerHeight?: number }>`
    background: ${theme.colors.background};
    box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.1);
    color: ${theme.colors.text};
    display: flex;
    flex-flow: column nowrap;
    max-height: calc(
        100vh -
            ${(props) =>
                props.headerHeight
                    ? `${props.headerHeight + bottomOffset}px`
                    : '180px'}
    );

    @media ${minWidth('md')} {
        &::before {
            position: absolute;
            content: '';
            border-width: 8px;
            border-style: solid;
            border-color: transparent;
            border-bottom-color: ${theme.colors.background};
            width: 0;
            height: 0;
            right: 28px;
            bottom: 100%;
        }
    }
`

const Header = styled.header`
    padding: 23px 28px 9px;
    border-bottom-color: ${theme.colors.borderLight};
`

export const MiniMenuFooter = styled.footer<{ centerText?: boolean }>`
    padding: 12px 28px;
    background: ${theme.colors.grayLighter};
    color: ${theme.colors.text};

    ${(props) => props.centerText && 'text-align: center;'}
`

const CloseButton = styled(Button)`
    position: absolute;
    top: 14px;
    right: 28px;
`

export const MiniMenuContent = styled.div`
    padding: 10px 28px;
    overflow-y: auto;
    flex: 1;
`

export const MiniMenuActions = styled.div`
    display: flex;
    padding: 12px 28px 11px;
    flex-direction: column;
    gap: 10px;

    > * {
        text-align: center;
    }
`

const StyledHeading = styled(Heading)`
    white-space: normal;
    max-width: 90%;
    font-size: 16px;
`

export const MiniMenuHeading = ({ children }: { children: ReactNode }) => (
    <StyledHeading element="h1" variant="h4">
        {children}
    </StyledHeading>
)

// eslint-disable-next-line react/display-name
const MiniMenu = forwardRef<HTMLElement, ContainerProps>(
    (
        { className, heading, children, onClose, ...others }: ContainerProps,
        ref,
    ) => {
        const headerSize = useReactiveVar(headerSizeVar)
        const headerHeight = headerSize?.height ?? 0
        const dispatch = useDispatch()

        useEffect(
            () => () => {
                dispatch(cartActions.miniCart.close())
            },
            [dispatch],
        )

        return (
            <Aside
                className={className}
                ref={ref}
                headerHeight={headerHeight}
                {...others}
            >
                <Header>
                    <MiniMenuHeading>{heading}</MiniMenuHeading>
                    <CloseButton
                        onClick={() => {
                            if (onClose) {
                                onClose()
                            }
                            dispatch(cartActions.miniCart.close())
                        }}
                        name=""
                        category=""
                        variant="link"
                    >
                        <CloseIcon />
                    </CloseButton>
                </Header>
                <Separator />
                {children}
            </Aside>
        )
    },
)

export default MiniMenu
