import {
    ApolloClient,
    ApolloQueryResult,
    gql,
    QueryHookOptions,
    useQuery,
} from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'
import React, { useMemo } from 'react'

import {
    CoreConfigData,
    CoreConfigResult,
    CountryCodeEnum,
    Maybe,
} from './graphql/schema.generated'

const query = gql`
    query getCoreConfig {
        coreConfig {
            data {
                code
                value
            }
        }
    }
`

interface GetCoreConfigData {
    coreConfig: Maybe<CoreConfigResult>
    __typename?: 'Query'
}
export interface CoreConfigObject {
    [key: string]: string
}

export const useCoreConfig = (
    options?: QueryHookOptions<GetCoreConfigData>,
): {
    data?: CoreConfigData[]
    loading: boolean
    refetch(): Promise<ApolloQueryResult<GetCoreConfigData>>
} => {
    const { data, error, loading, refetch } = useQuery<GetCoreConfigData>(
        query,
        {
            ...options,
            context: getCacheableContext(),
        },
    )

    if (error) {
        throw error
    }

    const hasData = data?.coreConfig?.data

    if (!loading && !hasData) {
        throw new Error('Core config is missing')
    }

    return {
        data: hasData
            ? (data?.coreConfig?.data as CoreConfigData[])
            : undefined,
        loading,
        refetch,
    }
}
export const useCoreConfigObject = (): {
    data?: CoreConfigObject
    loading: boolean
} => {
    const { data, loading } = useCoreConfig()

    const obj = useMemo(
        () =>
            data &&
            data.reduce<CoreConfigObject>((obj, item) => {
                if (item.code === null || item.value === null) {
                    return obj
                }
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                obj[item.code] = item.value!
                return obj
            }, {}),
        [data],
    )

    return {
        data: obj,
        loading,
    }
}

export const useCoreConfigValue = (
    key: string,
    /** @deprecated This is no longer used
     * {boolean} Wether the key is required. If a required config entry is missing an exception will be thrown. */
    required: boolean = false,
    options?: QueryHookOptions,
): {
    value: string | undefined
    loading: boolean
} => {
    const { data, loading } = useCoreConfig(options)
    const value = useMemo(() => {
        if (!data) {
            return null
        }

        return data.find((config) => config.code === key)
    }, [data, key])

    return {
        value: value?.value ?? undefined,
        loading,
    }
}

export const useAllowedCountriesForPickupPoint = () => {
    const { availableCountries, loading } = useAvailableCountries()

    const { value: allowSpecific, loading: loadingSpecific } =
        useCoreConfigValue(
            'carriers/dhlparcel/shipping_methods/servicepoint/sallowspecific',
            true,
        )

    const { value: specificCountries, loading: loadingSpecificCountries } =
        useCoreConfigValue(
            'carriers/dhlparcel/shipping_methods/servicepoint/specificcountry',
            true,
        )

    const allowedCountries =
        allowSpecific === '1' && specificCountries
            ? (specificCountries.split(',') as CountryCodeEnum[])
            : availableCountries

    return {
        allowedCountries,
        loading: loadingSpecific || loadingSpecificCountries || loading,
    }
}

export const useAvailableCountries = () => {
    const { value: countriesString, loading } = useCoreConfigValue(
        'general/country/allow',
        true,
    )

    const availableCountries = countriesString
        ? (countriesString.split(',') as CountryCodeEnum[])
        : undefined

    return { availableCountries, loading }
}
export const useDefaultCountry = () => {
    const { value, loading } = useCoreConfigValue(
        'general/country/default',
        true,
    )

    return { defaultCountry: value as CountryCodeEnum | undefined, loading }
}

// TODO: Remove
export const getCoreConfig = (client: ApolloClient<unknown>) => async () =>
    client.query<GetCoreConfigData>({
        query,
        context: getCacheableContext(),
    })
