import { getCartId } from '../actions'
import { useSelector } from '../types'

function useCartItemsAmount() {
    const { totals } = useSelector((state) => state.cart)
    const hasCart = Boolean(getCartId())

    return hasCart && totals?.items_qty ? totals.items_qty : 0
}

export default useCartItemsAmount
