import { useReactiveVar } from '@apollo/client'
import * as React from 'react'

import styles from './MobileNavigation.module.scss'
import { SubNav } from '../../../navigation/SubNav'
import Portal from '../../../Portal'
import FadeTransition from '../../FadeTransition'
import { headerSizeVar } from '../HeaderContainer'
import useMenuItems from '../useMenuItems'

interface Props {
    close(): void
    open?: boolean
}

const MobileNavigation = ({ close, open }: Props) => {
    const headerSize = useReactiveVar(headerSizeVar)
    const menuItems = useMenuItems()

    const closeMenu = () => {
        close()
    }

    if (!menuItems || menuItems.length === 0) {
        return null
    }

    return (
        <>
            <nav className={styles.hidden}>
                <SubNav
                    items={menuItems}
                    onItemClick={closeMenu}
                    extendedMenu
                />
            </nav>

            <Portal>
                <FadeTransition>
                    {open && (
                        <div
                            className={styles.modal}
                            style={{
                                top: headerSize?.height || 0,
                            }}
                        >
                            <SubNav
                                items={menuItems}
                                onItemClick={closeMenu}
                                className={styles.subNav}
                                extendedMenu
                            />
                        </div>
                    )}
                </FadeTransition>
            </Portal>
        </>
    )
}

export default MobileNavigation
