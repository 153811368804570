import { Image } from '@emico-react/image'
import styled from '@emotion/styled'
import { useCallback, useEffect, useRef, useState } from 'react'

import { minWidth } from '@emico/styles'
import { useBreakpoints } from '@emico/ui'

import { MiniCartUspIcon } from './cart/MiniCart'
import CheckIcon from './core/CheckIcon'
import HTMLContent from './HtmlContent'
import Button from './input/Button'
import HeightTransition from './layout/HeightTransition/HeightTransition'
import MiniMenu, {
    MiniMenuActions,
    MiniMenuContent,
    MiniMenuFooter,
} from './MiniMenu'
import { BlueConicTooltipInteractionData } from './types/BlueConic'

const TooltipMiniMenu = styled(MiniMenu)`
    position: absolute;
    right: 0;
    width: 100%;
    top: 100%;
    padding-bottom: 0;

    @media ${minWidth('md')} {
        right: -18px;
        width: 375px;
    }
`

const StyledImage = styled(Image)`
    margin-top: 20px;
    display: block;
    width: 100%;
`

const TooltipBlueConic = ({
    title,
    content,
    buttonText,
    buttonUrl,
    button2Text,
    button2Url,
    uspText,
    imageUrl,
    mobileImageUrl,
    displayDuration,
    interactionId,
    ...htmlProps
}: BlueConicTooltipInteractionData & React.HTMLAttributes<HTMLDivElement>) => {
    const { isMobile } = useBreakpoints()
    const ref = useRef<HTMLElement | null>(null)
    const [isVisible, setVisibility] = useState<boolean>(true)
    const timer = useRef<number>()

    useEffect(() => {
        timer.current = setTimeout(() => {
            setVisibility(false)
        }, displayDuration)

        return () => {
            if (timer.current) {
                clearTimeout(timer.current)
            }
        }
    }, [displayDuration])

    const handleOutsideClick = useCallback(
        (e: Event) => {
            if (!ref.current?.contains(e.target as Node)) {
                setVisibility(false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ref.current],
    )

    useEffect(() => {
        setVisibility(true)
    }, [interactionId])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick)
        return () => document.removeEventListener('click', handleOutsideClick)
    })

    const imageSrc = isMobile ? mobileImageUrl : imageUrl

    return (
        <HeightTransition>
            {isVisible && title && content && (
                <TooltipMiniMenu
                    {...htmlProps}
                    ref={ref}
                    onClose={() => setVisibility(false)}
                    heading={title}
                >
                    <MiniMenuContent>
                        <HTMLContent html={content} />

                        {imageSrc && (
                            <StyledImage
                                url={imageSrc}
                                width={768}
                                params={{
                                    resizingType: 'fill-down',
                                }}
                                alt=""
                                lazy
                                onError={(e) =>
                                    e.currentTarget.style.display === 'none'
                                }
                            />
                        )}
                    </MiniMenuContent>
                    {((buttonText && buttonUrl) ||
                        (button2Text && button2Url)) && (
                        <MiniMenuActions>
                            {buttonText && buttonUrl && (
                                <Button
                                    to={buttonUrl}
                                    variant="primary"
                                    name="tooltip"
                                    category="blueconic"
                                    onClick={() => {
                                        window.blueConicClient?.createEvent(
                                            'CLICK',
                                            interactionId,
                                        )
                                    }}
                                >
                                    {buttonText}
                                </Button>
                            )}
                            {button2Text && button2Url && (
                                <Button
                                    to={button2Url}
                                    variant="secondary"
                                    name="tooltip"
                                    category="blueconic"
                                    onClick={() => {
                                        window.blueConicClient?.createEvent(
                                            'CLICK',
                                            interactionId,
                                        )
                                    }}
                                >
                                    {button2Text}
                                </Button>
                            )}
                        </MiniMenuActions>
                    )}
                    {uspText && (
                        <MiniMenuFooter centerText>
                            <MiniCartUspIcon component={CheckIcon} title="" />
                            {uspText}
                        </MiniMenuFooter>
                    )}
                </TooltipMiniMenu>
            )}
        </HeightTransition>
    )
}

export default TooltipBlueConic
