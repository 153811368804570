import styled from '@emotion/styled'
import * as React from 'react'

import { useBreakpoints } from '@emico/ui'

import Accordion from './Accordion'
import { LayoutStyle } from './LayoutStyle'
import Link from './navigation/Link'
import Heading from './typography/Heading'

export interface Item {
    title: string
    url: string
}

export interface ItemGroup {
    title: string
    items: Item[]
}

interface Props {
    items: ItemGroup[]
    style?: LayoutStyle
    heading?: string
    gridClassName?: string
    gridItemClassName?: string
}

const GridHeading = styled(Heading)`
    text-align: center;
    margin-bottom: 1em;
`

const GridItemHeading = styled(Heading)`
    margin-bottom: 0.25em;
`

const Grid = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 20px;
`

const GridItem = styled.div`
    flex: 0 1 auto;
    max-width: 300px;
    padding: 0 40px;
`

const List = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
`

const ListItem = styled.li`
    padding: 0;
    padding-top: 10px;
    list-style: none;

    &:last-of-type {
        padding-bottom: 10px;
    }
`

const LinkList = ({
    style = 'light',
    heading,
    items,
    gridClassName,
    gridItemClassName,
}: Props) => {
    const { isMobile } = useBreakpoints()

    if (items.length === 0) {
        return null
    }

    const renderListWithLinks = (items: Item[]) => (
        <List>
            {items.map(({ title, url }) => {
                if (title === 'empty' && url === 'empty') {
                    return (
                        <ListItem key={`link-list-sub-${title}`}>
                            &nbsp;
                        </ListItem>
                    )
                }

                return (
                    <ListItem key={`link-list-sub-${title}`}>
                        <Link
                            name={title}
                            category="link-list"
                            to={`${url}`}
                            title={title}
                            variant={style === 'light' ? 'dark' : 'light'}
                        >
                            {title}
                        </Link>
                    </ListItem>
                )
            })}
        </List>
    )

    return (
        // eslint-disable-next-line react/no-unknown-property
        <>
            {heading && (
                <GridHeading variant="h2" element="h2">
                    {heading}
                </GridHeading>
            )}
            {isMobile ? (
                <Accordion
                    items={items.map(({ title, items }) => ({
                        heading: title,
                        content: renderListWithLinks(items),
                    }))}
                />
            ) : (
                <Grid className={gridClassName}>
                    {items.map(({ title, items: subItems }) => (
                        <GridItem
                            className={gridItemClassName}
                            key={`link-list-item-${title}`}
                        >
                            <GridItemHeading variant="h3" element="h3">
                                {title}
                            </GridItemHeading>
                            {renderListWithLinks(subItems)}
                        </GridItem>
                    ))}
                </Grid>
            )}
        </>
    )
}

export default LinkList
