/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// Based on: https://github.com/magento-research/pwa-studio/blob/develop/packages/venia-concept/src/actions/checkout/asyncActions.js

import { PaymentMethodsResponse } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types'
import { PaymentAction } from '@adyen/adyen-web/dist/types/types'

import actions from './actions'
import { updateAddressTelephone } from '../../customerAddress/updateAddress.query'
import { CountryCodeEnum } from '../../graphql/schema.generated'
import { CartState } from '../../reducers'
import {
    Action,
    Address,
    CheckoutDataPaymentDetailsInterface,
    CheckoutGuestPaymentInformationManagementV1SavePaymentInformationAndPlaceOrderPostBody,
    CheckoutGuestShippingInformationManagementV1SaveAddressInformationPostBody,
    QuoteDataAddressInterface,
    QuoteDataShippingMethodInterface,
    QuoteGuestShipmentEstimationV1EstimateByExtendedAddressPostBody,
    serializeShippingMethod,
    ShippingMethod,
    ThunkDispatch,
    ThunkResult,
} from '../../types'
import poll from '../../utils/poll'
import { M2ApiResponseError } from '../../utils/RestApi'
import cartActions, {
    clearCartId,
    createCart,
    getCartId,
    removeCart,
    restoreCart,
} from '../cart'
import { getToken, isSignedIn } from '../user'

interface AdyenMagentoPaymentMethodBody {
    method: string
    additional_data?: { [key: string]: any }
}

export interface AdyenMagentoPOSTerminal {
    terminal_id: string
    label: string
}

interface PaymentMethodsExtraDetailsObject {
    [key: string]: {
        configuration?: {
            amount: {
                currency: string
                value: number
            }
            currency: string
        }
        icon?: {
            height: number
            url: string
            width: number
        }
        isOpenInvoice?: boolean
    }
}

export interface ShippingInfo {
    shippingAddress: Address
    shippingMethod: ShippingMethod
    billingAddressSameAsShippingAddress: boolean
    billingAddress?: Address
    email?: string
    phone?: string
    pickUpPoint?: [CountryCodeEnum, string]
}

export interface AdyenPaymentMethodsResponse {
    paymentMethodsResponse: PaymentMethodsResponse
    paymentMethodsExtraDetails: PaymentMethodsExtraDetailsObject
}

export enum AdyenPaymentStatusResultCode {
    Authorised = 'Authorised',
    Received = 'Received',
    PresentToShopper = 'PresentToShopper',
    Refused = 'Refused',
    Error = 'Error',
    Pending = 'Pending',
    Cancelled = 'Cancelled',
    Unhandled = 'unhandled',
}

export interface AdyenState {
    brand?: string
    isValid: boolean
    data: {
        browserInfo?: any
        clientStateDataIndicator?: boolean
        riskData: {
            clientData: string
        }
        paymentMethod: {
            type: string
        }
    }
}

export interface AdyenPaymentStatus {
    orderId: number
    isFinal: boolean
    resultCode: AdyenPaymentStatusResultCode
    refusalReason?: string
    action?: PaymentAction
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AdyenPaymentDetails {}

export const getShippingMethods = (
    country: CountryCodeEnum,
    postalCode?: string,
    street?: string,
    houseNumber?: number,
    houseNumberAddition?: string,
): ThunkResult<Promise<QuoteDataShippingMethodInterface[]>> =>
    async function thunk(
        dispatch,
        getState,
        services,
    ): Promise<QuoteDataShippingMethodInterface[]> {
        const { request } = services
        const cartId = getCartId()

        try {
            // if there isn't a guest cart, create one
            // then retry this operation
            if (!cartId) {
                // await dispatch(createCart())
                // return thunk(dispatch, getState, services)
                throw new Error(
                    'Unable to fetch shipping methods when cart is unavailable',
                )
            }

            const guestEndpoint = `/rest/V1/guest-carts/${cartId}/estimate-shipping-methods`
            const authedEndpoint =
                '/rest/V1/carts/mine/estimate-shipping-methods'
            const endpoint = isSignedIn() ? authedEndpoint : guestEndpoint

            let addressLines

            if (street) {
                addressLines = []
                addressLines.push(street)
                addressLines.push(`${houseNumber}`)
                addressLines.push(`${houseNumberAddition}`)
            }

            // We need to make sure we're only providing values that have a value.
            // If we supply f.e. an empty string for postcode,
            // this will result in the active cart having an empty postal code.
            const address: Partial<
                QuoteGuestShipmentEstimationV1EstimateByExtendedAddressPostBody['address']
            > = {}

            if (country) {
                address.country_id = country
            }
            if (postalCode) {
                address.postcode = postalCode
            }
            if (addressLines) {
                address.street = addressLines
            }

            const body: {
                address: Partial<
                    QuoteGuestShipmentEstimationV1EstimateByExtendedAddressPostBody['address']
                >
            } = {
                address,
            }

            const response: QuoteDataShippingMethodInterface[] = await request(
                endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                },
            )

            return response
        } catch (error) {
            const response =
                error instanceof M2ApiResponseError ? error.response : undefined

            // check if the guest cart has expired
            if (response && response.status === 404) {
                // if so, clear it out, get a new one, and retry.
                await clearCartId()
                await dispatch(createCart())
                return thunk(dispatch, getState, services)
            }
            throw error
        }
    }

export const setShippingInformation = ({
    shippingAddress,
    shippingMethod,
    billingAddressSameAsShippingAddress,
    billingAddress,
    email,
    phone,
    pickUpPoint,
}: {
    shippingAddress: Address
    shippingMethod: ShippingMethod
    billingAddressSameAsShippingAddress: boolean
    billingAddress?: Address
    email?: string
    phone?: string
    pickUpPoint?: [CountryCodeEnum, string]
}): ThunkResult<Promise<CheckoutDataPaymentDetailsInterface>> =>
    async function thunk(
        dispatch,
        getState,
        { request, apolloClient },
    ): Promise<CheckoutDataPaymentDetailsInterface> {
        const { cart } = getState()
        const cartId = getCartId()

        // region Validation
        if (!cartId) {
            throw new Error('Missing required information: cartId')
        }
        if (!cart.details) {
            throw new Error(
                'Missing cart details. Cart details should always be loaded before shipping-information is set',
            )
        }
        if (!billingAddressSameAsShippingAddress && !billingAddress) {
            throw new Error(
                'Billing address is required when billingAddressSameAsShippingAddress is false',
            )
        }
        billingAddress = billingAddress!
        // endregion

        // region Collect values
        const currentShippingAssignment =
            cart.details.extension_attributes &&
            cart.details.extension_attributes.shipping_assignments &&
            cart.details.extension_attributes.shipping_assignments[0]

        const currentShippingAddress =
            currentShippingAssignment &&
            currentShippingAssignment.shipping.address
        const currentBillingAddress = cart.details.billing_address

        const addressToRestApi = (
            address: Address,
        ): OptionalProp<
            QuoteDataAddressInterface,
            | 'region'
            | 'region_id'
            | 'region_code'
            | 'telephone'
            | 'email'
            | 'country_id'
        > => {
            const street = [
                address.street,
                String(address.houseNumber),
                address.suffix ?? '',
            ]

            if (address.suffix !== undefined && address.suffix !== null) {
                // Mimic server-side: when empty there's no entry in the array
                street.push(address.suffix)
            }
            return {
                id: undefined,
                customer_address_id: isSignedIn()
                    ? address.customerAddressId
                    : undefined,
                firstname: address.firstName,
                lastname: address.lastName,
                company: address.company,
                street,
                region: undefined,
                region_code: undefined,
                region_id: undefined,
                postcode: address.postalCode,
                city: address.city,
                country_id: address.country,
                same_as_billing: 0,
                // Save address in addressbook when it's a new address
                save_in_address_book:
                    isSignedIn() && !address.customerAddressId ? 1 : 0,
            }
        }

        const restShippingAddress = {
            // Re-use existing values to attempt to maintain as much data as possible
            ...(currentShippingAddress || {}),
            ...addressToRestApi(shippingAddress),
        }

        restShippingAddress.same_as_billing =
            billingAddressSameAsShippingAddress ? 1 : 0
        const restBillingAddress = {
            // Re-use existing values to attempt to maintain as much data as possible
            ...(currentBillingAddress || {}),
            ...addressToRestApi(
                billingAddressSameAsShippingAddress
                    ? shippingAddress
                    : billingAddress,
            ),
        }

        if (email !== undefined) {
            restShippingAddress.email = email
            restBillingAddress.email = email
        }
        if (phone !== undefined) {
            restShippingAddress.telephone = phone
            restBillingAddress.telephone = phone

            // When customer address id is set we need to update the phone number because it might have been changed
            // We only need to do this for signed in users because guest users don't have a customer address id
            if (isSignedIn()) {
                if (restShippingAddress.customer_address_id) {
                    await updateAddressTelephone(
                        apolloClient,
                        getToken(),
                        restShippingAddress.customer_address_id,
                        phone,
                    )
                }
                if (restBillingAddress.customer_address_id) {
                    await updateAddressTelephone(
                        apolloClient,
                        getToken(),
                        restBillingAddress.customer_address_id,
                        phone,
                    )
                }
            }
        }
        // endregion

        // region API call
        const shippingBody: CheckoutGuestShippingInformationManagementV1SaveAddressInformationPostBody =
            {
                addressInformation: {
                    // @ts-ignore
                    shipping_address: restShippingAddress,
                    shipping_carrier_code: shippingMethod.carrierCode,
                    shipping_method_code: shippingMethod.methodCode,
                    // @ts-ignore
                    billing_address: restBillingAddress,
                    custom_attributes:
                        pickUpPoint && pickUpPoint[1] && pickUpPoint[0]
                            ? [
                                  {
                                      attribute_code:
                                          'dhlparcel_shipping_servicepoint_country_id',
                                      value: pickUpPoint[0],
                                  },
                                  {
                                      attribute_code:
                                          'dhlparcel_shipping_servicepoint_id',
                                      value: pickUpPoint[1],
                                  },
                              ]
                            : undefined,
                },
            }

        // POST to shipping-information to submit the shipping address and shipping method.
        const guestShippingEndpoint = `/rest/V1/guest-carts/${cartId}/shipping-information`
        const authedShippingEndpoint =
            '/rest/V1/carts/mine/shipping-information'

        const shippingEndpoint = isSignedIn()
            ? authedShippingEndpoint
            : guestShippingEndpoint

        const paymentDetails: CheckoutDataPaymentDetailsInterface =
            await request(shippingEndpoint, {
                method: 'POST',
                body: JSON.stringify(shippingBody),
            })
        // endregion

        let pickupPointData

        if (pickUpPoint && pickUpPoint[1] && pickUpPoint[0]) {
            pickupPointData = {
                dhlparcel_shipping_servicepoint_country_id: pickUpPoint[0],
                dhlparcel_shipping_servicepoint_id: pickUpPoint[1],
            }
        }

        // region Update state
        // Typehint since redux-actions doesn't provide any useful typing
        const receive: (details: Partial<CartState>) => Action =
            cartActions.getDetails.receive

        dispatch(
            receive({
                // @ts-ignore
                details: {
                    ...cart.details,
                    // @ts-ignore
                    billing_address: restBillingAddress,
                    extension_attributes: {
                        ...cart.details.extension_attributes,
                        ...pickupPointData,
                        shipping_assignments: [
                            {
                                shipping: {
                                    // @ts-ignore
                                    address: restShippingAddress,
                                    method: serializeShippingMethod(
                                        shippingMethod,
                                    ),
                                },
                                // @ts-ignore
                                items: currentShippingAssignment
                                    ? currentShippingAssignment.items
                                    : undefined,
                            },
                        ],
                    },
                },
                paymentMethods: paymentDetails.payment_methods,
                totals: paymentDetails.totals,
            }),
        )
        // endregion

        return paymentDetails
    }

export const submitOrder = (
    paymentMethodCode: string,
    bankCode?: string,
): ThunkResult<Promise<string>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        { request },
    ): Promise<string> {
        const { cart } = getState()
        const cartId = getCartId()

        if (!cartId) {
            throw new Error('Missing required information: cartId')
        }

        const email =
            cart.details &&
            cart.details.extension_attributes &&
            cart.details.extension_attributes.shipping_assignments![0] &&
            cart.details.extension_attributes.shipping_assignments![0].shipping
                .address.email

        if (!email) {
            throw new Error('Email is missing from shipping address')
        }

        // POST to payment-information to submit the payment details and billing address,
        // Note: this endpoint also actually submits the order.
        const guestPaymentEndpoint = `/rest/V1/guest-carts/${cartId}/payment-information-pay`
        const authedPaymentEndpoint =
            '/rest/V1/carts/mine/payment-information-pay'

        const paymentEndpoint = isSignedIn()
            ? authedPaymentEndpoint
            : guestPaymentEndpoint

        const paymentBody: CheckoutGuestPaymentInformationManagementV1SavePaymentInformationAndPlaceOrderPostBody =
            {
                email,
                paymentMethod: {
                    method: paymentMethodCode,
                    additional_data: {
                        // @ts-ignore Venia does this so it should work? If we can JSON encode this, that would be nice:
                        iDeal_issuer_id: bankCode,
                    },
                },
            }

        const paymentInformation: {
            order_id: string
            // The URL to send a customer to to let Magento redirect them to
            // the proper gateway page.
            gateway_redirect_url: string
            // POST this as a FORM to directly send the customer to the proper
            // gateway page.
            gateway_redirect_form_action: string
            gateway_redirect_form: Array<{ field: string; value: string }>
        } = await request(paymentEndpoint, {
            method: 'POST',
            body: JSON.stringify(paymentBody),
        })

        if (!paymentInformation || !paymentInformation.order_id) {
            throw new Error(
                'Placing the order failed (no order id). Contact customer service.',
            )
        }
        if (!paymentInformation.gateway_redirect_url) {
            throw new Error(
                'Placing the order failed (no redirect url). Contact customer service.',
            )
        }

        // Clear out everything we've saved about this cart
        // TODO: Test if the app still works with this removed so we can allow
        //  the user to choose a different payment method
        await dispatch(removeCart())

        dispatch(
            actions.setGatewayInformation({
                redirectUrl: paymentInformation.gateway_redirect_url,
                redirectForm: {
                    action: paymentInformation.gateway_redirect_form_action,
                    fields: paymentInformation.gateway_redirect_form,
                },
            }),
        )

        return paymentInformation.order_id
    }

export const getAdyenPaymentMethods = (): ThunkResult<
    Promise<AdyenPaymentMethodsResponse>
> =>
    async function thunk(
        dispatch,
        getState,
        services,
    ): Promise<AdyenPaymentMethodsResponse> {
        const { request } = services
        const cartId = getCartId()

        if (!cartId) {
            throw new Error(
                'Unable to fetch adyen payment methods when cart is unavailable',
            )
        }

        const guestEndpoint = `/rest/V1/guest-carts/${cartId}/retrieve-adyen-payment-methods`
        const authedEndpoint =
            '/rest/V1/carts/mine/retrieve-adyen-payment-methods'
        const endpoint = isSignedIn() ? authedEndpoint : guestEndpoint

        const body = {
            cart_id: cartId,
        }

        const response: AdyenPaymentMethodsResponse = await request(endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => JSON.parse(response))
            .catch((error) => {
                const { response } = error

                if (response && response.status === 404) {
                    // No cart was found. Most likely, the cart has
                    dispatch(restoreCart()).then((isCartRestored) => {
                        if (isCartRestored) {
                            return thunk(dispatch, getState, services)
                        }
                        throw new Error(
                            'Unable to fetch adyen payment methods when cart is unavailable',
                        )
                    })
                }
                throw error
            })

        if (!response.paymentMethodsResponse) {
            throw new Error(
                'Invalid response while fetching adyen payment methods',
            )
        }

        return response
    }

export const createAdyenOrder = (
    paymentMethod: AdyenMagentoPaymentMethodBody,
): ThunkResult<Promise<{ message?: string; orderId?: number }>> =>
    async function thunk(dispatch: ThunkDispatch, getState, { request }) {
        const { cart } = getState()
        const cartId = getCartId()

        if (!cartId) {
            throw new Error('Missing required information: cartId')
        }

        const email =
            cart.details &&
            cart.details.extension_attributes &&
            cart.details.extension_attributes.shipping_assignments![0] &&
            cart.details.extension_attributes.shipping_assignments![0].shipping
                .address.email

        if (!email) {
            throw new Error('Email is missing from shipping address')
        }

        // POST to payment-information to submit the payment details,
        // Note: this endpoint also actually submits the order.
        const guestPaymentEndpoint = `/rest/V1/guest-carts/${cartId}/payment-information`
        const authedPaymentEndpoint = '/rest/V1/carts/mine/payment-information'
        const paymentEndpoint = isSignedIn()
            ? authedPaymentEndpoint
            : guestPaymentEndpoint

        const paymentBody = {
            email,
            paymentMethod,
        }

        // The payment endpoint can either return an order id (regular payments)
        // or it can return an object with a message (POS payments)
        const result = await request(paymentEndpoint, {
            method: 'POST',
            body: JSON.stringify(paymentBody),
        })
            .then((response) => JSON.parse(response))
            .catch((err) => {
                const message =
                    err instanceof M2ApiResponseError
                        ? err.userMessage
                        : err.message

                return {
                    message,
                    orderId: undefined,
                }
            })

        return {
            message: result?.message,
            orderId: typeof result === 'number' ? result : undefined,
        }
    }

export const submitAdyenOrder = (
    adyenState: AdyenState,
): ThunkResult<Promise<AdyenPaymentStatus>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        { request },
    ): Promise<AdyenPaymentStatus> {
        let paymentMethodType = adyenState.data?.paymentMethod?.type
        let additionalData: { [key: string]: any } = {
            brand_code: paymentMethodType,
        }

        switch (paymentMethodType) {
            case 'scheme':
                paymentMethodType = 'cc'
                additionalData = {
                    // @ts-ignore
                    cc_type: adyenState.brand,
                }
                break
        }

        const paymentMethod: AdyenMagentoPaymentMethodBody = {
            method: `adyen_${paymentMethodType}`,
            additional_data: {
                ...additionalData,
                // @ts-ignore
                stateData: JSON.stringify(adyenState.data),
            },
        }

        const { orderId, message } = await dispatch(
            createAdyenOrder(paymentMethod),
        )

        if (!orderId) {
            // Order was not created, do some error handling
            console.log('submutAdyenOrder', { message })
            throw new Error(
                message ?? `Failed to create order. Contact customer service.`,
            )
        }

        // Check adyen payment status
        // See https://docs.adyen.com/plugins/magento-2/magento-pwa-storefront#payment-status
        const paymentStatusEndpoint = `/rest/V1/adyen/orders/${orderId}/payment-status`
        const paymentStatus: Omit<AdyenPaymentStatus, 'orderId'> =
            await request(paymentStatusEndpoint, {
                method: 'GET',
            })
                .then((response) => JSON.parse(response))
                .catch(() => {
                    dispatch(restoreCart())
                })

        if (!paymentStatus) {
            throw new Error(
                `Failed to fetch payment status for order ${orderId}. Contact customer service.`,
            )
        }

        return { ...paymentStatus, orderId }
    }

export const getAdyenPaymentDetails = (
    adyenState: AdyenState,
    orderId: string,
): ThunkResult<Promise<AdyenPaymentDetails>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        { request },
    ): Promise<AdyenPaymentDetails> {
        const cartId = getCartId()

        if (!cartId) {
            throw new Error('Missing required information: cartId')
        }

        const guestEndpoint = `/rest/V1/adyen/guest-carts/${cartId}/payments-details`
        const authedEndpoint = `/rest/V1/adyen/carts/mine/payments-details`
        const endpoint = isSignedIn() ? authedEndpoint : guestEndpoint
        const paymentDetails = await request(endpoint, {
            method: 'POST',
            body: JSON.stringify({
                payload: JSON.stringify({ ...adyenState.data, orderId }),
                orderId,
            }),
        }).then((response) => JSON.parse(response))

        if (!paymentDetails) {
            throw new Error(
                `Failed to fetch payment details for order ${orderId}. Contact customer service.`,
            )
        }

        return paymentDetails
    }

export const initiatePOSPayment = (
    terminalId: string,
): ThunkResult<Promise<number | undefined>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        { request },
    ): Promise<number | undefined> {
        const cartId = getCartId()

        if (!cartId) {
            throw new Error('Missing required information: cartId')
        }

        const guestEndpoint = `/rest/V1/adyen/orders/guest-carts/${cartId}/pos-payment`
        const authedEndpoint = '/rest/V1/adyen/orders/carts/mine/pos-payment'
        const endpoint = isSignedIn() ? authedEndpoint : guestEndpoint

        await request(endpoint, {
            method: 'POST',
            body: JSON.stringify({
                payload: JSON.stringify({ terminal_id: terminalId }),
            }),
        }).catch((error) => {
            // Calling this endpoint could result in a 500 error.
            // This is normal. Just continue when this endpoint has resolved.
        })

        const result = await poll<{
            message?: string | undefined
            orderId?: number | undefined
        }>(
            () =>
                dispatch(
                    createAdyenOrder({
                        method: 'adyen_pos_cloud',
                        additional_data: {
                            terminal_id: terminalId,
                        },
                    }),
                ),
            (response) => {
                // Keep polling when in progress,
                // all other cases cease polling
                if (response?.message?.toLowerCase() === 'in progress') {
                    return false
                }

                return true
            },
            (error) => ({ message: error.message, orderId: undefined }),
            6000,
            20,
        ).catch((err) => ({ message: err.message, orderId: undefined }))

        if (result?.message) {
            throw new Error(result.message)
        }

        return result?.orderId
    }

export const generateMaskedOrderId = (): ThunkResult<Promise<string>> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        { request },
    ): Promise<string> {
        const guestMaskedOrderIdEndpoint =
            '/rest/V1/guest-carts/generate-masked-order-id'

        const authedMaskedOrderIdEndpoint =
            '/rest/V1/carts/generate-masked-order-id'

        const maskedOrderIdEndpoint = isSignedIn()
            ? authedMaskedOrderIdEndpoint
            : guestMaskedOrderIdEndpoint

        const maskedOrderId = await request(maskedOrderIdEndpoint, {
            method: 'POST',
        })

        if (!maskedOrderId) {
            throw new Error(`Failed to fetch masked id for order.`)
        }

        return maskedOrderId
    }

export const getPosTerminals = (): ThunkResult<
    Promise<AdyenMagentoPOSTerminal[]>
> =>
    async function thunk(
        dispatch: ThunkDispatch,
        getState,
        { request },
    ): Promise<AdyenMagentoPOSTerminal[]> {
        const endpoint = '/rest/V1/adyen/terminals'

        return request(endpoint, {
            method: 'GET',
        })
    }
