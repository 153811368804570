import cx from 'classnames'
import * as React from 'react'

import useCollapse from '../../behaviour/Collapse/Collapse'
import DropdownIcon from '../../core/DropdownIcon'
import Icon from '../../media/Icon'

const NavSection: React.FC<{
    label: string
    className: string
    children: React.ReactNode
    open: boolean
    onClick(): void
    iconClass?: string
    iconClassOpen?: string
}> = ({
    label,
    className,
    children,
    open = false,
    onClick,
    iconClass,
    iconClassOpen,
}) => {
    const [getCollapseProps, getToggleProps] = useCollapse({ isOpen: open })

    return (
        <>
            <button {...getToggleProps({ onClick })} className={className}>
                {label}
                {children && (
                    <Icon
                        component={DropdownIcon}
                        title="dropdown"
                        className={cx(iconClass, {
                            [`${iconClassOpen}`]: open,
                        })}
                    />
                )}
            </button>
            {children && <ul {...getCollapseProps()}>{children}</ul>}
        </>
    )
}

export default NavSection
